import React, {useEffect, useState} from "react";
import axios from "axios";
import LoadingTableComponent from "../loadingTable.component";
import {getUserData} from "../../utils/LocalHandler";
import Swal from "sweetalert2";
import {API_BASE_URL} from "../../utils/config";
import {Link, useNavigate} from "react-router-dom";
import {App_Name} from "../../utils/name";

const PendingPaymentPage=()=>{
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [options, setOptions] = useState([10, 25, 50, 100,200]);
    const [pendingPayments, setPendingPayments] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [currentItems, setCurrentItems] = useState([]);
    const navigate=useNavigate();


    useEffect(() => {
        const newTotalPages = Math.ceil(pendingPayments.length / itemsPerPage);
        setTotalPages(newTotalPages);
        const newStartIndex = (currentPage - 1) * itemsPerPage;
        setStartIndex(newStartIndex);
        const newEndIndex = newStartIndex + itemsPerPage;
        setEndIndex(newEndIndex);
        const newCurrentItems = pendingPayments.slice(newStartIndex, newEndIndex);
        setCurrentItems(newCurrentItems);
    }, [pendingPayments, itemsPerPage, currentPage]);


    async function fetchPendingPayments() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
        };
        const response = await axios({
            url: `${API_BASE_URL}/get-pending-payment`,
            method: "post",
            data:configData
        });
        let data1 = response['data'];
        setPendingPayments(data1.data);
        console.log(data1.data)
        setIsLoading(false)
        if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }

    useEffect(() => {
        fetchPendingPayments();
    }, []);

    // console.log(pendingPayments)
    // const filteredItems = currentItems.filter((payment) =>
    //     payment.user_name.toLowerCase().includes(searchQuery.toLowerCase())
    // );
    const filteredItems = pendingPayments.filter((payment) => {
        return Object.values(payment).some((value) =>
            value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    async function handleActivate(id) {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            payment_id:currentItems[id].id
        };
        const response = await axios({
            url: `${API_BASE_URL}/activate-exam-from-pending-payment`,
            method: "post",
            data:configData
        });
        let data1 = response['data'];
        // setPendingPayments(data1.data);
        // console.log(data1.data)
        if (data1.status == 200){
            Swal.fire({
                icon: 'success',
                title: 'Exam activated ...',
                text: 'Exam activated successfully',
            })
            fetchPendingPayments();
        }
        setIsLoading(false)
        if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }


    return(
        <>

            <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Pending Payment {pendingPayments.length}</button>
                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className="border rounded overflow-hidden flex">
                                        <input
                                            className="input input-bordered rounded-[2px]   max-sm:input-sm max-sm:w-28"
                                            type="text"
                                            placeholder="Search Pending Payment"
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Middle container */}
                <main className="flex-1 overflow-y-auto ">
                    <div className="h-[90%] overflow-x-scroll overflow-y-scroll">
                        {
                            !isLoading?  <table className="border-separate w-full table-zebra table  table-auto table-compact ">
                                <thead className="border-b sticky top-0">
                                <tr className="text-center">
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Username</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Phone</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Exam Name</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Real Amount</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Times</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Date</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Remarks</th>
                                    {/*<th className="bg-[#50DBB4] pl-2 pr-2">Add Remark</th>*/}
                                    <th className="bg-[#50DBB4] pl-10 pr-10">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                { filteredItems.map((e, id) => (
                                    <tr key={id} className="hover">
                                        <td className="!h-14">
                                            {
                                                e.user_name
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.phone
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.exam_name
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.real_amount
                                            }
                                        </td>
                                        <td>
                                            {e.times}
                                        </td>
                                        <td>
                                            { e.date}
                                        </td>
                                        <td>
                                            { e.remarks}
                                        </td>
                                        {/*<td>*/}
                                        {/*    /!*<label htmlFor="addRemarkPayment" className="btn btn-ghost btn-sm text-2xl"><BsFillBellFill/></label>*!/*/}
                                        {/*    <label htmlFor="addRemarkPayment" className="btn btn-ghost btn-sm text-2xl"><RiPagesLine/></label>*/}
                                        {/*</td>*/}
                                        <td>
                                            <button
                                                className="btn btn-sm !rounded-[2px] btn-outline "
                                                onClick={() => handleActivate(id)}
                                            >
                                                Activate
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>:<LoadingTableComponent/>
                        }
                    </div>
                </main>

                {/* Footer */}
                <footer >
                    <div className="container mx-auto text-center">
                        <div>
                            {/*<div className="flex justify-end items-center mb-4">*/}
                            {/*    <span className="mr-2">Items per page:</span>*/}
                            {/*    <select*/}
                            {/*        className="border rounded-md px-2 py-1 mr-4"*/}
                            {/*        value={itemsPerPage}*/}
                            {/*        onChange={(e) => setItemsPerPage(Number(e.target.value))}*/}
                            {/*    >*/}
                            {/*        {options.map((option) => (*/}
                            {/*            <option key={option} value={option}>*/}
                            {/*                {option}*/}
                            {/*            </option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            <div className="flex justify-center">
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-l"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Previous
                                </button>
                                {totalPages > 7 ? (
                                    <>
                                        {currentPage > 4 && (
                                            <>
                                                <button
                                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4"
                                                    onClick={() => setCurrentPage(1)}
                                                >
                                                    1
                                                </button>
                                                <span className="mx-2">...</span>
                                            </>
                                        )}
                                        {Array.from({ length: totalPages }, (_, i) => i + 1)
                                            .filter(
                                                (page) =>
                                                    page === 1 ||
                                                    page === totalPages ||
                                                    (page >= currentPage - 2 && page <= currentPage + 2)
                                            )
                                            .map((page) => (
                                                <button
                                                    key={page}
                                                    className={`${
                                                        currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200 hover:bg-gray-300 text-gray-600"
                                                    } font-bold py-2 px-4`}
                                                    onClick={() => setCurrentPage(page)}
                                                >
                                                    {page}
                                                </button>
                                            ))}
                                        {currentPage < totalPages - 3 && (
                                            <>
                                                <span className="mx-2">...</span>
                                                <button
                                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4"
                                                    onClick={() => setCurrentPage(totalPages)}
                                                >
                                                    {totalPages}
                                                </button>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                        <button
                                            key={page}
                                            className={`${
                                                currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200 hover:bg-gray-300 text-gray-600"
                                            } font-bold py-2 px-4`}
                                            onClick={() => setCurrentPage(page)}
                                        >
                                            {page}
                                        </button>
                                    ))
                                )}
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-r"
                                    disabled={currentPage === totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}
export default PendingPaymentPage;
