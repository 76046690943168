import React, { useState } from 'react';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';
import { App_Name } from '../../../utils/name';
import axios from "axios";
import {getUserData} from "../../../utils/LocalHandler";
import {API_BASE_URL} from "../../../utils/config";
import toast, {Toaster} from "react-hot-toast";

const QuestionSys = () => {
	const [parsedData, setParsedData] = useState([]);
	const [tableRows, setTableRows] = useState([]);
	const [values, setValues] = useState([]);
	const [parsedDataArray, setParsedDataArray] = useState([]);

	const [loading,setLoading]=useState(false)
	const changeHandler = (event) => {
		Papa.parse(event.target.files[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (results) {
				const parsedObjects = results.data;

				// Save parsed data in the parsedData state
				setParsedData(parsedObjects);

				// Save parsed data in a separate state array
				setParsedDataArray([...parsedDataArray, ...parsedObjects]);

				const rowsArray = [];
				const valuesArray = [];

				parsedObjects.forEach((d) => {
					rowsArray.push(Object.keys(d));
					valuesArray.push(Object.values(d));
				});

				setTableRows(rowsArray[0]);
				setValues(valuesArray);
			},
		});
	};



	const handleUpload=async () => {
		setLoading(true)
		try {
			const userDetails = getUserData();
			const token = userDetails.token;
			const response = await axios.post(`${API_BASE_URL}/upload-q1`, {
				token: token,
				data: parsedDataArray,
			});

			const data=await response;
			debugger
			if(data.data.status == 200){
				setLoading(false)
				setValues(null)
				setTableRows(null)
				toast.success("Upload Completed")
			}
			else {
				toast.error("Failed to upload Please Refresh the page")
			}

		} catch (error) {

			console.error('Error uploading questions:', error.message);
		}
	}
	return (
		<>
			<div className="bg-base-00 !p-4 m-0 flex flex-col h-screen">
				<header>
					<div className="mx-auto">
						<div className="grid grid-cols-3 max-sm:grid-cols-1 mb-2">
							<div className="">
								<Link
									to="/"
									className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn !rounded-[2px] max-sm:text-sm max-sm:btn-sm"
								>
									{App_Name}
								</Link>
								<button className="btn bg-gray-300 btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">
									{'Question System'}
								</button>
							</div>
							<div className="justify-self-end col-span-2 max-sm:mt-2">
								<div className="flex gap-2 items-center">
									<input
										className="file-input file-input-bordered rounded-[2px] file-input-sm"
										type="file"
										name="file"
										onChange={changeHandler}
										// accept=".csv"
									/>
									<button onClick={handleUpload} className="btn btn-accent btn-sm rounded-[2px]">Upload</button>
								</div>
							</div>
						</div>
					</div>
				</header>

				{/* Table */}
				{
					!loading?
						<div className="overflow-auto w-screen">
							<table className="border-separate w-full table-zebra table table-compact ">
								<thead className="border-b sticky top-0">
								<tr>
									{tableRows?.map((rows, index) => {
										return <th className="bg-[#50DBB4] pl-2 pr-2 text-center" key={index}>{rows}</th>;
									})}
								</tr>
								</thead>
								<tbody>
								{values?.map((value, index) => {
									return (
										<tr key={index}>
											{value.map((val, i) => {
												return <td key={i}>{val}</td>;
											})}
										</tr>
									);
								})}
								</tbody>
							</table>
						</div>
						:
						<div className="hero min-h-screen bg-base-200">
							<div className="hero-content text-center">
								<div className="max-w-md">
									<h1 className="text-2xl font-bold ">
										 Please Wait. Uploading...</h1>
								</div>
							</div>
						</div>
				}
			</div>

			<Toaster/>
		</>
	);
};

export default QuestionSys;
