import {RxCross2} from "react-icons/rx";
import React, {useState} from "react";
import {getUserData} from "../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../utils/config";
import toast, {Toaster} from "react-hot-toast";

const Notification = () => {

	const [image,setImage]=useState()
	const [title,setTitle]=useState()
	const [body,setBody]=useState()
	const [link,setLink]=useState()


	async function handleNotification(e) {
		e.preventDefault()
		const userDetails = getUserData();
		const token = userDetails.token;

		try {
			const response = await fetch(`https://api.notification.dishaonlinetest.in/send-notification-to-all`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token,
					image,
					title,
					body,
					link
				}),
			});

			if (response.ok) {
				toast.success("Notification might be sent!")
				setTitle("")
				setImage("")
				setBody("")
				setLink("")
			}else{
				toast.error("Faild to send")
			}

		} catch (error) {
			console.error('Error sending notifications:', error.message);
		}
	}

	return(
		<>
			<input type="checkbox" id="notiModal" className="modal-toggle" />
			<label htmlFor="notiModal" className="modal cursor-pointer shadow-2xl">
				<label className="modal-box pt-0 pb-0 relative" htmlFor="">
					<div className="flex items-center mt-1">
						<p className="font-bold mb-1 mt-1">Send Notification</p>
						<label htmlFor="notiModal" className="btn ml-auto btn-ghost btn-sm">X</label>
					</div>
					<form onSubmit={handleNotification}>
						<div className="form-control">
							<label className="label">
								<span className="label-text">Title</span>
							</label>
							<input
								type="text"
								placeholder="Title"
								className="input  input-bordered text-sm !rounded-sm c-font1"
								value={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
								required={true}
							/>
						</div>
						<div className="form-control">
							<label className="label">
								<span className="label-text">Image</span>
							</label>
							<input
								type="text"
								placeholder="Image url"
								className="input  input-bordered text-sm !rounded-sm c-font1"
								value={image}
								onChange={(e) => {
									setImage(e.target.value);
								}}
							/>
						</div>
						<div className="form-control">
							<label className="label">
								<span className="label-text">Notification Body</span>
							</label>
							<textarea
								placeholder="Notification body"
								className="textarea  textarea-bordered h-20 !rounded-sm c-font1"
								value={body}
								onChange={(e) => {
									setBody(e.target.value);
								}}
								required={true}
							/>
						</div>
						<div className="form-control">
							<label className="label">
								<span className="label-text">Link</span>
							</label>
							<input
								type="text"
								placeholder="Url"
								className="input  input-bordered text-sm !rounded-sm c-font1"
								value={link}
								onChange={(e) => {
									setLink(e.target.value);
								}}
							/>
						</div>
						<button className="mt-2 mb-2 btn w-full bg-neutral rounded-[5px]">
							Submit
						</button>
					</form>
				</label>
			</label>


			<Toaster/>
		</>
	)
}
export default Notification;
