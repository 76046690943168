import Swal from "sweetalert2";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MdEmail, MdPhone} from "react-icons/md";
import {RiLockPasswordFill} from "react-icons/ri";
import img1 from "../../assets/12146011_Wavy_Gen-01_Single-07.jpg"
import anim from "../../assets/78126-secure-login.json"
import Lottie from "lottie-react";
import { addDataWithKey } from "../../utils/LocalHandler";
import axoisApi from "../../api/axios";
import {App_Name} from "../../utils/name";
const Login=()=>{

    const [isLoading, setIsLoading] = useState(false);
    const signInModal = useRef(null);
    const [phone_no,setPhone_no]=useState("");
    const [password,setPassword]=useState("");
    const [platform,setPlatform]=useState("Web")

    // const [teacher,setTeacher]=useState([]);

    let teacher=[]

    const navigate=useNavigate();

    async function handleSignIn(event){
        event.preventDefault()
        setIsLoading(true)

        const response = await axoisApi({
          url: "/authenticate-admin",
          method: "post",
          data: {
            phone:phone_no,
            password,
          },
        });

        const data= response['data'];
        // console.log(data[data].admin_name)
        if(data.status===200) {
            addDataWithKey(data["data"][0])
            localStorage.setItem('name',data)
            // Swal.fire({
            //     title:'Login Success!',
            //     text:'Welcome to the portal!',
            //     icon:'success'
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         .auth(true)
            //         // signInModal.current.click();
            //     }
            // })
            navigate("/")
        }
        else if(data.status==404){
            setIsLoading(false)
            Swal.fire({
                title:'Invalid credentials!',
                text:'please check the credentials !',
                icon:'error'
            }).then((result) => {
                if (result.isConfirmed) {
                }
            })
        }
        else{
            setIsLoading(false)
            Swal.fire({
                title:'Login Failed!',
                text:'please check the credentials !',
                icon:'error'
            }).then((result) => {
                if (result.isConfirmed) {
                    signInModal.current.click();
                }
            })
        }
        setPhone_no("")
        setPassword("")
    }


    return(
        <>
            <div className="hero login-bg h-screen text-white max-sm:h-[70vh] max-sm:mt-5">
                <div className="hero-content flex-col lg:flex-row">
                    {/*<Lottie animationData={anim}  className="w-5/12" />*/}
                    <div className=" !bg-transparent ">
                        <div className="card w-full p-2 glass  !rounded-[2px]">
                            <div className="card-body">
                                <p className="text-xl text-black font-bold ">{App_Name} Admin Panel Login</p>
                                <form  onSubmit={handleSignIn}>
                                    {/*<p className="text-[25px] text-black font-bold ">Login to your account</p>*/}
                                    <div >
                                        <div className="form-control ">
                                            <label className="label">
                                                <span className="label-text c-font1 font-bold">Enter Phone No:</span>
                                            </label>
                                            <label className="input-group ">
                                                <input type="text" placeholder="Enter Phone No" className="input w-full text-black c-input c-font1 input-bordered bg-transparent !rounded-sm text-sm"
                                                       value={phone_no} onChange={(e)=>(setPhone_no(e.target.value))} required/>
                                                <button className="btn text-2xl bg-[#120e43] "><MdPhone/></button>
                                            </label>
                                        </div>
                                        <div className="form-control shadow-none">
                                            <label className="label">
                                                <span className="label-text c-font1 font-bold">Enter Password:</span>
                                            </label>
                                            <label className="input-group">
                                                <input type="password" placeholder="Password" className="input bg-transparent text-black c-input w-full c-font1 input-bordered !rounded-sm text-sm"
                                                       value={password} onChange={(e)=>(setPassword(e.target.value))} required/>
                                                <button className="btn text-2xl bg-[#120e43] "><RiLockPasswordFill/></button>
                                            </label>
                                        </div>
                                        {

                                            !isLoading?<button className="btn w-full mt-4 bg-[#120e43] capitalize c-font1 !rounded-sm">Login</button>:<button className="btn w-full mt-4 loading capitalize bg-[#120e43]  c-font1"></button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;
