import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { checkUserLoggedIn } from "./LocalHandler";
const ProtectedRoute = () => {
  // const navigate = useNavigate();
  const auth = checkUserLoggedIn();
  const location = useLocation();
  // console.log(auth)
  // return navigate('/signup')
  return auth ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />;

};

export default ProtectedRoute;