import React, {useEffect, useState} from "react";
import {getUserData} from "../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../utils/config";
import {Bar} from "react-chartjs-2";


const AdmGraphForExam = (props) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [data,setData]=useState([])
    const [totalData,setTotalData]=useState([])



    async function fetchAdm() {
        // debugger
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            exam_id:props.examId
        };
        const response = await axios({
            url: `${API_BASE_URL}/get-analytics-with-range`,
            method: "post",
            data:configData
        });
        let data1 = response['data'];
        setData(data1.data);
        setTotalData(data1.total)
        // console.log(data1.data)
        // setIsLoading(false)
    }

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        setSelectedDate(currentDate);
    }, []);

    useEffect(() => {
        fetchAdm();
    }, [props.examId]);

    const dataGraph = {
        labels: data.map(item => item.admission_date),
        datasets: [
            {
                label: "Total Admissions on Daily basis",
                data: data.map(item => item.daily_admission_count),
                backgroundColor: "rgba(18, 14, 67, 0.68)",
                borderColor: "#120e43",
                borderWidth: 1,
            },
        ],
    };


    const options = {
        legend: {
            display: false, // Hide the legend label
        },
    };
    return(
        <>

            <div className="grid grid-cols-2 max-sm:grid-cols-1 mb-2 gap-2">
                <div >
                    <div className="card !h-72  !rounded-[5px] bg-base-100 shadow-xl">
                        <div className="card-body">

                            <div className="stats w-full  !rounded-[5px] shadow">

                                <div className="stat bg-neutral text-white mr-[-29px] max-sm:mr-auto">
                                    <div className="stat-title text-white">Total Admissions:</div>
                                    <div className="stat-value">{props.totalAdm?props.totalAdm:<progress className="progress "></progress>
                                    }</div>
                                </div>

                                <div className="stat bg-yellow-300 ">
                                    <div className="stat-title ">Today Admissions:</div>
                                    <div className="stat-value">{data.length>0?data[data.length-1].daily_admission_count:<progress className="progress "></progress>
                                    }</div>
                                </div>

                            </div>

                            {/*<p>*/}
                            {/*    <b>Total Admissions: {props.totalAdm} </b>*/}
                            {/*</p>*/}
                            {/*<hr/>*/}
                            {
                                totalData.map((item,key)=>(
                                    <>
                                        <div className="stats  !rounded-[5px] w-full shadow">

                                            <div className="stat bg-gray-600 text-white">
                                                <div className="stat-title text-white">Total Revenue:</div>
                                                <div className="stat-value">{item.total_amount?item.total_amount:<progress className="progress "></progress>
                                                }</div>
                                            </div>

                                            <div className="stat  bg-green-200 ">
                                                <div className="stat-title ">Coupon code used:</div>
                                                <div className="stat-value">{item.promo_code_id?item.promo_code_id:<div className="badge bg-red-400 border-none">Not found</div>
                                                }</div>
                                            </div>

                                        </div>
                                    </>
                                ))
                            }
                            {/*<p>*/}
                            {/*    <b>Manually Actived: </b>*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
                <div >

                    <div className={`card !h-72 !rounded-[5px]  shadow-xl bg-base-100`}>
                        <div className="card-body  text-center">
                            <Bar className="!h-60 !z-0" data={dataGraph} options={options} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default AdmGraphForExam;
