import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {Bar, Line, Bubble, Pie} from "react-chartjs-2";
import Chart from "chart.js/auto";

import { CategoryScale } from "chart.js";
import axios from "axios";
import { API_BASE_URL } from "../../utils/config";
import { getUserData } from "../../utils/LocalHandler";
import OmrTest from "./OmrTest";
import LiveTest from "./LiveTest";
import ExamManagement from "./ExamManagement";
import PendingPaymentPage from "./pendingPayment.page";
import CarouselPage from "./carousel.page";
import mgm from "../../assets/menu/exam-mgm.png";
import omr from "../../assets/menu/omr.png";
import pen from "../../assets/menu/data-pending.png";
import cars from "../../assets/menu/cras.png";
import sync from "../../assets/menu/connection-sync.png";
import {Link, useNavigate} from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import socketIO from "socket.io-client";
import {AiOutlineLogout, AiOutlineSearch} from "react-icons/ai";
import {App_Name} from "../../utils/name";
import {formatDate} from "../../utils/formatDate";
import {getRole} from "../../utils/getRole";
import {BsFillPencilFill} from "react-icons/bs";
import {GrHelp} from "react-icons/gr";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Recording from "./recording";
import ResetCache from "../ResetCache";
import IndexUser from "./User/indexUser";
import Notification from "../Notification/Notification";
Chart.register(CategoryScale);

const DashboardPage = () => {


  const role=getRole();

  const [isLoading, setIsLoading] = useState(true);

  const closeBtnRef = useRef();
  const [airtableTableName, setAirtableTableName] = useState("");
  const [syncCode, setSyncCode] = useState("");
  const [syncingMessage, setSyncingMessage] = useState("Sync Teach");
  const [isSyncing, setIsSyncing] = useState(false);

  const [searchNav, setSearchNav] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  const [newUser, setNewUser] = useState([]);
  const [newUser1, setNewUser1] = useState([]);

  const [payDay, setPayDay] = useState([]);
  const [payDay1, setPayDay1] = useState([]);
  const [payDay0, setPayDay0] = useState([]);

  const [resDay, setResDay] = useState([]);
  const [resDay0, setResDay0] = useState([]);
  const [resDay1, setResDay1] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);

  const [active, setActive] = useState([]);
  const [todayRes,setTodayRes]=useState()
  const [todayAdm,setTodayAdm]=useState()

  const [adm,setAdm]=useState([])

  const [pieData,setPieData]=useState([])
  const [pieData1,setPieData1]=useState([])
  const [pieData0,setPieData0]=useState([])

  const [todayRevenue,setTodayRevenue]=useState([]);

  async function fetchData() {
    debugger
    const userDetails = getUserData();
    const token = userDetails.token;
    setIsLoading(true)
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/dashboard`,
        options
    );
    let resData = await response.json();
    // console.log(resData["data"][0]);
    setNewUser(resData["data"][0].map((item) => item.new_user));
    setNewUser1(resData["data"][0].map((item) => item.date));

    setPayDay(resData["data"][1].map((item) => item.payment_count));
    setPayDay1(resData["data"][1].map((item) => item.payment_day));
    setPayDay0(resData["data"][1])
    setResDay(resData["data"][2].map((item) => item.total_results));
    setResDay0(resData["data"][2]) // all values
    setResDay1(resData["data"][2].map((item) => item.result_date));

    setTableData(resData["data"][3]);

    setPieData(resData["data"][7].map((item) => item.exam_id))
    setPieData0(resData["data"][7].map((item) => item.exam_name))
    setPieData1(resData["data"][7].map((item) => item.total_admission))

    setAdm(resData["data"][7])

    // setTodayRevenue(resData["data"][8])

    const lastObject = resData["data"][2][resData["data"][2].length - 1]; //result
    const lastObject1 = resData["data"][1][resData["data"][1].length - 1]; // admission

    setActive(resData["data"][4].map((item) => item.active_count));

    setTableData1(resData["data"][5]);

    const currentDate = new Date();
    const dateTimeString= formatDate(currentDate)
    const dateString = dateTimeString.slice(0, 10);


    if(lastObject){
      if (lastObject.result_date === dateString) {
        setTodayRes(lastObject.total_results);
      } else {
        setTodayRes(0);
      }
    }


   if(lastObject1){
     if (lastObject1.payment_day === dateString) {

       setTodayAdm(lastObject1.payment_count);
     } else {
       setTodayAdm(0);
     }
   }



    setIsLoading(false)
  }


  const [searchByPhone,setSearchByPhone]=useState()
  const [phoneFlag,setPhoneFlag]=useState(true)


  const handleSearch= async (e)=>{
    e.preventDefault();
    const userDetails = getUserData();
    const token = userDetails.token;
    const configData = {
      token,
      phone:searchByPhone
    };
    const response = await axios({
      url:`${API_BASE_URL}/getUserbyPhone`,
      method: "post",
      data: configData
    });
    let data1 = response['data'];
    if(data1.data.length>0){
      setTableData1(data1.data);
      setSearchByPhone("")
    }
    else{
      toast.info("No User Found")
      fetchData()
    }

  }

  const data = {
    labels: newUser1,
    datasets: [
      {
        label: "New Users",
        data: newUser,
        backgroundColor: "rgba(18,14,67,0.68)",
        borderColor: "#120e43",
        borderWidth: 1,
      },
    ],
  };

  const data1 = {
    labels: payDay1,
    datasets: [
      {
        label: "Payment",
        data: payDay,
        backgroundColor: "rgba(18,14,67,0.68)",
        borderColor: "#120e43",
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: resDay1,
    datasets: [
      {
        label: "Result",
        data: resDay,
        backgroundColor: "rgba(18,14,67,0.68)",
        borderColor: "#120e43",
        borderWidth: 1,
      },
    ],
  };

  const data3 = {
    labels: pieData,
    datasets: [
      {
        label: 'Admissions',
        data: pieData1,
        backgroundColor: "rgba(18,14,67,0.68)",
        borderColor: "#120e43",
        borderWidth: 1,
      },
    ],
  };


  // Options for the bar graph
  const optionsNew = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Exam ID',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Admissions',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            const index = tooltipItem[0].dataIndex;
            return pieData0[index];
          },
        },
      },
    },
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 16,
          },
        },
      ],
    },
  };

  useEffect(() => {
    fetchData();
  }, []);


  const [menus, setMenus] = useState([]);
const stream="https://image.bigbooster.in/36408b6d-43f0-4355-82d2-db52f4b9e9b8.png"
  const help="https://image.bigbooster.in/3eccca1b-d22e-4950-8674-831ec0251380.png"
  const editor="https://image.bigbooster.in/b7af3227-a74f-49bb-858e-0c0ccbf28275.png"
  const seo="https://cdn-icons-png.flaticon.com/512/2977/2977790.png"
  const blog="https://cdn-icons-png.flaticon.com/512/3959/3959542.png"
  const setMenusBasedOnRole = (role) => {
    if (role === 1) {
      setMenus([
        {
          name: "Exam Management",
          link: "/exam-management",
          icon: mgm,
          margin: true,
          w: 56
        },
        {
          name: "Carousal",
          link: "/carousels",
          icon: cars,
          w: 44
        },
        { name: "Support", link: "/support", icon: help, w: 44 }

      ]);
    } else if (role === 2) {
      setMenus([
        {
          name: "Exam Management",
          link: "/exam-management",
          icon: mgm,
          margin: true,
          w: 56
        },
        {
          name: "Carousal",
          link: "/carousels",
          icon: cars,
          w: 44
        },
        { name: "Support", link: "/support", icon: help, w: 44 }
      ]);
    } else if (role === 3) {
      setMenus([
        { name: "Live Test", link: "/live-test", icon: mgm, w: 36 },
        { name: "Live Quiz", link: "/live-quiz", icon: mgm, w: 36 },
        // { name: "Live Quiz", link: "/live-quiz", icon: mgm },
        // { name: "OMR Test", link: "/omr-test", icon: omr, w: 36 },
        {
          name: "Exam Management",
          link: "/exam-management",
          icon: mgm,
          margin: true,
          w: 56
        },
        { name: "Pending Payment", link: "/pending-payment", icon: pen, w: 56 },
        { name: "Carousal", link: "/carousels", icon: cars, w: 44 },
        // { name: "Community", link: "/community", icon: cars, w: 44 },
        { name: "Support", link: "/support", icon: help, w: 44 },
        { name: "Users", link: "/users", icon: "", w: 44 },
        { name: "Editor", link: "/editor", icon: editor, w: 44 },
        { name: "Question System", link: "/question-system", icon: blog, w: 44 },
        { name: "SEO", link: "/seo-json", icon: seo, w: 44 },
        // { name: "Stream Recording", link: "/stream-recording", icon: stream, w: 44 },
      ]);
    } else {
      setMenus([]);
    }
  };

  useEffect(() => {
    setMenusBasedOnRole(role);
  }, []);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  // console.log(formattedDate)
  const paymentCount = payDay0.find(item => item.payment_day === formattedDate)?.payment_count || 0;
  // console.log(paymentCount)

  const filteredItems = tableData.filter((data) =>
      data.user_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredItems1 = tableData1.filter((data) =>
      data.user_name.toLowerCase().includes(searchQuery1.toLowerCase())
  );

  const filteredItems2 = adm.filter((data) =>
      data.exam_name.toLowerCase().includes(searchQuery2.toLowerCase())
  );



  const handleInputChange = (value) => {
    setSearchNav(value.toLowerCase());
  };

  const Navi = () => {
    // debugger
    if (searchNav.includes("omr") || searchNav == "omr test") {
      return <OmrTest />;
    } else if (searchNav.includes("live") || searchNav == "live test") {
      return <LiveTest />;
    } else if (searchNav.includes("exam") || searchNav == "exam management") {
      return <ExamManagement />;
    } else if (searchNav.includes("carousel") || searchNav == "carousel") {
      return <CarouselPage />;
    } else if (
        searchNav.includes("pending") ||
        searchNav.includes("payment") ||
        searchNav == "pending payment"
    ) {
      return <PendingPaymentPage />;
    }
  };

  useEffect(() => {
    Navi();
    // console.log(searchNav);
  }, []);

  // sync

  const handleStartSyncing = () => {

    const userDetails = getUserData();
    const token = userDetails.token;
    if (syncCode == "" && airtableTableName == "") {
      setSyncingMessage(false);
    } else {
      const socket = socketIO.connect(
          `${API_BASE_URL}`
      );
      setSyncingMessage("connecting...");
      setIsSyncing(true);
      socket.emit("start-syncing-teach", {
        token: token,
        airtable_table_name: airtableTableName,
        sync_code: syncCode,
      });
      setSyncingMessage("Syncing...");
      setIsSyncing(true);
      socket.on("sync-status", (res) => updateDomAfterSocketResponse(res));
    }
  };

  const updateDomAfterSocketResponse = (res) => {
    setSyncingMessage(res["data"]);
  };

  const navigate=useNavigate()
  const handleSignOut=()=>{
    sessionStorage.clear();
    navigate("/login")
  }
  const ChartContainer = ({ height, children }) => {
    const containerStyle = {
      height: `${height}px`,
      overflow: 'auto',
    };

    return <div style={containerStyle}>{children}</div>;
  };

  return (
      <>
        <div className="bg-gray-200 !p-4 m-0 flex flex-col h-screen ">
          {/* Header */}
          <header>
            <div className=" mx-auto">
              <div className="grid  grid-cols-3  mb-2">
                <div className="col-span-2">
                  <button className="text-xl btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">
                    {App_Name}
                  </button>
                </div>
                <div className="justify-self-end">
                  <div className="form-control ">
                    <div className="rounded  flex">
                      <button onClick={handleSignOut}
                              className={`btn pr-10 pl-10 mr-1 !rounded-[2px] gap-2 max-sm:mr-1 max-sm:btn-sm`}
                      > <AiOutlineLogout/>
                        Logout
                      </button>
                      {/*<div>*/}
                      {/*  <AutocompleteInput*/}
                      {/*    suggestions={items}*/}
                      {/*    placeholder="Search"*/}
                      {/*    onInputChange={handleInputChange}*/}
                      {/*  />*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* Middle container */}
          <main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin">
            <div className="overflow-y-auto pb-4 pt-4">
              <div className="stats !rounded-[5px] shadow-xl mb-2">
                <div className="stat ">
                  <div className="stat-title">New Registration</div>
                  {!isLoading ? (
                      <div className="stat-value text-primary">{newUser.slice(-1)[0]}</div>
                  ) : (
                      <div className="stat-title loading btn text-sm !m-0 !p-0 btn-ghost "></div>
                  )}
                </div>

                <div className="stat bg-accent">
                  <div className="stat-title">Active users</div>
                  {!isLoading ? (
                      <div className="stat-value ">{active}</div>
                  ) : (
                      <div className="stat-title loading btn text-sm !m-0 !p-0 btn-ghost "></div>
                  )}
                </div>

                <div className="stat bg-info">
                  <div className="stat-title">Today Admission</div>
                  {!isLoading ? (
                      <div className="stat-value ">{todayAdm || 0}</div>
                  ) : (
                      <div className="stat-title loading btn text-sm !m-0 !p-0 btn-ghost "></div>
                  )}
                </div>

                <div className="stat bg-[#F4CE6A]">
                  <div className="stat-title">Today Result</div>
                  {!isLoading ? (
                      <div className="stat-value ">{todayRes}</div>
                  ) : (
                      <div className="stat-title loading btn text-sm !m-0 !p-0 btn-ghost "></div>
                  )}
                </div>

              </div>
            </div>

            <div className="divider">Explore</div>

            {/*navigations*/}
            <Navi />



            <div className="grid grid-rows-2 grid-cols-6 max-sm:grid-cols-1 gap-3 ">
              {menus.map((item, key) => (
                  <div>
                    {/*max-sm:w-${item.w}*/}
                    <Link
                        to={item.link}
                        className={`btn w-full max-sm:btn-sm  max-sm:h-14 !rounded-[2px] gap-2`}
                    >
                      <img src={item.icon} width="20" height="20" />
                      {item.name}
                    </Link>
                  </div>
              ))}
              {
                role==1?null:role==2?null:role==3?<label
                    htmlFor="syncModal"
                    className="btn btn-wide w-full !rounded-[2px] gap-2"
                >
                  <img src={sync} width="20" height="20" />
                  <label htmlFor="syncModal">Sync Teach</label>
                </label>:null
              }

              <label
                  htmlFor="notiModal"
                  className="btn btn-wide  !rounded-[2px] gap-2"
              >
                <img src="https://img.icons8.com/?size=512&id=119034&format=png" width="20" height="20"  alt={""}/>
                <label htmlFor="notiModal">Notification</label>
              </label>

              <Notification/>
              <ResetCache/>
              {/*{*/}
              {/*  role==1?null:role==2?null:role==3?*/}
              {/*    <>*/}
              {/*    <Recording/>*/}
              {/*      <label  htmlFor="recordingModal"*/}
              {/*              className="btn btn-wide w-full !rounded-[2px] gap-2">*/}
              {/*        <img src={""} width="20" height="20" />*/}
              {/*        Get Recording</label>*/}
              {/*    </>*/}
              {/*      :null*/}
              {/*}*/}
            </div>


            {/*graphs */}
            <div className="grid mt-4 gap-4 grid-cols-3 max-md:grid-cols-2 max-xl:grid-cols-2 max-sm:grid-cols-1">
              <div>
                <div className={`card !rounded-[5px] shadow-xl bg-base-100`}>
                  <div className="card-body text-center">
                    <Bar className="!h-48"  data={data} options={options} />
                  </div>
                </div>
              </div>
              <div>
                <div className={`card !rounded-[5px] shadow-xl bg-base-100`}>
                  <div className="card-body">
                    <Bar className="!h-48"  data={data1} options={options} />
                  </div>
                </div>
              </div>
              {/*<div>*/}
              {/*  <div className={`card  !rounded-[5px] shadow-xl bg-base-100`}>*/}
              {/*    <div className="card-body text-center justify-center">*/}
              {/*      /!*<Pie className="!h-48" data={data3} options={options1} />*!/*/}
              {/*      <Bar className="!h-48"  data={data3} options={optionsNew} />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div>
                <div className={`card !rounded-[5px] shadow-xl bg-base-100`}>
                  <div className="card-body">
                    <Bar className="!h-48"  data={data2} options={options} />
                  </div>
                </div>
              </div>

            </div>

            {/*<AdmGraph/>*/}

            <div className="divider"></div>

            {/*table */}
            <div className="grid grid-cols-2 gap-2 max-sm:grid-cols-1">
              <div>
                <div className="mx-auto">
                  <div className="grid sticky top-0 grid-cols-3 mb-2">
                    <div className="col-span-2">
                      <button className=" btn bg-gray-300 btn-sm btn-ghost mt-2 !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">
                        Last Active Users
                      </button>
                    </div>
                    <div className="justify-self-end">
                      <div className="form-control ">
                        <div className="rounded  flex">
                          <input
                              className="input input-bordered rounded-[2px] mr-1 mt-2 input-sm max-sm:w-28"
                              type="text"
                              placeholder="Search here"
                              value={searchQuery}
                              onChange={(event) =>
                                  setSearchQuery(event.target.value)
                              }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto !h-72  ">
                  <table className="table !rounded-[2px] border-separate  border border-neutral w-full text-center table-zebra table-auto table-compact ">
                    {/* head */}
                    <thead className="border-b sticky top-0">
                    <tr className="text-white">
                      <th className="bg-neutral text-center">User Id</th>
                      <th className="bg-neutral text-center">Name</th>
                      <th className="bg-neutral text-center">Phone</th>
                      <th className="bg-neutral text-center">Last Active</th>
                    </tr>
                    </thead>
                    <tbody className="text-center ">
                    {filteredItems.map((item, key) => (
                        <tr className="!text-xl">
                          <td>{item.user_id}</td>
                          <td>{item.user_name}</td>
                          <td>{item.phone}</td>
                          <td>{item.last_active}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div className="mx-auto">
                  <div className="grid sticky top-0  grid-cols-3 mb-2">
                    <div className="">
                      <button className=" btn bg-gray-300  btn-ghost mt-2 !rounded-[2px] font-bold max-sm:text-sm btn-sm">
                        New Registered Users
                      </button>
                    </div>
                    <div className="col-span-2 justify-self-end">
                      <div className="form-control mr-1">
                        <div className="rounded  flex">
                          <input
                              className="input input-bordered rounded-[2px] mr-1 mt-2 input-sm "
                              type="text"
                              placeholder="Search here"
                              value={searchQuery1}
                              onChange={(event) =>
                                  setSearchQuery1(event.target.value)
                              }
                          />
                          <div className="input-group">
                            <input type="number" placeholder="Search…" className="input input-bordered rounded-[2px]  mt-2 input-sm max-sm:w-28"
                                   placeholder="Search by Phone"
                                   value={searchByPhone}
                                   onChange={(event) =>
                                       setSearchByPhone(event.target.value)
                                   }/>
                            <button className="btn btn-square btn-sm mt-2" onClick={handleSearch}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/*<div className="form-control">*/}

                      {/*</div>*/}
                    </div>
                  </div>
                </div>
                <div className="overflow-auto !h-72 ">
                  <table className=" table !rounded-[2px] border-separate  border border-neutral w-full text-center table-zebra table-auto table-compact  ">
                    {/* head */}
                    <thead className="border-b sticky top-0">
                    <tr className="text-white">
                      <th className="bg-neutral text-center">User Id</th>
                      <th className="bg-neutral text-center">Name</th>
                      <th className="bg-neutral text-center">Phone</th>
                      {/*<th className="bg-neutral text-center">Last Active</th>*/}
                      <th className="bg-neutral text-center">
                        Registration Date
                      </th>
                    </tr>
                    </thead>
                    <tbody className={`text-center `}>
                    {
                      filteredItems1.length==0?
                          <div className="w-full">
                            <p className="text-center mt-4">No User Found</p>
                          </div>:
                      filteredItems1.map((item, key) => (
                        <tr className="!text-xl">
                          <td>{item.user_id}</td>
                          <td>{item.user_name}</td>
                          <td>{item.phone}</td>
                          <td>{item.registration_date}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div className="mx-auto">
                  <div className="grid sticky top-0  grid-cols-3 mb-2">
                    <div className="col-span-2">
                      <button className=" btn bg-gray-300  btn-ghost mt-2 !rounded-[2px] font-bold max-sm:text-sm btn-sm">
                        Admissions
                      </button>
                    </div>
                    <div className="justify-self-end">
                      <div className="form-control ">
                        <div className="rounded  flex">
                          <input
                              className="input input-bordered rounded-[2px] mr-1 mt-2 input-sm max-sm:w-28"
                              type="text"
                              placeholder="Search here"
                              value={searchQuery2}
                              onChange={(event) =>
                                  setSearchQuery2(event.target.value)
                              }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto !h-72 ">
                  <table className=" table !rounded-[2px] border-separate  border border-neutral w-full text-center table-zebra table-auto table-compact  ">
                    {/* head */}
                    <thead className="border-b sticky top-0">
                    <tr className="text-white">
                      <th className="bg-neutral text-center">Exam Id</th>
                      <th className="bg-neutral text-center">Exam Name</th>
                      <th className="bg-neutral text-center">Total Admission</th>
                    </tr>
                    </thead>
                    <tbody className="text-center ">
                    {filteredItems2.map((item, key) => (
                        <tr className="!text-xl">
                          <td>{item.exam_id}</td>
                          <td>{item.exam_name}</td>
                          <td>{item.total_admission}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </main>

          <footer></footer>

          {/*    sync modal */}

          <input type="checkbox" id="syncModal" className="modal-toggle" />
          <label htmlFor="syncModal" className="modal cursor-pointer shadow-2xl">
            <label className="modal-box pt-0 pb-0 relative" htmlFor="">
              <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-neutral text-white">
                <div className="input-group text-center gap-2 flex  items-center justify-center">
                  <p className="text-2xl font-bold text-center c-font1">
                    {syncingMessage}
                  </p>
                  {/*<p className="text-2xl font-bold text-center c-font1">Syncing</p>*/}
                </div>
                <div className="card-actions  justify-end ">
                  <label
                      ref={closeBtnRef}
                      htmlFor="syncModal"
                      className="btn btn-ghost btn-sm mt-[-30px]"
                  >
                    <RxCross2 />
                  </label>
                </div>
              </div>
              <div className="text-center">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Paste Airtable Table Name</span>
                  </label>
                  <input
                      type="text"
                      placeholder="Paste Airtable Table Name"
                      className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                      value={airtableTableName}
                      onChange={(e) => {
                        setAirtableTableName(e.target.value);
                      }}
                      required={true}
                      readOnly={isSyncing}
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Paste Sync Code</span>
                  </label>
                  <input
                      type="text"
                      placeholder="Paste Sync Code"
                      className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                      value={syncCode}
                      onChange={(e) => {
                        setSyncCode(e.target.value);
                      }}
                      required={true}
                      readOnly={isSyncing}
                  />
                </div>

                <div
                    className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  "
                    disabled={isSyncing}
                >
                  <button
                      onClick={handleStartSyncing}
                      disabled={isSyncing}
                      className={`${
                          isSyncing ? "!bg-neutral opacity-90 !text-base-200 cursor-not-allowed" : ""
                      } mt-2 btn w-full bg-neutral rounded-[5px]`}

                  >
                    {isSyncing ? "Syncing..." : "Start Sync"}
                  </button>
                </div>
              </div>
            </label>
          </label>
        </div>
        <ToastContainer />
      </>
  );
};
export default DashboardPage;
