import {Link, useLocation} from "react-router-dom";
import {getUserData} from "../../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../../utils/config";
import React, {useEffect, useState} from "react";
import {App_Name} from "../../../utils/name";
import {AiFillCheckCircle} from "react-icons/ai";
import LoadingTableComponent from "../../loadingTable.component";

const Chat = () => {

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');

    const [comments,setComments]=useState([])

    const passedData = location.state;


    async function fetchData() {
        debugger
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            url:passedData.video_url,
            exam_id:passedData.exam_id
        };
        const response = await axios({
            url:`${API_BASE_URL}/get-livechat`,
            // url:`https://5fa2-106-213-39-251.ngrok-free.app/get-blog`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];
        setIsLoading(false)
        setComments(data1.data)
        // setBlogData(data1.data

    }

    useEffect(() => {
        const fetchDataInterval = setInterval(() => {
            fetchData();
        }, 3000);
        return () => {
            clearInterval(fetchDataInterval);
        };
    }, []);



    return(
        <>

            <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Comments </button>

                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className="rounded  flex">
                                        {/*<label htmlFor="CarouselModal" className="btn btn-ghost m-1 text-3xl"><AiOutlinePlusCircle/></label>*/}
                                        <input
                                            className="input input-bordered rounded-[2px]  mr-1 max-sm:input-xs max-sm:w-28"
                                            type="text"
                                            placeholder="Search "
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Middle container */}
                <main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin  ">
                    <p className="text-lg"><b>Video Title:</b> {passedData.video_title}</p>
                    <div className="gap-4 overflow-x-scroll overflow-y-scroll">
                        {
                            !isLoading?
                                    comments.map((comment,key) => (
                                        <div key={key}>
                                            <div className="card mt-2 mb-2 rounded-[5px] border shadow-sm">
                                                <div className="card-body">
                                                    <h2 className="card-title">{comment.user_name}</h2>
                                                    <p className="text-gray-500 text-xs">{comment.time}</p>
                                                    {/*<p className="text-gray-500 text-xs">{new Date(comment.time_create).toISOString().slice(0, 19).replace("T", " ")}</p>*/}
                                                    <p>{comment.comment}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                :<LoadingTableComponent/>
                        }
                    </div>
                </main>

            </div>

        </>
    )

}
export default Chat;
