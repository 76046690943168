import React, {useEffect, useRef, useState} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import {RxCross2} from "react-icons/rx";
import {getUserData} from "../../../utils/LocalHandler";
import {API_BASE_URL} from "../../../utils/config";

const WriteSeo = () => {


    const [slug, setSlug] = useState('');
    const [title, setTitle] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [keywords, setKeywords] = useState('');
    const [lang, setlang] = useState('');


    const userDetails = getUserData();
    const adminName=userDetails.admin_name;

    const editRef=useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const token = userDetails.token;

        const formData = {
            token: token,
            title:title,
            meta_data:metaDesc,
            keywords,
            slug,
            language:lang,
        };

        try {
            const response1 = await fetch(`${API_BASE_URL}/get-particular-blog?=${slug}`);
            const data = await response1.json();
            let slugFromAPI=data.data[0].slug
            if(slug==slugFromAPI){
                toast.warning('Duplicate slug not allowed', {
                    position: toast.POSITION.TOP_RIGHT,
                });

            }else {
                try {
                    const response = await fetch(`${API_BASE_URL}/create-seo`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                    });

                    if (response.ok) {
                        toast.success('Seo data added successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        setTitle("")
                        setKeywords("")
                        setlang("")
                        setSlug("")
                        setMetaDesc("")

                    } else {
                        toast.error('SEO data submission failed. Please try again.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } catch (error) {
                    console.error('Error submitting blog post:', error);
                    toast.error('An error occurred. Please try again later.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }

            }
        } catch (error) {
            console.error('Error submitting blog post:', error);
        }
    };

    const navigate = useNavigate();


    return (
        <>
            <input type="checkbox" id="createSEO" className="modal-toggle" />
            <label htmlFor="createSEO" className="modal cursor-pointer">
                <label className="modal-box pt-0 pb-0 relative" htmlFor="">
                    <div className="modal-header border sticky top-0 ml-[-28px] mr-[-28px] p-2 ">
                        <p className="text-2xl !pt-[-50px] font-bold c-font1 text-center">Add Seo Data</p>
                        <div className="card-actions  justify-end">
                            <label ref={editRef} htmlFor="createSEO" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Title</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Title"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Title</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Title"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Meta Description</span>
                            </label>
                            <textarea
                                id="content"
                                value={metaDesc}
                                onChange={(e) => setMetaDesc(e.target.value)}
                                className="textarea rounded-[2px]  textarea-bordered w-full"
                                placeholder="Blog Content"
                            ></textarea>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Keywords (Comma Separated)</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Title"
                                className="input rounded-[5px] input-bordered text-sm  c-font1"
                                value={keywords}
                                onChange={(e) => setKeywords(e.target.value)}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Language</span>
                            </label>
                            <select
                                id="selectedCategory"
                                value={lang}
                                onChange={(e) => setlang(e.target.value)}
                                className="input input-sm input-bordered rounded-[2px] w-full"
                                required
                            >
                                <option value="">Select a Lanuage</option>
                                <option value="eng">English</option>
                                <option value="hin">Hindi</option>
                            </select>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-neutral mt-4 mb-4 w-full capitalize rounded-[2px]"
                        >
                            Add
                        </button>
                    </form>
                </label>
            </label>
            <ToastContainer />
        </>

    );
};

export default WriteSeo;
