import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./utils/ProtectedRoute";
import PageNotFound from "./components/Pages/PageNotFound.component";
import Login from "./components/Pages/Login.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LiveTest from "./components/Pages/LiveTest";
import ExamManagement from "./components/Pages/ExamManagement";
import PendingPaymentPage from "./components/Pages/pendingPayment.page";
import CarouselPage from "./components/Pages/carousel.page";
import OmrTest from "./components/Pages/OmrTest";
import SideNav from "./components/HeaderFooter/sideNav";
import NavbarComponent from "./components/HeaderFooter/navbar.component";
import DashboardPage from "./components/Pages/dashboard.page";
import MainQuiz from "./components/Quiz/main.quiz";
import ViewAdmissions from "./components/Pages/viewAdmissions";
import Editor from "./components/editor.component";
import CommunityPage from "./components/Pages/community.page";
import SupportPage from "./components/Pages/support.page";
import BlogPage from "./components/Pages/blog/blog.page";
import PreviewPage from "./components/Pages/blog/preview.page";
import ViewPage from "./components/Pages/blog/View.page";
import UpdateBlog from "./components/Pages/blog/update.blog";
import MainSeo from "./components/Pages/Seo/main.seo";
import Chat from "./components/Pages/Livechat/Chat";
import Main from "./components/Pages/LiveQuiz/Main";
import IndexRecording from "./components/Pages/recording/IndexRecording";
import QuestionSys from "./components/Pages/question-sys/QuestionSys";
import IndexUser from "./components/Pages/User/indexUser";



function App() {
  return (

      <Router>

          {/*<NavbarComponent/>*/}

          <div className="flex ">
              {/*<SideNav/>*/}
              <div className="flex flex-col flex-1 overflow-y-auto overflow-x-auto">
                  <main className="flex-1 ">
                      <Routes>
                          <Route element={<ProtectedRoute />}>
                              <Route exact path="/" element={<DashboardPage />} />
                              <Route exact path="/omr-test" element={<OmrTest />} />
                              <Route exact path="/live-test" element={<LiveTest />} />
                              {/*<Route exact path="/live-quiz" element={<MainQuiz />} />*/}
                              <Route exact path="/live-quiz" element={<Main />} />
                              <Route exact path="/viewAdmissions" element={<ViewAdmissions />} />
                              <Route
                                  exact
                                  path="/exam-management"
                                  element={<ExamManagement />}
                              />
                              <Route
                                  exact
                                  path="/pending-payment"
                                  element={<PendingPaymentPage />}
                              />
                              <Route
                                  exact
                                  path="/carousels"
                                  element={<CarouselPage />}
                              />
                              <Route
                                  exact
                                  path="/community"
                                  element={<CommunityPage />}
                              />
                              <Route
                                  exact
                                  path="/support"
                                  element={<SupportPage />}
                              />
                              <Route
                                  exact
                                  path="/editor"
                                  element={<Editor />}
                              />
                              <Route
                                  exact
                                  path="/seo-json"
                                  element={<MainSeo />}
                              />
                              <Route
                                  exact
                                  path="/preview/:blogTitle"
                                  element={<PreviewPage />}
                              />
                              <Route
                                  exact
                                  path="/write-blog"
                                  element={<BlogPage />}
                              />
                              <Route
                                  exact
                                  path="/all-blogs"
                                  element={<ViewPage />}
                              />
                              <Route
                                  exact
                                  path="/edit-blog/:id"
                                  element={<UpdateBlog />}
                              />
                              <Route
                                  exact
                                  path="/live-chat/:id"
                                  element={<Chat />}
                              />
                              <Route
                                  exact
                                  path="/stream-recording"
                                  element={<IndexRecording />}
                              />
                              <Route
                                  exact
                                  path="/users"
                                  element={<IndexUser />}
                              />
                              <Route
                                  exact
                                  path="/question-system"
                                  element={<QuestionSys />}
                              />
                          </Route>
                          <Route path="login" element={<Login />} />
                          <Route path="*" element={<PageNotFound />} />
                      </Routes>
                      <ToastContainer />
                  </main>
              </div>
          </div>

      </Router>
  );
}

export default App;
