import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {getUserData} from "../../utils/LocalHandler";
import {BsStopwatch} from "react-icons/bs";
import {SiPlanetscale} from "react-icons/si";
import Swal from "sweetalert2";
import {AiFillCheckCircle, AiFillDelete, AiFillEdit, AiOutlinePlusCircle} from "react-icons/ai";
import CarouselModal from "../carousel.modal";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RxCross2} from "react-icons/rx";
import LoadingTableComponent from "../loadingTable.component";
import {API_BASE_URL} from "../../utils/config";
import {MdCancel} from "react-icons/md";
import {toast} from "react-toastify";
import {App_Name} from "../../utils/name";

const SupportPage=(props)=>{
    const [isLoading, setIsLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');
    const [pendingPayments, setPendingPayments] = useState([]);
    const [tab,setTab]=useState(1)

    const navigate=useNavigate()
    async function fetchTickets() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            crud_type: "read",
            db_table_name: "carousel",
            action_performing: "get-carousel",
        };
        const response = await axios({
            url:`${API_BASE_URL}/get-all-tickets`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];
        setPendingPayments(data1.data);
        setIsLoading(false)
        // console.log(data1.data)
        if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }

    useEffect(() => {
        fetchTickets();
    }, []);


    const filteredItems = pendingPayments.filter((qu) =>
        tab === 1
            ? qu.name.toLowerCase().includes(searchQuery.toLowerCase())
            : tab === 2
                ? qu.name.toLowerCase().includes(searchQuery.toLowerCase()) && !qu.resolveStatus==true
                : tab === 3
                    ? qu.name.toLowerCase().includes(searchQuery.toLowerCase()) && !qu.resolveStatus==false
                    : null
    );


    const handleResolve=async (id) => {

        const userDetails = getUserData();
        const token = userDetails.token;

        const liveTestCreatingToast = toast.warning('updating...', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            hideProgressBar: true,
            isLoading: true
        });

        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                id:id,
                resolveStatus:true,
            }),
        };
        const response = await fetch(
            // `http://localhost:8080/update-ticket`,
            `${API_BASE_URL}/update-ticket`,
            options
        );
        let resData = await response.json();
        // console.log(resData);
        if (resData["status"] === 200) {
            toast.update(liveTestCreatingToast, { render: "Resolved successfully", autoClose: 3000,
                type: "success", isLoading: false });
            fetchTickets()
        }
    }

    const modalRef=useRef();
    const [ticket,setTicket]=useState([])
    const openModal=(e)=>{
        setTicket(e)
        modalRef.current.click()
    }

    return(
        <>

            <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Support Queries {pendingPayments.length}</button>

                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className="rounded  flex">
                                        {/*<label htmlFor="CarouselModal" className="btn btn-ghost m-1 text-3xl"><AiOutlinePlusCircle/></label>*/}
                                        <input
                                            className="input input-bordered rounded-[2px]  mr-1 max-sm:input-xs max-sm:w-28"
                                            type="text"
                                            placeholder="Search "
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Middle container */}
                <main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin  ">
                    <div className="tabs mt-2 p-2 gap-2  max-sm:mt-1 overflow-auto tabs-boxed">
                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==1?"tab-active !bg-neutral":null}`}  onClick={()=>(
                            setTab(1))}>All</a>
                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==3?"tab-active !bg-neutral":null}`}  onClick={()=>(
                            setTab(3))}>Solved</a>
                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==2?"tab-active !bg-neutral":null}`}  onClick={()=>(
                            setTab(2))}>Pending</a>
                    </div>
                    <div className="h-[90%] overflow-x-scroll overflow-y-scroll">
                        {
                            !isLoading?<table className="border-separate w-full table-zebra table  table-compact ">
                                <thead className="border-b sticky top-0">
                                <tr>
                                    <th className="bg-[#50DBB4] pl-2 pr-2"></th>
                                    <th className="bg-[#50DBB4] pl-6 pr-6 text-center ">Name</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2 text-center ">Phone Number</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2 text-center">Email</th>
                                    <th className="bg-[#50DBB4] pl-20 z-20 pr-20 text-center !z-10 !w-20">Query</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10 text-center ">Date</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10 text-center !z-20">Resolve Status</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10 text-center ">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                { filteredItems.map((e, id) => (
                                    <tr key={id} >
                                        <th>{id + 1}</th>
                                        <td >
                                            {
                                                e.name
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.phone
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.email
                                            }
                                        </td>
                                        <td>
                                            {/*<p className="text-ellipsis w-80 line-clamp-1">{e.message}</p>*/}
                                            <button onClick={()=>{openModal(e)}} className="btn btn-ghost btn-xs">Click to read</button>
                                        </td>
                                        <td >
                                            {
                                                e.created_at
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.resolveStatus==1?
                                                    <span
                                                        className="relative !z-0 inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                                                    >
                                                    <span
                                                        aria-hidden
                                                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                                    ></span>
                                                    <span className="relative">Solved</span>
                                                </span>:<span
                                                        className="relative !z-0 inline-block px-3 py-1 font-semibold text-red-900 leading-tight"
                                                    >
                                                    <span
                                                        aria-hidden
                                                        className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                                    ></span>
                                                        <span className="relative">Pending</span></span>
                                            }

                                        </td>
                                        <td >
                                            <button
                                                className="btn btn-sm  btn-ghost text-2xl !rounded-[2px]"
                                                disabled={e.resolveStatus}
                                                onClick={()=>(handleResolve(e.id))}
                                            >
                                                <AiFillCheckCircle/>
                                            </button>
                                        </td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>:<LoadingTableComponent/>
                        }
                    </div>
                </main>

            </div>


            <input type="checkbox" id="queryModal" className="modal-toggle" />
            <label htmlFor="queryModal" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="grid grid-cols-2">
                        <div>
                            <p className="text-lg"><b>Ticket ID: </b>{ticket.id}</p>
                        </div>
                        <div className="justify-self-end">
                            <label ref={modalRef} htmlFor="queryModal" className="btn justify-self-end btn-ghost btn-sm ">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>

                    <div className="">
                        <h5 className="">Raised by <b>{ticket.name}</b> at <b>{ticket.created_at}</b>
                            {
                                ticket.resolveStatus==1?
                                    <div className="badge badge-sm ml-1 badge-success gap-2">
                                        Solved
                                    </div>:
                                    <div className="badge badge-sm ml-1  badge-error gap-2">
                                        Pending
                                    </div>
                            }
                        </h5>
                        <p className="mt-2"><b>Email: </b>{ticket.email} </p>
                        <p className="mt-2"><b>Phone: </b>{ticket.phone}</p>
                        <p className="py-4"><b>Ticket Details:</b> {ticket.message?ticket.message:"Not data found"}</p>
                    </div>
                </label>
            </label>

        </>
    )
}
export default SupportPage;
