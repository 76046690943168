import React, {useEffect, useRef, useState} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link, useLocation} from "react-router-dom";
import {App_Name} from "../../../utils/name";
import { useNavigate } from 'react-router-dom';
import {RxCross2} from "react-icons/rx";
import PreviewPage from "./preview.page";
import JoditEditor from "jodit-react";
import {getUserData} from "../../../utils/LocalHandler";
import {API_BASE_URL} from "../../../utils/config";
import axios from "axios";

const UpdateBlog = () => {

    const location = useLocation();

    const passedData = location.state;

    console.log(passedData)

    const [selectedCategorybyText, setSelectedCategorybyText] = useState('');
    const [blogTitle, setBlogTitle] = useState('');
    const [blogThumbnail, setBlogThumbnail] = useState('');
    const [blogDescription, setBlogDescription] = useState('');
    const [postDate, setPostDate] = useState('');
    const [keywords, setKeywords] = useState('');
    const [slug, setSlug] = useState('');
    const [content, setContent] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [author,setAuthor]=useState("")
    const [visibility,setVisiblity]=useState()

    const closeBtnRef = useRef()
    const userDetails = getUserData();
    const adminName=userDetails.admin_name;


    const [categoryData, setCategoryData] = useState([]);

    async function fetchExam() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
        };
        const response = await axios({
            url: `${API_BASE_URL}/read-exam-list`,
            method: "post",
            data: configData
        });
        let data= response['data'];
        console.log(data)
        setCategoryData(data['home_screen_categories'])
    }

    useEffect(() => {
        const currentDate = new Date();
        const currentISTDate = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000); // UTC+5:30
        const currentDateTime = currentISTDate.toISOString().substr(0, 16); // Format: YYYY-MM-DDTHH:MM
        setPostDate(currentDateTime);
        setAuthor(adminName)
        fetchExam()
    }, []);

    useEffect(() => {
        setBlogTitle(passedData.title || '');
        setBlogThumbnail(passedData.thumbnail || '');
        setBlogDescription(passedData.meta_data || ''); //
        setKeywords(passedData.keywords || '');
        setSlug(passedData.slug || '');
        setContent(passedData.content || '');
        setSelectedTags(passedData.tags || []);
        setAuthor(passedData.author || '');
        setVisiblity(passedData.visibility);

        if (!isNaN(parseInt(passedData.category)) && passedData.category!=="") {
            setSelectedCategory(passedData.category || '');
        } else {
            setSelectedCategorybyText(passedData.category || '');
        }
    }, [passedData]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const token = userDetails.token;

        const formData = {
            id:passedData.id,
            token: token,
            title:blogTitle,
            thumbnail:blogThumbnail,
            meta_data:blogDescription,
            // postDate,
            keywords,
            slug,
            content,
            author:adminName,
            category:selectedCategorybyText==""?selectedCategory:selectedCategorybyText,
            tags:selectedTags,
            visibility
        };

        const response1 = await fetch(`${API_BASE_URL}/get-particular-blog?slug=${slug}`);
        const data = await response1.json();

        let slugFromAPI;
        let examNameFromAPI
        data.data.length===0?slugFromAPI=" ":slugFromAPI=data.data[0].slug
        data.data.length===0?examNameFromAPI="000":slugFromAPI=data.data[0].id



        if(slug==slugFromAPI && formData.id!==examNameFromAPI){
            toast.warning('Duplicate slug not allowed!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else{
            try {
                // url:`${API_BASE_URL}/get-blog`,
                const response = await fetch(`${API_BASE_URL}/update-blog`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    body: JSON.stringify(formData),
                });
                const data= await response.json();
                if (data.status==200) {
                    toast.success('Blog post updated successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    navigate("/write-blog")
                } else {
                    toast.error('Blog post updation failed. Please try again.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                console.error('Error submitting blog post:', error);
                toast.error('An error occurred. Please try again later.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }

    };

    const navigate = useNavigate();

    const [data,setData]=useState([])
    const handlePreviewClick = () => {
        const dataToPass = {
            blogTitle,
            blogThumbnail,
            blogDescription,
            postDate,
            keywords,
            slug,
            content,
            selectedCategory,
            selectedTags,
            visibility
        };
        setData(dataToPass)
        // navigate(`/preview/${blogTitle}`, { state: dataToPass });
        closeBtnRef.current.click()
    };
    return (
        <>
            <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Edit Blog Post</button>

                            </div>
                            <div className="justify-self-end ">
                                <button
                                    onClick={handlePreviewClick}
                                    className="btn btn-outline btn-primary capitalize rounded-[2px] max-sm:btn-sm"
                                >
                                    Preview
                                </button>
                                {/*<Link*/}
                                {/*    to="/all-blogs"*/}
                                {/*    className="btn btn-outline btn-primary capitalize rounded-[2px]"*/}
                                {/*>*/}
                                {/*    View All*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </header>
                {/* Form section */}
                <div className="isolate bg-white p-5">
                    <form onSubmit={handleSubmit} className="  ">

                        <div className="grid grid-cols-3 gap-4 max-sm:grid-cols-1">
                            <div className="col-span-2">
                                <div className="sm:col-span-2">
                                    <label htmlFor="blogTitle" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Blog Title
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            id="blogTitle"
                                            value={blogTitle}
                                            onChange={(e) => setBlogTitle(e.target.value)}
                                            placeholder="Blog Title"
                                            className="input input-bordered rounded-[2px] w-full"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Blog Thumbnail */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="blogThumbnail" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Blog Thumbnail URL
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            id="blogThumbnail"
                                            value={blogThumbnail}
                                            onChange={(e) => setBlogThumbnail(e.target.value)}
                                            placeholder="Thumbnail URL"
                                            className="input input-bordered rounded-[2px] w-full"
                                        />
                                    </div>
                                </div>

                                {/*Content */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="content" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Content
                                    </label>
                                    <div className="mt-2.5">
                                        <JoditEditor
                                            value={content}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={newContent => console.log(newContent)}
                                            onChange={newContent => {setContent(newContent)
                                            }}
                                            style={{ height: "600px", minHeight:"1000px" }}
                                        />
                                        {/*<textarea*/}
                                        {/*    id="content"*/}
                                        {/*    value={content}*/}
                                        {/*    onChange={(e) => setContent(e.target.value)}*/}
                                        {/*    className="textarea rounded-[2px] textarea-md h-80 textarea-bordered w-full"*/}
                                        {/*    placeholder="Blog Content"*/}
                                        {/*></textarea>*/}
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* Blog Description */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="blogDescription" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Blog Description
                                    </label>
                                    <div className="mt-2.5">
                                        <textarea
                                            id="blogDescription"
                                            value={blogDescription}
                                            onChange={(e) => setBlogDescription(e.target.value)}
                                            placeholder="Blog Description"
                                            className="textarea rounded-[2px] textarea-md textarea-bordered w-full"
                                            required
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="postDate" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Author
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            id="postDate"
                                            disabled={true}
                                            value={author}
                                            onChange={(e) => setAuthor(e.target.value)}
                                            className="input input-sm input-bordered rounded-[2px] w-full"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Category */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="selectedCategory" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Select Category
                                    </label>
                                    <div className="mt-2.5">
                                        <div className="grid  gap-2 grid-cols-3">
                                            <div className="col-span-2">

                                                    <div>
                                                        <input
                                                            value={selectedCategorybyText}
                                                            type="text"
                                                            placeholder="Category"
                                                            onChange={(e) => setSelectedCategorybyText(e.target.value)}
                                                            className="input input-sm input-bordered rounded-[2px] w-full "
                                                            required={selectedCategorybyText.length>1?true:false}
                                                        />
                                                    </div>

                                            </div>
                                            <div >
                                                <select
                                                    id="selectedCategory"
                                                    value={selectedCategory}
                                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                                    className="input input-sm input-bordered rounded-[2px] w-full"
                                                    required={!selectedCategorybyText.length>1?true:false}
                                                >
                                                    <option value="">Select a category</option>
                                                    {categoryData.map((item, key) => (
                                                        <option key={key} value={item.id}>
                                                            {item.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Tags */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="selectedTags" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Select Tags (comma-separated)
                                    </label>
                                    <div className="mt-2.5">
                                        {/*<Chips*/}
                                        {/*    value={selectedTags}*/}
                                        {/*    onChange={(chips) => setSelectedTags(chips)}*/}
                                        {/*    placeholder="Add tags..."*/}
                                        {/*    suggestions={tags}*/}
                                        {/*/>*/}

                                        <input
                                            type="text"
                                            id="tags"
                                            value={selectedTags}
                                            onChange={(e) => setSelectedTags(e.target.value)}
                                            placeholder="Tags"
                                            className="input input-sm input-bordered rounded-[2px] w-full"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Keywords */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="keywords" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Keywords (comma-separated)
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            id="keywords"
                                            value={keywords}
                                            onChange={(e) => setKeywords(e.target.value)}
                                            placeholder="Keywords"
                                            className="input input-sm input-bordered rounded-[2px] w-full"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Slug */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="slug" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Slug
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            id="slug"
                                            value={slug}
                                            onChange={(e) => setSlug(e.target.value)}
                                            placeholder="Slug"
                                            className="input input-sm input-bordered rounded-[2px] w-full"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="selectedCategory" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Post Visibility
                                    </label>
                                    <div className="mt-2.5">
                                            <div className="col-span-2">
                                                <select
                                                    id="selectedCategory"
                                                    value={visibility}
                                                    onChange={(e) => setVisiblity(e.target.value)}
                                                    className="input input-sm input-bordered rounded-[2px] w-full"
                                                    required
                                                >
                                                    <option >Select</option>
                                                    <option value="1" >Public</option>
                                                    <option value="0" >Private</option>
                                                </select>
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Submit Button */}
                        <div className="mt-5">
                            <button
                                type="submit"
                                className="btn btn-neutral w-full capitalize rounded-[2px]"
                            >
                               Update Post
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <input type="checkbox" id="previewModal" className="modal-toggle" />
            <label htmlFor="previewModal" className="modal cursor-pointer">
                <label className="modal-box w-11/12	 max-w-screen-2xl relative" htmlFor="">
                    <div className="sticky top-0 z-10">
                        <div className="card-actions  justify-end">
                            <label ref={closeBtnRef} htmlFor="previewModal" className="btn btn-ghost bg-gray-500 bg-opacity-20  btn-sm ">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>
                    <PreviewPage data={data}/>
                </label>
            </label>

            <ToastContainer />
        </>

    );
};

export default UpdateBlog;
