import React, {useEffect, useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import {App_Name} from "../../../utils/name";

import WriteSeo from "./write.seo";
import {AiFillEye} from "react-icons/ai";
import UpdateSeo from "./update.seo";
import {getUserData} from "../../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../../utils/config";
import {toast, ToastContainer} from "react-toastify";
import {RxCross2} from "react-icons/rx";
import {SiPlanetscale} from "react-icons/si";
import {BsStopwatch} from "react-icons/bs";
import LoadingTableComponent from "../../loadingTable.component";

const MainSeo = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');


    const [slug, setSlug] = useState('');
    const [title, setTitle] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [keywords, setKeywords] = useState('');
    const [lang, setlang] = useState('');
    const [examId, setExamId] = useState('');



    const editRef=useRef();

    const [tab,setTab]=useState(1)

    const [data,setData]=useState([])
    async function fetchData() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
        };
        const response = await axios({
            // url:`${API_BASE_URL}/get-home`,
            url:`${API_BASE_URL}/get-home`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];
        setData(data1.data);
        setIsLoading(false)
    }


    const [passData,setPassData]=useState([])
    const handleEdit=(blog)=>{
        // let seoJson = {}
        // try{seoJson = JSON.parse(blog?.seo_json)}catch(e){seoJson = {}}
        // blog.seo_json = seoJson;
        setPassData(blog)
        editRef.current.click()
    }

    const handleUpdate = async (event) => {
        event.preventDefault();
        const userDetails = getUserData();
        const token = userDetails.token;

        const formData = {
            exam_id:examId,
            token: token,
            slug,
            seo_json:JSON.stringify({
                title:title,
                meta_data:metaDesc,
                keywords:keywords,
                language:lang,
            })
        };

        try {
            const response1 = await fetch(`${API_BASE_URL}/get-home-data-with-slug?token=${token}&slug=${slug}`);
            const data = await response1.json();
            let slugFromAPI=data?.data[0]?.slug
            let examIdfromAPI=data?.data[0]?.exam_id
            if(slug==slugFromAPI && examId !==examIdfromAPI){
                toast.warning('Duplicate slug not allowed', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }else {
                try {
                    const response = await fetch(`${API_BASE_URL}/update-exam-seo`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                    });
                   // console.log(response)
                   //
                    if (response.status===200) {
                        toast.success('Seo data updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTitle("")
                        setKeywords("")
                        setlang("")
                        setSlug("")
                        setMetaDesc("")
                        setExamId("")
                        fetchData()
                        editRef.current.click()

                    } else {
                        toast.error('SEO data update failed. Please try again.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }

                } catch (error) {
                    console.error('Error submitting:', error);
                    toast.error('An error occurred. Please try again later.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        } catch (error) {
            console.error('Error submitting blog post:', error);
        }
    };


    const filteredItems = data.filter((qu) =>
        tab === 1
            ? qu.exam_name.toLowerCase().includes(searchQuery.toLowerCase())
            : tab === 2
                ? qu.exam_name.toLowerCase().includes(searchQuery.toLowerCase()) && qu.slug!==null && qu.seo_json!==null
                : tab === 3
                    ? qu.exam_name.toLowerCase().includes(searchQuery.toLowerCase()) && qu.slug==null && qu.seo_json==null
                        : null
    );





    useEffect(() => {
        fetchData()
    }, []);

    // update
    useEffect(() => {
        setExamId(passData.exam_id)
        setTitle(passData?.seo_json?.title || '');
        setSlug(passData.slug || '');
        setKeywords(passData?.seo_json?.keywords || '');
        setMetaDesc(passData?.seo_json?.meta_data || '');
        setlang(passData?.seo_json?.language || '');
    }, [passData]);


    return (
        <>
            <div className="bg-base-00 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">{
                                 "SEO Data"
                                }</button>
                            </div>
                            <div className="justify-self-end col-span-2">
                                {/*<label htmlFor="createSEO" className="btn mr-1 capitalize rounded-[5px]">Add Data</label>*/}
                                <input
                                    className="input input-bordered rounded-[2px]  mr-2 max-sm:input-xs max-sm:w-28"
                                    type="text"
                                    placeholder="Search "
                                    value={searchQuery}
                                    onChange={(event) => setSearchQuery(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </header>

                <main>

                    <div className="tabs mt-2 p-2 gap-2  max-sm:mt-1 overflow-auto tabs-boxed">
                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==1?"tab-active !bg-neutral":null}`}  onClick={()=>(
                            setTab(1))}>All</a>
                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==3?"tab-active !bg-neutral":null}`}  onClick={()=>(
                            setTab(3))}>Empty</a>
                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==2?"tab-active !bg-neutral":null}`}  onClick={()=>(
                            setTab(2))}>Filtered</a>
                    </div>

                    <main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin  ">
                        <div className="h-[90%] overflow-x-scroll overflow-y-scroll">
                            {
                                !isLoading?<table className="border-separate w-full table-zebra table  table-compact ">
                                    <thead className="border-b sticky top-0">
                                    <tr>
                                        <th className="bg-[#50DBB4] text-center">Exam Name</th>
                                        <th className="bg-[#50DBB4] text-center">Slug</th>
                                        <th className="bg-[#50DBB4] text-center">Title</th>
                                        <th className="bg-[#50DBB4] text-center">Keywords</th>
                                        <th className="bg-[#50DBB4] text-center">Language</th>
                                        <th className="bg-[#50DBB4] text-center">Description</th>
                                        <th className="bg-[#50DBB4] text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        filteredItems.length==0?
                                            <div className="h-screen w-screen">
                                                <p className="text-center mt-4">No data Found..</p>
                                            </div>:
                                            filteredItems.map((e, id) => (
                                        <tr key={id} >
                                            <td >
                                                {
                                                    e?.exam_id
                                                }
                                                <span>  • </span>
                                                {
                                                   e?.exam_name
                                                }
                                            </td>
                                            <td>
                                                <p className="text-ellipsis w-40 line-clamp-1">{e?.slug}</p>
                                            </td>

                                            <td>
                                                <p className="text-ellipsis w-40 line-clamp-1">{e.seo_json?.title}</p>

                                            </td>

                                            <td>
                                                <p className="text-ellipsis w-64 line-clamp-1">{e.seo_json?.keywords}</p>
                                            </td>
                                            <td>
                                                {e.seo_json?.language=="eng"
                                                    ? "English"
                                                        :e.seo_json?.language=="hin"
                                                        ?"Hindi"
                                                        :"Not Set"}
                                            </td>
                                            <td>
                                                <p className="text-ellipsis w-80 line-clamp-1">{e.seo_json?.meta_data}</p>
                                            </td>

                                            <td >
                                                <div className="btn-group">
                                                    {
                                                        <button
                                                            className="btn btn-sm  btn-outline !rounded-[2px]"
                                                            onClick={()=>(handleEdit(e))}
                                                        >
                                                            {/*<AiFillEdit/>*/} Edit
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )) }
                                    </tbody>
                                </table>:<LoadingTableComponent/>
                            }
                        </div>
                    </main>



                    {/*<div className="grid mt-2 gap-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">*/}
                    {/*    {*/}
                    {/*        data.length==0?*/}
                    {/*            <div className="h-screen w-screen">*/}
                    {/*                <p className="text-center mt-4">Loading..</p>*/}
                    {/*            </div>:*/}
                    {/*            data.map((blog,key)=>*/}
                    {/*            {*/}
                    {/*                // let seoJson = blog.seo_json ? JSON.parse(blog.seo_json):{};*/}
                    {/*                // try{seoJson = JSON.parse(blog?.seo_json)}catch(e){seoJson = {}}*/}
                    {/*                return (*/}
                    {/*                // eslint-disable-next-line react/jsx-key*/}
                    {/*                <div key={{key}}>*/}
                    {/*                    <div className="card border  text-left rounded-[10px] bg-base-100 shadow-xl">*/}
                    {/*                        <div className="card-body">*/}
                    {/*                            <h2 className="card-title text-ellipsis line-clamp-1">{blog?.exam_name}</h2>*/}
                    {/*                            <p className="text-ellipsis line-clamp-1"><b>Title</b>: {blog.seo_json?.title}</p>*/}
                    {/*                            <p className="text-ellipsis line-clamp-1"><b>Slug</b>: {blog?.slug}</p>*/}
                    {/*                            <p className="text-ellipsis line-clamp-1"><b>Language</b>: {blog.seo_json?.language}</p>*/}
                    {/*                            <p className="text-ellipsis line-clamp-1"><b>Keywords</b>: {blog.seo_json?.keywords}</p>*/}
                    {/*                            <p className="text-ellipsis line-clamp-1">{blog.seo_json?.meta_Data}</p>*/}
                    {/*                            <div className="card-actions">*/}
                    {/*                                <button onClick={()=>(handleEdit(blog))} className="btn btn-neutral btn-outline w-full rounded-[5px]">Edit</button>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            )})*/}
                    {/*    }*/}
                    {/*</div>*/}


                </main>


            </div>
            <WriteSeo/>



            {/*modal for update*/}

            <input type="checkbox" id="updateSEO" className="modal-toggle" />

            <label htmlFor="updateSEO" className="modal cursor-pointer">
                <label className="modal-box pt-0 pb-0 relative" htmlFor="">
                    <div className="modal-header border sticky top-0 ml-[-28px] mr-[-28px] p-2 ">
                        <p className="text-2xl !pt-[-50px] font-bold c-font1 text-center">Edit {title}</p>
                        <div className="card-actions  justify-end">
                            <label ref={editRef} htmlFor="updateSEO" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>
                    <form onSubmit={handleUpdate}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Title</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Title"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Slug</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter slug"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Meta Description</span>
                            </label>
                            <textarea
                                id="content"
                                value={metaDesc}
                                onChange={(e) => setMetaDesc(e.target.value)}
                                className="textarea rounded-[2px]  textarea-bordered w-full"
                                placeholder="Blog Content"
                            ></textarea>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Keywords (Comma Separated)</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Title"
                                className="input rounded-[5px] input-bordered text-sm  c-font1"
                                value={keywords}
                                onChange={(e) => setKeywords(e.target.value)}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Language</span>
                            </label>
                            <select
                                id="selectedCategory"
                                value={lang}
                                onChange={(e) => setlang(e.target.value)}
                                className="input input-sm input-bordered rounded-[2px] w-full"
                                required
                            >
                                <option value="">Select a Lanuage</option>
                                <option value="eng">English</option>
                                <option value="hin">Hindi</option>
                            </select>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-neutral mt-4 mb-4 w-full capitalize rounded-[2px]"
                        >
                            Update
                        </button>
                    </form>
                </label>
            </label>

            <ToastContainer />


        </>

    );
};

export default MainSeo;
