import React, {useEffect, useRef, useState} from "react";
import {getUserData} from "../../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../../utils/config";
import {Link} from "react-router-dom";
import {App_Name} from "../../../utils/name";
import LoadingTableComponent from "../../loadingTable.component";
import {RxCross2} from "react-icons/rx";
import VideoPlayer from "../../VideoPlayer";
import {toast} from "react-toastify";


const IndexRecording = () => {



	const [isLoading, setIsLoading] = useState(true);
	const [quality, setQuality] = useState('auto'); // Default quality level


	const [videoTitle,setVideoTitle]=useState()
	const [examId,setExamId]=useState()

	const [link,setLink]=useState("")

	const [streamData,setStreamData]=useState({
		startTime:"",
		endTime:""
	})

	const [tab,setTab]=useState(0)

	const [data,setData]=useState([])
	async function fetchData() {
		const userDetails = getUserData();
		const token = userDetails.token;
		const configData = {
			token,
			channel_id:tab
		};
		const response = await axios({
			// url:`${API_BASE_URL}/get-home`,
			url:`${API_BASE_URL}/get-sessions`,
			method: "post",
			data: configData
		});
		let data1 =await response;
		setData(data1.data.sessions);
		setIsLoading(false)
	}

	useEffect(() => {
		fetchData()
	}, [tab]);

    function formatDate(inputDate) {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        const date = new Date(inputDate);
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Add "st", "nd", "rd", or "th" to the day
        const day = date.getDate();
        const daySuffix = getDaySuffix(day);
        const formattedWithSuffix = formattedDate.replace(/\d{1,2}/, day + daySuffix);

        return formattedWithSuffix;
    }

	function formatDate1(inputDate) {
		const options = { year: 'numeric', month: 'short', day: 'numeric' };
		const date = new Date(inputDate);
		const formattedDate = date.toLocaleDateString('en-US', options);

		// Add "st", "nd", "rd", or "th" to the day
		const day = date.getDate();
		const daySuffix = getDaySuffix(day);
		const formattedWithSuffix = formattedDate.replace(/\d{1,2}/, day + daySuffix);

		return formattedWithSuffix;
	}

    function getDaySuffix(day) {
        if (day >= 11 && day <= 13) {
            return 'th';
        }
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }


	const closeBtnRef=useRef()
	const getRecording=async (par) => {
        setLink("")
		debugger

		var inputDate = new Date(par);

		if (inputDate.getUTCSeconds() === 59 || inputDate.getUTCSeconds() === 58 || inputDate.getUTCSeconds() === 57) {
			inputDate.setUTCSeconds(0);
			inputDate.setUTCMinutes(inputDate.getUTCMinutes() + 1);
		}

		var roundedStr = inputDate.toISOString();

        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            channel_id: tab,
            date_time: roundedStr
        };
        const response = await axios({
            // url:`${API_BASE_URL}/get-home`,
            url: `${API_BASE_URL}/get-recording`,
            method: "post",
            data: configData
        });
        let data1 = await response;
        setLink(data1.data.recordingUrl);
		closeBtnRef.current.click()
        setIsLoading(false)
    }


	const updateVdoWithRe= async (e)=>{
		e.preventDefault();
		const userDetails = getUserData();
		const token = userDetails.token;
		const liveTestCreatingToast = toast.warning('Stream Link updating...', {
			position: toast.POSITION.TOP_RIGHT,
			theme: "colored",
			hideProgressBar: true,
			isLoading: true
		});

		let options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
				exam_id:examId,
				video_title:videoTitle,
				video_url:link
			}),
		};
		const response = await fetch(
			`${API_BASE_URL}/create-video`,
			options
		);
		let resData = await response.json();
		if (resData["status"] === 400) {

			toast.update(liveTestCreatingToast, { render: "Video link already exists", autoClose: 3000,
				type: "error", isLoading: false });
		}
		else if(resData["status"] === 200){
			toast.update(liveTestCreatingToast, { render: "Updated successfully", autoClose: 3000,
				type: "success", isLoading: false });
		}
	}


	return(
		<>


			<div className="bg-base-00 !p-4 m-0 flex flex-col h-screen ">
				{/* Header */}
				<header >
					<div className=" mx-auto">
						<div className="grid  grid-cols-2 mb-2">
							<div className="">
								<Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
								<button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">{
									"Stream Recording"
								}  {data.length}</button>
							</div>
							<div className="ml-auto">
                                {/*{*/}
								{/*	link?*/}
                                {/*        <div className="alert w-[600px] overflow-auto rounded-[2px] alert-sm alert-success">*/}
                                {/*            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>*/}
                                {/*            <span>{link}</span>*/}
                                {/*        </div>*/}
								{/*		:link=="error"?*/}
                                {/*        <div className="alert rounded-[2px] alert-sm alert-error">*/}
                                {/*            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>*/}
                                {/*            <span>Error! Check Time.</span>*/}
                                {/*        </div>:null*/}
								{/*}*/}
							</div>
						</div>
					</div>
				</header>

				<main>

					<div className="tabs mt-2 p-2 gap-2  max-sm:mt-1 overflow-auto tabs-boxed">
						<a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab===0?"tab-active !bg-neutral":null}`}  onClick={()=>(
							setTab(0))}>Default Channel</a>
						<a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab===1?"tab-active !bg-neutral":null}`} onClick={()=>(
							setTab(1))}>Channel 1</a>
						<a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab===2?"tab-active !bg-neutral":null}`}  onClick={()=>(
							setTab(2))}>Channel 2</a>
						<a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab===3?"tab-active !bg-neutral":null}`}  onClick={()=>(
							setTab(3))}>Channel 3</a>
						<a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab===4?"tab-active !bg-neutral":null}`}  onClick={()=>(
							setTab(4))}>Channel 4</a>

					</div>

					<main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin  ">
						<div className="h-[90%] overflow-x-scroll overflow-y-scroll">
							{
								!isLoading?<table className="border-separate w-full table-zebra table  table-compact ">
									<thead className="border-b sticky top-0">
									<tr>
										<th className="bg-[#50DBB4] text-center">Sr No</th>
										<th className="bg-[#50DBB4] text-center">Stream Start Time</th>
										<th className="bg-[#50DBB4] text-center">Stream End Time</th>
										<th className="bg-[#50DBB4] text-center">Action</th>
									</tr>
									</thead>
									<tbody>
									{
										data.length==0?
											<div className="h-screen w-screen">
												<p className="text-center mt-4">No data Found..</p>
											</div>:
											data.map((e, id) => (
												<tr key={id} >
													<td>
                                                        {
															id+1
														}
													</td>
													<td >
														{
                                                            formatDate(e?.startTime)
														}
													</td>
													<td>
														{
                                                            e.endTime?formatDate(e.endTime): <img src="https://image.bigbooster.in/ea1aaea7-9b46-46a7-b8d4-036b25207d0b.png" className="w-10 m-auto"/>
														}
													</td>
													<td>
														<button disabled={e.endTime?false:true} className="btn btn-info btn-sm text-white rounded-[2px]" onClick={()=>(getRecording(e.startTime), setStreamData({startTime: e.startTime,endTime: e.endTime}), setVideoTitle(formatDate1(e.startTime)))} >Get</button>
													</td>
												</tr>
											)) }
									</tbody>
								</table>:<LoadingTableComponent/>
							}
						</div>
					</main>
				</main>


			</div>



			<input type="checkbox" id="recordingModal" className="modal-toggle" />
			<label htmlFor="recordingModal" className="modal cursor-pointer">
				<label className="modal-box pt-0 pb-0 relative" htmlFor="">
					<div className="grid grid-cols-2 mt-4">
						<div >
							<p className=" font-bold ">Play Recording - Channel {tab}</p>
						</div>
						<div className="justify-self-end">
							<label ref={closeBtnRef} htmlFor="recordingModal" className="btn btn-ghost btn-sm ">
								<RxCross2/>
							</label>
						</div>
					</div>
					<hr/>
					<p className="mt-2 mb-2">Stream Started at: <strong>{formatDate(streamData.startTime)}</strong> and Ended at <strong>{formatDate(streamData.endTime)}</strong></p>
					<hr/>

					{/*<ReactHlsPlayer*/}
					{/*	src={link}*/}
					{/*	autoPlay={false}*/}
					{/*	controls={true}*/}
					{/*	width="100%"*/}
					{/*	height="auto"*/}
					{/*/>*/}
					{/*<HlsPlayer videoUrl={link} />*/}

					<VideoPlayer videoUrl={link} />

					{
						link=="error"?
							<div className="alert p-1 mt-4 mb-4 alert-error rounded-[2px] ">
								<div>
									<span>Please check date time of Live Stream</span>
								</div>
							</div>
							:link?
								<>
									<b>Recording Url:</b>
									<div className="alert w-full overflow-auto  p-1  mb-4 alert-success rounded-[2px]">
										<div>

											<span>{link}</span>
										</div>
									</div>
								</>
								:null
					}


					{/*<form onSubmit={updateVdoWithRe}>*/}

					{/*	<div className="form-control">*/}
					{/*		<label className="label">*/}
					{/*			<span className="label-text">Exam ID</span>*/}
					{/*		</label>*/}
					{/*		<input*/}
					{/*			type="text"*/}
					{/*			placeholder={"exam id"}*/}
					{/*			className="input  rounded-[5px] input-bordered  c-font1"*/}
					{/*			value={examId}*/}
					{/*			onChange={(e) => {*/}
					{/*				setExamId(e.target.value);*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</div>*/}

					{/*	<div className="form-control">*/}
					{/*		<label className="label">*/}
					{/*			<span className="label-text">Video Title</span>*/}
					{/*		</label>*/}
					{/*		<input*/}
					{/*			type="text"*/}
					{/*			placeholder={"video title"}*/}
					{/*			className="input  rounded-[5px] input-bordered  c-font1"*/}
					{/*			value={videoTitle}*/}
					{/*			onChange={(e) => {*/}
					{/*				setVideoTitle(e.target.value);*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</div>*/}

					{/*	<button className="mt-4 mb-4 btn bg-neutral w-full rounded-[2px]">Update</button>*/}
					{/*</form>*/}

				</label>
			</label>

		</>
	)
}
export default IndexRecording;
