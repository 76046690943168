import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const PreviewPage = (props) => {
    const location = useLocation();
    // const passedData = location.state;
    const passedData = props.data;
        console.log(passedData)
    const navigate = useNavigate();
    return (
        <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
        <div className="grid grid-cols-3 max-sm:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 max-md:grid-cols-1">
            <div className="col-span-2">
                <div className="card m-5 border-b-2 border-neutral card-compact rounded-[10px] border bg-base-100 c-shadow">
                    <figure>
                            {passedData.blogThumbnail ? (
                                <img
                                    className="w-full max-lg:h-[400px] max-sm:w-auto max-sm:h-auto"
                                    src={passedData.blogThumbnail}
                                    alt="Image"
                                />
                            ) : null}
                    </figure>
                    <div className="card-body">
                        <p className="text-gray-500">{passedData.postDate}</p>
                        <h2 className="card-title">{passedData.blogTitle}</h2>
                        <div className="mt-4 max-w-xl">
                            <style>
                                {`
                                table, th, td{ border: 1px solid gray !important;
  border-collapse: collapse !important;}
 
                                `}
                            </style>
                            <div dangerouslySetInnerHTML={{ __html:  passedData.content }} />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="card m-5 border text-left md:w-96 md:ml-auto md:mr-auto rounded-[10px] bg-base-100 shadow-xl">
                    <div className="card-body">
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Blog Description:</h3>
                            <p>{passedData.blogDescription}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Category:</h3>
                            <p>{passedData.selectedCategory}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Tags:</h3>
                            <p>{passedData.selectedTags}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Keywords:</h3>
                            <p>{passedData.keywords}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Slug:</h3>
                            <p>{passedData.slug}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Author:</h3>
                            <p>{passedData.author}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Visibility:</h3>
                            <p>{passedData.visibility==1?"Public":"Private"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default PreviewPage;
