import {RxCross2} from "react-icons/rx";
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import CarouselPage from "./Pages/carousel.page";
import Swal from "sweetalert2";
import {getUserData} from "../utils/LocalHandler";
import {API_BASE_URL} from "../utils/config";

const CarouselModal=()=>{

    const closeBtnRef=useRef();

    const [logoUrl,setLogoUrl]=useState();
    const [title,setTitle]=useState();
    const [action,setAction]=useState();
    const [actionStatement,setActionStatement]=useState();

    const navigate=useNavigate();

    const handleAdd= async (e)=>{
        e.preventDefault();
        const userDetails = getUserData();
        const token = userDetails.token;
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                carousel_title:title,
                carousel_url:logoUrl,
                action_type:action,
                action_statement:actionStatement
            }),
        };
        const response = await fetch(
            `${API_BASE_URL}/add-carousel`,
            options
        );
        let resData = await response.json();
        console.log(resData);
        if (resData["status"] === 404) {
        }

        setTitle("")
        setActionStatement("")
        setLogoUrl("")
        setAction("")
        closeBtnRef.current.click();

    }

    return(
        <>
            <input type="checkbox" id="CarouselModal" className="modal-toggle" />
            <label htmlFor="CarouselModal" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <p className="text-2xl font-bold c-font1">Add Carousel Data</p>
                    <div className="card-actions  justify-end">
                        <label ref={closeBtnRef} htmlFor="CarouselModal" className="btn btn-ghost btn-sm mt-[-30px]">
                            <RxCross2/>
                        </label>
                    </div>
                    <form className="text-center" onSubmit={handleAdd}>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Enter logo Url</span>
                            </label>
                            <input type="text" placeholder="Enter logo url" className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                                   value={logoUrl} onChange={(e)=>{setLogoUrl(e.target.value);}}/>
                        </div>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Enter Title</span>
                            </label>
                            <input type="text" placeholder="Enter title" className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                                   value={title} onChange={(e)=>{setTitle(e.target.value);}}/>
                        </div>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Select Action</span>
                            </label>
                            <select className="select select-bordered " value={action}
                                    onChange={(event) => {
                                        setAction(event.target.value)
                                    }}>
                                <option value="target_exam">Target Exam</option>
                                <option value="launch_url">Launch Url</option>
                            </select>
                        </div>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Enter Action Statement</span>
                            </label>
                            <input type="text" placeholder="Enter Action Statement" className="input rounded-[5px] input-bordered text-sm  !rounded-sm c-font1"
                                   value={actionStatement} onChange={(e)=>{setActionStatement(e.target.value);}}/>
                        </div>
                        <button className="btn pl-10 pr-10 m-2">Add</button>
                    </form>
                </label>
            </label>
        </>
    )
}
export default CarouselModal;
