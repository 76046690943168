
import { useNavigate } from "react-router-dom";
import React from "react";
export const getDataWithKey = (key) => {
    return JSON.parse(sessionStorage.getItem(key)) || {};
}

export const addDataWithKey = (value) => {
    sessionStorage.setItem("user_details", JSON.stringify(value));
    return true;
}

export const checkUserLoggedIn = () => {
    const userDetails = getDataWithKey('user_details');
    return Object.keys(userDetails).length !== 0 && userDetails.phone ? true : false;
}

export const getUserData = () => {
    return getDataWithKey('user_details');
}

export const LoggedOut = () => {
    const navigate = useNavigate();
    setTimeout(() => {
        navigate("/login")
    }, 1000)
    sessionStorage.clear();
    return (
        <div>Logging out.... </div>
    )
}
