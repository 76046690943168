import React, {useEffect, useRef, useState} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import {App_Name} from "../../../utils/name";
import { useNavigate } from 'react-router-dom';
import {RxCross2} from "react-icons/rx";
import PreviewPage from "./preview.page";
import JoditEditor from "jodit-react";
import {getUserData} from "../../../utils/LocalHandler";
import WriteBlog from "./write.blog";
import ViewPage from "./View.page";

const BlogPage = () => {


    const [tab,setTab]=useState(1)


    return (
        <>
            <div className="bg-base-00 !p-4 m-0 flex flex-col h-screen ">
                    {/* Header */}
                    <header >
                        <div className=" mx-auto">
                            <div className="grid  grid-cols-3 mb-2">
                                <div className="">
                                    <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                    {/*<button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">{*/}
                                    {/*    tab==1?"Create Blog Post":"All Posts"*/}
                                    {/*}</button>*/}
                                </div>
                                <div className="justify-self-end col-span-2">
                                    <div className="tabs mt-2 p-2 gap-2  max-sm:mt-1 overflow-auto tabs-boxed">
                                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==1?"tab-active !bg-neutral":null}`}  onClick={()=>(
                                            setTab(1))}>Write</a>
                                        <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==2?"tab-active !bg-neutral":null}`}  onClick={()=>(
                                            setTab(2))}>View All</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <hr/>
                {
                    tab==1?<WriteBlog/>:tab==2?<ViewPage/>:null
                }

            </div>

        </>

    );
};

export default BlogPage;
