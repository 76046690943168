import React, { useRef } from 'react';
import FileUploadComponent from './fileUpload.component';
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from 'react-toastify';
import {App_Name} from "../utils/name";
import {AiOutlineLogout} from "react-icons/ai";
import {Link, useNavigate} from "react-router-dom";

function Editor() {
    const editor = useRef(null);
    const navigate=useNavigate()
    const handleSignOut=()=>{
        sessionStorage.clear();
        navigate("/login")
    }
    return (
        <>
            <div className="bg-gray-200 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header>
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3  mb-2">
                            <div className="col-span-2">
                                <Link to="/" className="text-xl capitalize btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">
                                    {App_Name}
                                </Link>
                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className="rounded  flex">
                                        <button onClick={handleSignOut}
                                                className={`btn pr-10 pl-10 mr-1 !rounded-[2px] gap-2 max-sm:mr-1 max-sm:btn-sm`}
                                        > <AiOutlineLogout/>
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div>
                <JoditEditor
                    ref={editor}
                    value={"Welcome sir/mam"}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => console.log(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {
                    }}
                />
                <FileUploadComponent />
            </div>
            </div>
        </>
    )
}

export default Editor
