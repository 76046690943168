import React, {useEffect, useState} from "react";
import axios from "axios";
import LoadingTableComponent from "../loadingTable.component";
import {getUserData} from "../../utils/LocalHandler";
import Swal from "sweetalert2";
import {API_BASE_URL} from "../../utils/config";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {App_Name} from "../../utils/name";
import {viewSets} from "./ExamManagement";
import {deductPercentage} from "../../utils/revenue";
import AdmGraph from "../AdmGraph";
import AdmGraphForExam from "../AdmGraphForExam";
import {Bar} from "react-chartjs-2";

const ViewAdmissions=()=>{

    const location=useLocation();
    // console.log(location.state.examData)
    const [examName, setExamName] = useState(location.state.examName);
    const [examId,setExamId]=useState(location.state.examId);

    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchExam, setSearchExam] = useState('');
    const [admData, setAdmData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [currentItems, setCurrentItems] = useState([]);



    useEffect(() => {
        const newTotalPages = Math.ceil(admData.length / itemsPerPage);
        setTotalPages(newTotalPages);
        const newStartIndex = (currentPage - 1) * itemsPerPage;
        setStartIndex(newStartIndex);
        const newEndIndex = newStartIndex + itemsPerPage;
        setEndIndex(newEndIndex);
        const newCurrentItems = admData.slice(newStartIndex, newEndIndex);
        setCurrentItems(newCurrentItems);
    }, [admData, itemsPerPage, currentPage]);

    async function fetchAdm(examId) {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            exam_id:examId
        };
        const response = await axios({
            url: `${API_BASE_URL}/get-admission`,
            method: "post",
            data:configData
        });
        let data1 = response['data'];
        setAdmData(data1.data);
        // console.log(data1.data)

        setIsLoading(false)
        if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }

    useEffect(() => {
        fetchAdm(location.state.examId);
    }, []);

    // console.log(admData)
    const filteredItems = currentItems.filter((payment) =>
        payment.user_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredItems1 = location.state.examData.filter((data) =>
        data.exam_name.toLowerCase().includes(searchExam.toLowerCase())
    );

    const navigate=useNavigate();



    return(
        <>

            <div className="bg-base-100 !p-4 m-0 flex flex-col  ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2 ">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm max-sm:mt-1 max-sm:text-[10px]">Adm. · {examName} · {admData.length} </button>
                            </div>
                            <div className="justify-self-end">
                                <div className="form-control">
                                    <div className="input-group max-sm:grid gap-2 max-sm:gap-1">
                                        <div className="form-control ">
                                            <div className=" border rounded overflow-hidden flex">
                                                <input
                                                    className="input input-bordered rounded-[2px]   max-sm:input-sm max-sm:w-28"
                                                    type="text"
                                                    placeholder="Search admisisonss"
                                                    value={searchQuery}
                                                    onChange={(event) => setSearchQuery(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="dropdown  dropdown-end">
                                            <input
                                                className="input input-bordered rounded-[2px]   max-sm:input-sm max-sm:w-28"
                                                type="text"
                                                tabIndex={0}
                                                placeholder="Search Exam"
                                                value={searchExam}
                                                onChange={(event) => setSearchExam(event.target.value)}

                                            />
                                            <ul tabIndex={0} className="dropdown-content bg-base-100 !z-20 h-96 w-96 max-sm:w-72 menu mt-2 shadow-2xl !flex-nowrap !overflow-y-auto">
                                                {
                                                    filteredItems1.map((item,key)=>(
                                                        <div className="bg-base-100">
                                                            <li className="flex w-96 max-sm:w-72 border !focus:bg-none !hover:bg-none !rounded-[2px] " onClick={()=>{
                                                                location.state.examId=item.exam_id
                                                                // console.log( location.state.examId)
                                                                fetchAdm(item.exam_id)
                                                                setExamId(item.exam_id)
                                                                setExamName(item.exam_name)
                                                            }}>
                                                                <div>
                                                                    <img src={item.logo} className="w-16 h-10"/>
                                                                    <span className="bg-transparent max-sm:text-sm">{item.exam_name}</span>
                                                                </div>
                                                            </li>
                                                            <div className="divider p-0 m-0"/>
                                                        </div>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </header>


                <AdmGraphForExam examId={examId} totalAdm={admData.length}/>

                {/* Middle container */}
                <main className="flex-1 overflow-y-auto ">
                    <div className="grid  grid-cols-3 mb-2">
                        <div className="col-span-2">
                            <div className="tabs ">
                                <a className={`tab tab-lifted `} onClick={()=>{navigate("/exam-management")}}>Exams</a>
                                <a className={`tab tab-lifted `}  onClick={() => {
                                    // navigate("/exam-management", {
                                    //     state: {
                                    //         clickStatus:"true",
                                    //         id:location.state.id,
                                    //         examName:examName,
                                    //     },
                                    // });
                                }} style={{pointerEvents: 'none'}}>Sets</a>
                                <a className={`tab tab-lifted tab-active`}>Adm.</a>
                            </div>
                        </div>
                    </div>
                    <div className="h-[90%] overflow-x-scroll overflow-y-scroll">
                        {
                            !isLoading?  <table className="border-separate w-full table-zebra table  table-auto table-compact ">
                                <thead className="border-b sticky top-0">
                                <tr className="text-center">
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Username</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Phone</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Real Amount</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Settlement Amount</th>
                                    {/*<th className="bg-[#50DBB4] pl-2 pr-2">Total Amount</th>*/}
                                    {/*<th className="bg-[#50DBB4] pl-2 pr-2">Wallet Used</th>*/}
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Discount</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Order Id</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Payment Id</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Payment Date</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Expiry Date</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Remarks</th>
                                    {/*<th className="bg-[#50DBB4] pl-10 pr-10">Action</th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                { filteredItems.map((e, id) => (
                                    <tr key={id} className="hover">
                                        <td className="!h-14">
                                            {
                                                e.user_name
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.phone
                                            }
                                        </td>
                                        <td>
                                            ₹ {
                                            e.real_amount
                                        }
                                        </td>
                                        <td>
                                            ₹ {e.real_amount
                                        }
                                        </td>
                                        {/*<td>*/}
                                        {/*    {*/}
                                        {/*        e.total_amount*/}
                                        {/*    }*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*    {e.wallet_used}*/}
                                        {/*</td>*/}
                                        <td>
                                            { e.promo_code_price}
                                        </td>
                                        <td>
                                            { e.order_id}
                                        </td>
                                        <td>
                                            { e.payment_id}
                                        </td>
                                        <td>
                                            { e.date}
                                        </td>
                                        <td>
                                            { e.expiry_date}
                                        </td><td>
                                        { e.remarks}
                                    </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>:<LoadingTableComponent/>
                        }
                    </div>
                </main>

                {/* Footer */}
                <footer >
                    <div className="container mx-auto text-center">
                        <div>
                            <div className="flex justify-center">
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-l"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Previous
                                </button>
                                {totalPages > 7 ? (
                                    <>
                                        {currentPage > 4 && (
                                            <>
                                                <button
                                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4"
                                                    onClick={() => setCurrentPage(1)}
                                                >
                                                    1
                                                </button>
                                                <span className="mx-2">...</span>
                                            </>
                                        )}
                                        {Array.from({ length: totalPages }, (_, i) => i + 1)
                                            .filter(
                                                (page) =>
                                                    page === 1 ||
                                                    page === totalPages ||
                                                    (page >= currentPage - 2 && page <= currentPage + 2)
                                            )
                                            .map((page) => (
                                                <button
                                                    key={page}
                                                    className={`${
                                                        currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200 hover:bg-gray-300 text-gray-600"
                                                    } font-bold py-2 px-4`}
                                                    onClick={() => setCurrentPage(page)}
                                                >
                                                    {page}
                                                </button>
                                            ))}
                                        {currentPage < totalPages - 3 && (
                                            <>
                                                <span className="mx-2">...</span>
                                                <button
                                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4"
                                                    onClick={() => setCurrentPage(totalPages)}
                                                >
                                                    {totalPages}
                                                </button>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                        <button
                                            key={page}
                                            className={`${
                                                currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200 hover:bg-gray-300 text-gray-600"
                                            } font-bold py-2 px-4`}
                                            onClick={() => setCurrentPage(page)}
                                        >
                                            {page}
                                        </button>
                                    ))
                                )}
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-r"
                                    disabled={currentPage === totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        </>
    )
}
export default ViewAdmissions;
