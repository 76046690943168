import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {AiFillCheckCircle, AiFillDelete, AiFillEdit, AiFillEye, AiOutlinePlusCircle} from "react-icons/ai";
import {getUserData} from "../../utils/LocalHandler";
import Swal from "sweetalert2";
import {RxCross2} from "react-icons/rx";
import LoadingTableComponent from "../loadingTable.component";
import {API_BASE_URL} from "../../utils/config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link, useNavigate} from "react-router-dom";
import {App_Name} from "../../utils/name";
import {formatDate} from "../../utils/formatDate";
import {getRole} from "../../utils/getRole";
const LiveTest=()=>{

  const role=getRole();

  const [isLoading, setIsLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const [liveTestData, setLiveTestData] = useState([]);
  const [showOnAppWeb, setShowOnAppWeb] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const options = [10, 25, 50, 100];

  const totalPages = Math.ceil(liveTestData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = liveTestData.slice(startIndex, endIndex);
  const [currentItems1,setCurrentItems]=useState()

  const navigate=useNavigate();

  async function fetchLiveTests() {
    const response = await axios({
      url: `${API_BASE_URL}/get-live-test-list`,
      method: "get"
    });
    let data1= response['data'];
    setLiveTestData(data1.data);
    console.log(data1.data)
    setIsLoading(false)
    if (data1.status == 500 || data1.status == 401) return navigate("/login")
  }

  useEffect(() => {
    fetchLiveTests();
  }, []);

  // console.log(pendingPayments)
  const filteredItems = liveTestData.filter((payment) =>
      payment.exam_name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  // modal data

  const closeBtnRef=useRef();
  const editRef=useRef();

  const [testName, setTestName] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [entryTime, setEntryTime] = useState();
  const [resultTime, setResultTime] = useState();
  const [contentSourceCode, setContentSourceCode] = useState("");
  const [timeGivenForTest, setTimeGivenForTest] = useState("");
  const [positiveMarks, setPositiveMarks] = useState("");
  const [negativeMarks, setNegativeMarks] = useState("");
  const [totalQuestions, setTotalQuestions] = useState("");
  const [instructionText, setInstructionText] = useState("");


  const containerRef = useRef(null);


  const handleAdd= async (e)=>{
    e.preventDefault();

    closeBtnRef.current.click();

    const liveTestCreatingToast = toast.warning('Creating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });

    const userDetails = getUserData();
    const token = userDetails.token;
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        // content_source_code:contentSourceCode,
        // entry_time:entryTime,
        exam_name:testName,
        // time:timeGivenForTest,
        // instructions:instructionText,
        // logo:logoUrl,
        // negative_mark:negativeMarks,
        // positive_mark:positiveMarks,
        // result_time:resultTime,
        // total_question:totalQuestions

      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/create-live-test`,
        options
    );
    let resData = await response.json();
    console.log(resData);
    if (resData["status"] === 404) {
    }
    else if(resData["status"] === 200){
      toast.update(liveTestCreatingToast, { render: "Created successfully", autoClose: 3000,
        type: "success", isLoading: false });
      setTestName("");
      fetchLiveTests();
    }

  }

  const handleRemove= async (id)=> {


      Swal.fire({
        title: "<p>Write <b>delete</b> to delete</p>"+currentItems[id].exam_name,
        text:'This is permanent delete',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: false,
        confirmButtonText: 'delete',
        showLoaderOnConfirm: true,
        confirmButtonColor: 'red',
        customClass: {
          confirmButton: 'w-5/6'
        },
        inputValidator: async (value) => {

          if (!value) {
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
          } else if (value.toLowerCase() === 'delete') {
            Swal.close();
            const userDetails = getUserData();
            const token = userDetails.token;
            const liveTestCreatingToast = toast.warning('deleting...', {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              hideProgressBar: true,
              isLoading: true
            });
            let options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                exam_id: filteredItems[id].exam_id,
              }),
            };
            const response = await fetch(
                `${API_BASE_URL}/delete-live-test`,
                options
            );
            let resData = await response.json();
            console.log(resData);
            if (resData["status"] === 200) {
              toast.update(liveTestCreatingToast, { render: "deleted successfully", autoClose: 3000,
                type: "success", isLoading: false });
              fetchLiveTests()
            }

          }
          else{
            Swal.close();
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
            toast.error('Type delete to delete', {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: "",
              theme: "light",
            });
          }
        }
      })
  }


  const [editIdLive,setEditIdLive] =useState('')
  const [examId, setExamId] = useState();

  const [logo, setLogo] = useState();
  const [examName, setExamName] = useState();
  const [examType, setExamType] = useState();
  const [status, setStatus] = useState();
  const [entryTimeEdit, setEntryTimeEdit] = useState("");
  const [resultTimeEdit, setResultTimeEdit] = useState("");
  const [examStartStatus, setExamStartStatus] = useState();
  const [time, setTime] = useState();
  const [language, setLanguage] = useState();
  const [positiveMark, setPositiveMark] = useState();
  const [negativeMark, setNegativeMark] = useState();
  const [totalQuestion, setTotalQuestion] = useState();
  const [contentSourceCodeEdit, setContentSourceCodeEdit] = useState();
  const [instructions, setInstructions] = useState();
  const [tag, setTag] = useState();
  const [showPreviousYearTag, setShowPreviousYearTag] = useState();
  const [date, setDate] = useState();
  const [examCategory, setExamCategory] = useState();
  const [Visiblestatus,setVisiblestatus]=useState('')

  const clear = () => {
    setLogo("");
    setExamName("");
    setExamType("");
    setStatus("");
    setEntryTimeEdit("");
    setResultTimeEdit("");
    setExamStartStatus("");
    setTime("");
    setLanguage("");
    setPositiveMark("");
    setNegativeMark("");
    setTotalQuestion("");
    setContentSourceCodeEdit("");
    setInstructions("");
    setTag("");
    setShowPreviousYearTag("");
    setDate("");
    setExamCategory("");
    setVisiblestatus("");
  }

  const updateNew=async (e) => {
    e.preventDefault()
    editRef.current.click()
    const id = editIdLive
    const userDetails = getUserData();
    const token = userDetails.token;


    const liveTestCreatingToast = toast.warning('updating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        exam_id: liveTestData[id].exam_id,
        logo: logo,
        exam_name: examName,
        exam_type: examType,
        status: Visiblestatus,
        entry_time: entryTimeEdit,
        result_time: resultTimeEdit,
        exam_start_status: status,
        time: time,
        language: liveTestData[id].language,
        positive_mark: positiveMark,
        negative_mark: negativeMark,
        total_question: totalQuestion,
        content_source_code: contentSourceCodeEdit,
        instructions: instructions,
        tag: tag,
        show_previous_year_tag: showPreviousYearTag,
        // show_ratings_participants:currentItems[id].show_ratings_participants,
        date: liveTestData[id].date,
        exam_category: examCategory,
        show_on_app_web:showOnAppWeb
      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/update-live-test`,
        options
    );
    let resData = await response.json();

    // console.log(resData);
    if (resData["status"] === 200) {
      fetchLiveTests();
      toast.update(liveTestCreatingToast, { render: "updated successfully", autoClose: 3000,
        type: "success", isLoading: false });
    }
    clear()

  }
  const handleUpdateNew=(id)=>{
    setEditIdLive(id)

    setLogo(liveTestData[id].logo)
    setExamName(liveTestData[id].exam_name)
    setExamType(liveTestData[id].exam_type)
    setStatus(liveTestData[id].exam_start_status)
    setShowOnAppWeb(liveTestData[id].show_on_app_web)
    setEntryTimeEdit(liveTestData[id].entry_time)
    setResultTimeEdit(liveTestData[id].result_time)
    setTime(liveTestData[id].time)
    setPositiveMark(liveTestData[id].positive_mark)
    setNegativeMark(liveTestData[id].negative_mark)
    setTotalQuestion(liveTestData[id].total_question)
    setContentSourceCodeEdit(liveTestData[id].content_source_code)
    setInstructions(liveTestData[id].instructions)
    setTag(liveTestData[id].tag)
    setShowPreviousYearTag(liveTestData[id].show_previous_year_tag)
    setExamCategory(liveTestData[id].exam_category)
    setVisiblestatus(liveTestData[id].status)
    editRef.current.click();

  }


  return(
      <>

        <div className="bg-base-100 !p-4 m-0 flex flex-col h-fit ">
          {/* Header */}
          <header >
            <div className=" mx-auto">
              <div className="grid  grid-cols-3 mb-2">
                <div className="col-span-2">
                  <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                  <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Live Test</button>
                </div>
                <div className="justify-self-end ">
                  <div className="form-control ">
                    <div className="rounded flex">
                      <input
                          className="input input-bordered rounded-[2px]  mr-1 max-sm:input-sm max-sm:w-28"
                          type="text"
                          placeholder="Search Live Test"
                          value={searchQuery}
                          onChange={(event) => setSearchQuery(event.target.value)}
                      />
                      <label htmlFor="createLiveTest" className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* Middle container */}
          <main className="flex-1  ">
            {/*overflow-x-scroll overflow-y-scroll*/}
            <div className="h-fit overflow-auto  rounded-[10px]">
              {
                !isLoading?<table ref={containerRef} className="table  !rounded-[2px] border-separate w-full text-center table-zebra table-auto table-compact ">
                  <thead className="border-b sticky top-0">
                  <tr>
                    <th className="pl-5 pr-5  bg-[#50DBB4] whitespace-nowrap z-50">Logo</th>
                    <th className="pl-16 pr-16 w-full bg-[#50DBB4]">Exam Name</th>
                    <th className="pl-10 pr-10 w-full bg-[#50DBB4]">Status</th>
                    <th className="pl-2 pr-2 w-full bg-[#50DBB4]">Entry Time</th>
                    <th className="pl-4 pr-4 w-full bg-[#50DBB4]">Result Time</th>
                    <th className="pl-4 pr-4 w-full bg-[#50DBB4]">Teach Set ID</th>
                    <th className="pl-5 pr-5 w-full bg-[#50DBB4]">Tag</th>
                    <th className="pl-6 pr-6 w-full bg-[#50DBB4]">PY Tag</th>
                    <th className="  w-full bg-[#50DBB4]">Visible</th>
                    <th className="pl-12 pr-12 w-full bg-[#50DBB4]">Action</th>

                  </tr>
                  </thead>
                  <tbody className="text-left overflow-x-auto">
                  { filteredItems.map((e, id) => (
                      <tr key={id}  className={ "hover"}>
                        <th className={"text-center"}>
                          {
                              <img className="h-[28px] w-[50px] text-center rounded-[2px]" src={e.logo}   />
                          }
                        </th>
                        <td>
                          {
                              e.exam_name
                          }
                        </td>
                        <td>
                          {
                              e.exam_start_status==0?<p>Upcoming</p>:e.exam_start_status==1?<p>Running</p>:e.exam_start_status==2?<p>Entry Blocked</p>:e.exam_start_status==3?<p>Result Declared</p>:null
                          }
                        </td>
                        <td>
                          {
                              e.entry_time
                          }
                        </td>
                        <td>
                          {
                              e.result_time
                          }
                        </td>

                        <td>
                          {
                              e.content_source_code
                          }
                        </td>
                        <td>
                          {
                              e.tag
                          }
                        </td>
                        <td>
                          {
                              <label className="cursor-pointer label">
                                <input type="checkbox" className="toggle toggle-accent" checked={e.show_previous_year_tag == "1"}
                                />
                              </label>
                          }
                        </td>
                        <td>
                          {
                              <label className="cursor-pointer label">
                                <input type="checkbox" className="toggle toggle-accent" checked={e.status == "1"}
                                />
                              </label>
                          }
                        </td>
                        <td>
                          <div className=" gap-2">
                            {
                                <div>
                                  <button
                                      className="btn btn-sm   btn-outline !rounded-[2px]"
                                      onClick={() => handleUpdateNew(id)}
                                  >
                                    {/*<AiFillEdit/>*/}Edit
                                  </button>
                                  {
                                    role===1?null:role===2?<button  onClick={() => handleRemove(id)}  className="btn ml-1  btn-error btn-sm !rounded-[2px]">
                                      {/*<AiFillDelete/>*/} Delete
                                    </button>:role===3? <button  onClick={() => handleRemove(id)}  className="btn ml-1  btn-error btn-sm !rounded-[2px]">
                                      {/*<AiFillDelete/>*/} Delete
                                    </button>:null
                                  }

                                </div>
                            }
                          </div>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>:<LoadingTableComponent/>
              }
            </div>
          </main>

        </div>

      {/*  add modal */}
        <input type="checkbox" id="createLiveTest" className="modal-toggle" />
        <label htmlFor="createLiveTest" className="modal cursor-pointer">
          <label className="modal-box pt-0 pb-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold text-center c-font1">Create Live Test</p>
              <div className="card-actions  justify-end">
                <label ref={closeBtnRef} htmlFor="createLiveTest" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label >
              </div>
            </div>
            <form className="text-center" onSubmit={handleAdd}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Enter Exam Name</span>
                </label>
                <input
                    type="text"
                    placeholder="Enter Exam Name"
                    className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                    value={testName}
                    onChange={(e) => {
                      setTestName(e.target.value);
                    }}
                />
              </div>

              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Create</button>
              </div>
            </form>
          </label>
        </label>



        <input type="checkbox" id="editLiveTestModal" className="modal-toggle" />
        <label htmlFor="editLiveTestModal" className="modal cursor-pointer">

          <label className="modal-box pt-0 pb-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl !pt-[-50px] font-bold c-font1 text-center">{examName}</p>
              <div className="card-actions  justify-end">
                <label ref={editRef} htmlFor="editLiveTestModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>
            <form onSubmit={updateNew}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Background Image</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={logo}
                    onChange={(e) => {
                      setLogo(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Exam Name</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={examName}
                    onChange={(e) => {
                      setExamName(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Current Status</span>
                </label>
                <select className="select w-full select-bordered c-font1 !rounded-[2px] " value={status}
                        onChange={(event) => {
                          setStatus(event.target.value)
                        }}>
                  <option value="0">Upcoming</option>
                  <option value="1">Running</option>
                  <option value="2">Entry Blocked</option>
                  <option value="3">Result Declared</option>
                </select>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Entry Time</span>
                </label>
                <DatePicker
                    showTimeSelect
                    setDefaultSelected={true}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeFormat="HH:mm:ss"
                    placeholderText={entryTimeEdit}
                    selected={entryTimeEdit.length>0?new Date(entryTimeEdit.toString()):new Date()}
                    closeOnSelect={false}
                    shouldCloseOnSelect={false}
                    onChange={(date) => {
                      setEntryTimeEdit(formatDate(date))
                    }}
                    className="input input-bordered z-20 c-font1 w-full"
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Result Time </span>
                </label>
                <DatePicker
                    showTimeSelect
                    setDefaultSelected={true}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeFormat="HH:mm:ss"
                    placeholderText={resultTimeEdit}
                    selected={resultTimeEdit.length>0?new Date(resultTimeEdit.toString()):new Date()}
                    closeOnSelect={false}
                    shouldCloseOnSelect={false}
                    onChange={(date) => {
                      setResultTimeEdit(formatDate(date))
                    }}
                    className="input input-bordered z-20 c-font1 w-full"
                />

              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Total Time</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={time}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Total Question</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={totalQuestion}
                    onChange={(e) => {
                      setTotalQuestion(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Positive Mark</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={positiveMark}
                    onChange={(e) => {
                      setPositiveMark(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Negative Mark</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={negativeMark}
                    onChange={(e) => {
                      setNegativeMark(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Teach Set ID</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={contentSourceCodeEdit}
                    onChange={(e) => {
                      setContentSourceCodeEdit(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Exam Instructions</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={instructions}
                    onChange={(e) => {
                      setInstructions(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Tag</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={tag}
                    onChange={(e) => {
                      setTag(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Show Previous Year Tag</span>
                </label>
                <select className="select c-font1 w-full select-bordered  !rounded-[2px] " value={showPreviousYearTag}
                        onChange={(event) => {
                          setShowPreviousYearTag(event.target.value)

                        }}>
                  <option value="0">NO</option>
                  <option value="1">Yes</option>
                </select>
                {/*<input type="checkbox" className="toggle toggle-accent" checked={showPreviousYearTag == "1"} onChange={(event) => {*/}
                {/*  setShowPreviousYearTag(event.target.checked ? "1" : "0")*/}
                {/*}} />*/}
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Exam Type</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={examType}
                    onChange={(e) => {
                      setExamType(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Exam Category</span>
                </label>
                <input
                    type="text"
                    className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                    value={examCategory}
                    onChange={(e) => {
                      setExamCategory(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Visible</span>
                </label>
                <select className="select c-font1 w-full select-bordered  !rounded-[2px] " value={Visiblestatus}
                        onChange={(event) => {
                          setVisiblestatus(event.target.value)

                        }}>
                  <option value="0">NO</option>
                  <option value="1">Yes</option>
                </select>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Platform</span>
                </label>
                <select className="select select-bordered w-full " value={showOnAppWeb}  onChange={(e) => {
                  setShowOnAppWeb(e.target.value);
                }}>
                  <option value="1">App</option>
                  <option value="2">Web</option>
                  <option value="3">Both</option>
                </select>
              </div>


              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Update</button>
              </div>

            </form>
          </label>
        </label>

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />

      </>
  )
}
export default LiveTest;
