import React, { useRef, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const FileUploadComponent = () => {
    const divPaste = useRef(null);
    const [imageUrl, setImageUrl] = useState('');
    const uploadImageEndPoint = async (base64Image) => {
        const base64Response = await fetch(`${base64Image}`);
        let formData = new FormData();
        let filetemp = await base64Response.blob();
        let config = {
            headers: {
                'Authorization': 'Bearer ' + "6A586E3272357538782F413F4428472B4B6250655368566D5970337336763979"
            }
        }
        formData.set('file', filetemp);
        let endpoint = 'https://image.dishaonlinetest.in/upload-image';
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: "warning",
            title: "Uploading Image Please Wait...",
        });
        axios.post(endpoint, formData, config).then(async (res) => {
            if (res.status === 200) {
                if (res.data?.success === false) Toast.fire({ icon: "warning", title: res.data?.data  });
                else {
                    await navigator.clipboard.writeText(res.data.url)
                    Toast.fire({
                        icon: "success",
                        title: res.data?.data || 'Image Uploaded....',
                    });
                }
            }
            divPaste.current.innerHTML = "";
        });
    };

    const handleOnPaste = (e) => {
        let cbPayload = [...(e.clipboardData || e.originalEvent.clipboardData).items];
        cbPayload = cbPayload.filter((i) => /image/.test(i.type));
        console.log(cbPayload);
        if (!cbPayload.length || cbPayload.length === 0) {
            e.target.innerHTML = '';
            return false;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
            e.target.innerHTML = `<img src="${e.target.result}">`;
            uploadImageEndPoint(e.target.result);
        };
        reader.readAsDataURL(cbPayload[0].getAsFile());
    };

    const handleInputChange = (e) => {
        setImageUrl(e.target.value);
    };

    const handleUploadButtonClick = () => {
        uploadImageEndPoint(imageUrl);
    };

    return (
        <div>
            <span style={{ fontFamily: "serif", margin: "4px" }}>Paste image in the box below</span>
            <div
                className="imagePaste"
                contentEditable={true}
                style={{ width: '200px', height: '100px', border: '1px solid', margin: '5px 0 0 10px', borderRadius: "10px" }}
                onPaste={handleOnPaste}
                ref={divPaste}
            />
            {/*<div className="mt-2">*/}
            {/*    <input className="input input-sm" type="text" value={imageUrl} onChange={handleInputChange} />*/}
            {/*    <button className="btn btn-sm ml-1 rounded-[2px]" onClick={handleUploadButtonClick}>Upload</button>*/}
            {/*</div>*/}
        </div>
    );
};

export default FileUploadComponent;
