import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {getUserData} from "../../utils/LocalHandler";
import Swal from "sweetalert2";
import {AiFillCopy, AiFillDelete, AiFillEdit, AiFillFilePdf, AiFillSetting, AiOutlinePlusCircle} from "react-icons/ai";
import {RxCross2} from "react-icons/rx";
import LoadingTableComponent from "../loadingTable.component";
import {API_BASE_URL} from "../../utils/config";
import {toast} from "react-toastify";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {App_Name} from "../../utils/name";
import DatePicker from "react-datepicker";
import {formatDate} from "../../utils/formatDate";
import {getRole} from "../../utils/getRole";



const ExamManagement=()=>{

  const role=getRole();
  const [defExamName,setDefExamName]=useState()

  const navigate=useNavigate();
  // modeal vars

  const closeBtnRef=useRef();
  const closeBtnRef1=useRef();
  const closeBtnRef2=useRef();
  const setting=useRef();
  const editRef=useRef();
  const editRef1=useRef();
  const closeNotes=useRef();
  const closeVideo=useRef();
  const bulkEditRef=useRef();
  const userRef=useRef();
  const [exam_name,setExamName]=useState();
  const [price,setPrice]=useState();
  const [validity,setValidity]=useState();
  const [logo,setLogo]=useState();
  const [set_name,setSetName]=useState('');
  const [set_date,setSet_date]=useState('');
  const [clickSetId,setClickSetId]=useState(); // for set
  const [categoryData, setCategoryData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [examData, setExamData] = useState([]);
  const [setData, setSetData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [videoData, setVideoData] = useState([]);


  const [rating,setRating]=useState()
  const [ratingGiven,setRatingGiven]=useState()

  // category vars
  const [catName, setCatName] = useState("");
  const [catImage, setCatImage] = useState("");


  const [tabs,settabs]=useState(1);

  async function fetchExam() {
    const userDetails = getUserData();
    const token = userDetails.token;
    const configData = {
      token,
    };
    const response = await axios({
      url: `${API_BASE_URL}/read-exam-list`,
      method: "post",
      data: configData
    });
    let data= response['data'];
    // data["data"] = data
    // console.log( data["data"])
    setIsLoading(false)
    setExamData(data['home_screen_exams']);
    setCategoryData(data['home_screen_categories'])
    if (data.status == 500 || data.status == 401) return  navigate("/login")
  }

  const [defExamId,setDefExamId]=useState();
  // console.table(categoryData)

  async function fetchSets(id) {
    setIsLoading(true)
    setDefExamId(filteredItems[id].exam_id)
    const userDetails = getUserData();
    const token = userDetails.token;
    const configData = {
      token,
      exam_id: filteredItems[id].exam_id,
    };
    const response = await axios({
      url: `${API_BASE_URL}/get-set-list`,
      method: "post",
      data: configData
    });
    let data= response['data'];
    // data["data"] = data
    console.log( data["data"])
    setSetData(data.data);
    setIsLoading(false)
    if (data.status == 500 || data.status == 401) return navigate("/login")
  }

  async function fetchNotes(id) {
    setIsLoading(true)
    setDefExamId(filteredItems[id].exam_id)
    const userDetails = getUserData();
    const token = userDetails.token;
    const configData = {
      token,
      exam_id: filteredItems[id].exam_id,
    };
    const response = await axios({
      url: `${API_BASE_URL}/get-notes-list`,
      method: "post",
      data: configData
    });
    let data= response['data'];
    // data["data"] = data
    // console.log( data["notes"])
    setNotesData(data.notes);

    setIsLoading(false)
    if (data.status == 500 || data.status == 401) return navigate("/login")
  }

  async function fetchVideos(id) {
    setIsLoading(true)
    setDefExamId(filteredItems[id].exam_id)
    const userDetails = getUserData();
    const token = userDetails.token;
    const configData = {
      token,
      exam_id: filteredItems[id].exam_id,
    };
    const response = await axios({
      url: `${API_BASE_URL}/get-videos-list`,
      method: "post",
      data: configData
    });
    let data= response['data'];
    // data["data"] = data
    // console.log( data["videos"])
    setVideoData(data.videos);
    setIsLoading(false)
    if (data.status == 500 || data.status == 401) return navigate("/login")
  }

  const handleAdd= async (e,id)=>{
    e.preventDefault();
    const userDetails = getUserData();
    const token = userDetails.token;
    const liveTestCreatingToast = toast.warning('Creating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    // debugger
    if(tabs==1){
      closeBtnRef.current.click();
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          exam_name:exam_name,
          price:price,
          validity:validity
        }),
      };
      const response = await fetch(
          `${API_BASE_URL}/create-exam`,
          options
      );
      let resData = await response.json();
      // console.log(resData);
      if (resData["status"] === 404) {
        setExamName("")
        setPrice("")
        setValidity("")
        setLogo("")
      }
      else if(resData["status"] === 200){
        //creating
        // fetchExam();
        setExamData(resData["data"])
        toast.update(liveTestCreatingToast, { render: "Created successfully", autoClose: 3000,
          type: "success", isLoading: false });
        setExamName("")
        setPrice("")
        setValidity("")
      }
    }
    else if(tabs==2){
      closeBtnRef1.current.click();
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          exam_id:defExamId,
          set_name:set_name,
        }),
      };
      const response = await fetch(
          ` ${API_BASE_URL}/create-new-set`,
          options
      );
      let resData = await response.json();
      // console.log(resData);
      if (resData["status"] === 404) {
        setSetName("")
      }
      else if(resData["status"] === 200){
        toast.update(liveTestCreatingToast, { render: "Created successfully", autoClose: 3000,
          type: "success", isLoading: false });
        setSetName("")
      }
      // fetchSets(clickSetId);
      setSetData(resData['data'])
    }
    else if(tabs==4){
      closeBtnRef2.current.click();
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          title:catName,
          background_image:catImage
        }),
      };
      const response = await fetch(
          ` ${API_BASE_URL}/create-category`,
          options
      );
      let resData = await response.json();
      if (resData["status"] === 404) {
        setCatName("")
      }
      else if(resData["status"] === 200){
        toast.update(liveTestCreatingToast, { render: "Created successfully", autoClose: 3000,
          type: "success", isLoading: false });
        setCatName("")
      }
      setCategoryData(resData.data)
    }

  }

  const handleRemove= async (id)=> {

    if(tabs==1){
      Swal.fire({
        title: "<h1>Write <b>delete</b> to delete</h1>"+filteredItems[id].exam_name,
        text:'This is permanent delete',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: false,
        confirmButtonText: 'delete',
        showLoaderOnConfirm: true,
        confirmButtonColor: 'red',
        inputValidator: async (value) => {

          if (!value) {
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
          } else if (value.toLowerCase() === 'delete') {
            Swal.close();
            const userDetails = getUserData();
            const token = userDetails.token;
            const liveTestCreatingToast = toast.warning('deleting...', {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              hideProgressBar: true,
              isLoading: true
            });
            console.log("Exam id:"+filteredItems[id].exam_id ,"Exam Name: "+ filteredItems[id].exam_name)
            let options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                exam_id : filteredItems[id].exam_id,
              }),
            };
            const response = await fetch(
                ` ${API_BASE_URL}/delete-exam`,
                options
            );
            let resData = await response.json();
            console.log(resData);
            if (resData["status"] === 200) {
              setExamData(resData["data"])
              toast.update(liveTestCreatingToast, { render: "deleted successfully", autoClose: 3000,
                type: "success", isLoading: false });
            }

          }
          else{
            Swal.close();
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
            toast.error('Type delete to delete', {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      })
    }
    else if(tabs==2){
      Swal.fire({
        title: "<h1>Write <b>delete</b> to delete</h1>"+filteredItems1[id].exam_name,
        text:'This is permanent delete',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: false,
        confirmButtonText: 'delete',
        showLoaderOnConfirm: true,
        confirmButtonColor: 'red',
        inputValidator: async (value) => {

          if (!value) {
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
          } else if (value.toLowerCase() === 'delete') {
            Swal.close();
            const userDetails = getUserData();
            const token = userDetails.token;
            const liveTestCreatingToast = toast.warning('deleting...', {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              hideProgressBar: true,
              isLoading: true
            });
            let options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                set_id : filteredItems1[id].set_id,
                exam_id:defExamId,
              }),
            };
            const response = await fetch(
                `${API_BASE_URL}/delete-set`,
                options
            );
            let resData = await response.json();
            console.log(resData);
            if (resData["status"] === 200) {
              setSetData(resData['data'])
              toast.update(liveTestCreatingToast, { render: "deleted successfully", autoClose: 3000,
                type: "success", isLoading: false });
            }
          }
          else{
            Swal.close();
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
            toast.error('Type delete to delete', {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      })
    }

    else if(tabs==5){

      Swal.fire({
        title: "<h1>Write <b>delete</b> to delete</h1>"+filteredItems3[id].notes_title,
        text:'This is permanent delete',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: false,
        confirmButtonText: 'delete',
        showLoaderOnConfirm: true,
        confirmButtonColor: 'red',
        inputValidator: async (value) => {

          if (!value) {
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
          } else if (value.toLowerCase() === 'delete') {
            Swal.close();
            const userDetails = getUserData();
            const token = userDetails.token;
            const liveTestCreatingToast = toast.warning('deleting...', {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              hideProgressBar: true,
              isLoading: true
            });
            let options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                id : filteredItems3[id].id,
                exam_id:defExamId,
              }),
            };
            const response = await fetch(
                `${API_BASE_URL}/delete-notes`,
                options
            );
            let resData = await response.json();
            if (resData["status"] === 200) {
              setNotesData(resData['data'])
              toast.update(liveTestCreatingToast, { render: "deleted successfully", autoClose: 3000,
                type: "success", isLoading: false });
            }
          }
          else{
            Swal.close();
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
            toast.error('Type delete to delete', {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      })
    }
    else if(tabs==6){
      Swal.fire({
        title: "<h1>Write <b>delete</b> to delete</h1>"+filteredItems4[id].video_title,
        text:'This is permanent delete',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: false,
        confirmButtonText: 'delete',
        showLoaderOnConfirm: true,
        confirmButtonColor: 'red',
        inputValidator: async (value) => {

          if (!value) {
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
          } else if (value.toLowerCase() === 'delete') {
            Swal.close();
            const userDetails = getUserData();
            const token = userDetails.token;
            const liveTestCreatingToast = toast.warning('deleting...', {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              hideProgressBar: true,
              isLoading: true
            });
            let options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                id : filteredItems4[id].id,
                exam_id:defExamId,
              }),
            };
            const response = await fetch(
                `${API_BASE_URL}/delete-video`,
                options
            );
            let resData = await response.json();
            if (resData["status"] === 200) {
              setVideoData(resData['data'])
              toast.update(liveTestCreatingToast, { render: "deleted successfully", autoClose: 3000,
                type: "success", isLoading: false });
            }
          }
          else{
            Swal.close();
            toast.error( { render: "Type delete to delete", autoClose: 3000,
              type: "error" });
            toast.error('Type delete to delete', {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      })
    }
  }

  function viewSets(id,name) {
    settabs(2)
    setDefExamName(name)
    setClickSetId(id)
    // console.table(id)
    fetchSets(id)
  }

  const location=useLocation();



  const filteredItems = examData.filter((payment) =>{
        return payment.exam_name.toLowerCase().includes(searchQuery.toLowerCase())
      }
  );

  const filteredItems1 = setData.filter((payment) => {
    return Object.values(payment).some((value) =>
        value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const filteredItems2 = categoryData.filter((payment) =>{
        return payment.title.toLowerCase().includes(searchQuery.toLowerCase())
      }
  );

  const filteredItems3 = notesData.filter((payment) =>{
        return payment.notes_title.toLowerCase().includes(searchQuery.toLowerCase())
      }
  );

  const filteredItems4 = videoData.filter((payment) =>{
        return payment.video_title.toLowerCase().includes(searchQuery.toLowerCase())
      }
  );

  useEffect(() => {
    // alert(tabs)
    if(tabs==1 ){
      fetchExam();
    }
    else if(tabs==2){
      fetchSets(clickSetId);
    }
    // else if(tabs==2){
    //   fetchSets(clickSetId);
    // }
  }, []);


  const [editExaId,setEditExamId]=useState();
  const [editSetId,setEditSetId]=useState();

  const [logoEdit, setLogoEdit] = useState(""); // Exam logo URL
  const [examName, setExamNameEdit] = useState(''); // Exam name
  const [examCategory, setExamCategory] = useState(""); // Exam category
  const [examType, setExamType] = useState(""); // Exam type
  const [showRatings, setShowRatings] = useState(); // Show ratings flag
  const [tag, setTag] = useState(""); // Exam tag
  const [entryTime, setEntryTime] = useState(""); // Entry time
  const [showNotice, setShowNotice] = useState(); // Show notice flag
  const [notice, setNotice] = useState(""); // Exam notice
  const [priceEdit, setPriceEdit] = useState(); // Exam price
  const [validityEdit, setValidityEdit] = useState(""); // Exam validity
  const [useWalletPercent, setUseWalletPercent] = useState(); // Use wallet percent
  const [showOnAppWeb, setShowOnAppWeb] = useState(); // Use wallet percent
  const [status, setStatus] = useState(""); // Exam status
  const [date, setDate] = useState(""); // Exam date
  const [categoryImage, setCategoryImage] = useState("");
  const [purchaseText, setPurchaseText] = useState("");

  const clearExamFeild = () => {
    setLogoEdit("");
    setExamNameEdit("");
    setExamCategory("");
    setExamType("");
    setShowRatings("");
    setRating()
    setRatingGiven()
    setTag("");
    setEntryTime("");
    setShowNotice("");
    setNotice("");
    setPriceEdit("");
    setValidityEdit("");
    setUseWalletPercent("");
    setShowOnAppWeb("");
    setStatus("");
    setDate("");
    setCategoryImage("");
  }


  const [setId, setSetId] = useState(""); // Set ID

  const [setName, setSetNameEdit] = useState(""); // Set name
  const [contentSourceCode, setContentSourceCode] = useState(""); // Content source code
  const [primaryFilter, setPrimaryFilter] = useState(""); // Primary filter
  const [subFilter, setSubFilter] = useState(""); // Sub filter
  const [time, setTime] = useState(""); // Time
  const [tagSet, setTagSet] = useState(""); // Tag
  const [countdown, setCountdown] = useState(""); // Countdown
  const [pdf, setPdf] = useState(""); // PDF
  const [tq, setTq] = useState(""); // TQ
  const [pm, setPm] = useState(""); // PM
  const [nm, setNm] = useState(""); // NM
  const [isLive, setIsLive] = useState(false); // Is Live flag
  const [language, setLanguage] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [openingTime, setOpeningTime] = useState('');
  const [groupBySubject, setGroupBySubject] = useState();
  const [showPreviousYearTag, setShowPreviousYearTag] = useState();
  const [takePay,setTakePay]=useState('');
  const [instruction,setInstruction]=useState()
  const [sortingParams, setSortingParams] = useState(0);
  const [newCategoryName, setNewCategoryName] = useState();
  const [preferenceSubject, setPreferenceSubject] = useState();


  const [backgroundImage, setBackgroundImage] = useState("");
  const [idCate, setIdCate] = useState(0);
  const [statusCate, setStatusCate] = useState(0);
  const [title, setTitle] = useState("");

  const clearSetFields = () => {
    setSetNameEdit("");
    setSet_date("")
    setContentSourceCode("");
    setPrimaryFilter("");
    setPreferenceSubject("");
    setSubFilter("");
    setTime("");
    setTagSet("");
    setCountdown("");
    setPdf("");
    setTq("");
    setPm("");
    setNm("");
    setIsLive(false);
    setLanguage('');
    setVideoUrl('');
    setOpeningTime('');
    setGroupBySubject('');
    setShowPreviousYearTag('');
    setTakePay('');
    setInstruction('');
    setSortingParams(0);
    setNewCategoryName()
  }


  const [note_examId, note_setExamId] = useState();
  const [notesId, setNotesId] = useState();
  const [notesTitle, setNotesTitle] = useState();
  const [notesSubtitle, setNotesSubtitle] = useState();
  const [downloadUrl, setDownloadUrl] = useState();
  const [visibility, setVisibility] = useState();
  const [notesDate, setNotesDate] = useState();

  const clearNotesData = () => {
    note_setExamId("");
    setNotesId("")
    setNotesTitle("");
    setNotesSubtitle("");
    setDownloadUrl("");
    setVisibility("");
    setNotesDate("")
  };

  const [VideoId, setVideoId] = useState("");
  const [VideoExamId, setVideoExamId] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDesc, setVideoDesc] = useState("");
  const [videoSortingParam, setVideoSortingParam] = useState("");
  const [videoSubtitle, setVideoSubtitle] = useState("");
  const [videoListUrl, setVideoListUrl] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [server, setServer] = useState("");
  const [takePayment, setTakePayment] = useState("");
  const [videoisLive, setVideoIsLive] = useState("");
  const [scheduleStatus, setScheduleStatus] = useState("");
  const [videoVisibility, setVideoVisibility] = useState("");
  const [videoDate, setVideoDate] = useState('');


  const clearVideoFields = () => {
    setVideoId("");
    setVideoExamId("");
    setVideoTitle("");
    setVideoDesc("")
    setVideoSortingParam("");
    setVideoSubtitle("");
    setVideoListUrl("");
    setThumbnail("");
    setServer("");
    setTakePayment("");
    setVideoIsLive("");
    setScheduleStatus("");
    setVideoVisibility("");
    setVideoDate("");
  };


  //  default create

  const [videoListTitle,setVideoTitleList]=useState()
  const [def_notesTitle,setDef_NotesTitle]=useState()


  // create

  const createNotes= async (e)=>{
    e.preventDefault();
    const userDetails = getUserData();
    const token = userDetails.token;
    const liveTestCreatingToast = toast.warning('Creating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });

    closeNotes.current.click();
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        exam_id:defExamId,
        notes_title:def_notesTitle,
      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/create-notes`,
        options
    );
    let resData = await response.json();
    // console.log(resData);
    if (resData["status"] === 404) {
      setDef_NotesTitle("")
    }
    else if(resData["status"] === 200){
      //creating
      // fetchExam();
      setNotesData(resData["data"])
      toast.update(liveTestCreatingToast, { render: "Created successfully", autoClose: 3000,
        type: "success", isLoading: false });
      setDef_NotesTitle("")
    }
  }

  const createViedo= async (e)=>{
    e.preventDefault();
    const userDetails = getUserData();
    const token = userDetails.token;
    const liveTestCreatingToast = toast.warning('Creating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });

    closeVideo.current.click()
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        exam_id:defExamId,
        video_title:videoListTitle,
      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/create-video`,
        options
    );
    let resData = await response.json();
    // console.log(resData);
    if (resData["status"] === 404) {
      setDef_NotesTitle("")
    }
    else if(resData["status"] === 200){
      setVideoData(resData["data"])
      setDef_NotesTitle("")
      toast.update(liveTestCreatingToast, { render: "Created successfully", autoClose: 3000,
        type: "success", isLoading: false });
      setDef_NotesTitle("")
    }
  }

  const updateNew=async (e) => {
    e.preventDefault();
    editRef.current.click()
    const userDetails = getUserData();
    const token = userDetails.token;
    const liveTestCreatingToast = toast.warning('updating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    const id=editExaId


    if(tabs==1){
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          id: filteredItems[id].id,
          exam_id: filteredItems[id].exam_id,
          exam_category: examCategory,
          category_id:newCategoryName,
          exam_name: examName,
          exam_type: examType,
          isLiveMega: filteredItems[id].isLiveMega,
          show_ratings_participants: showRatings,
          rating_given: ratingGiven,
          rating: parseInt(rating),
          logo: logoEdit,
          tag: tag,
          entry_time: entryTime,
          show_notice: showNotice,
          notice: notice,
          price: priceEdit,
          purchase_text:purchaseText,
          validity: validityEdit,
          use_wallet_percent: useWalletPercent,
          status: status,
          show_on_app_web:showOnAppWeb,
          date:filteredItems[id].date,
          category_image:categoryImage,
          sorting_params:sortingParams
        }),
      };
      const response = await fetch(
          `${API_BASE_URL}/update-exam`,
          options
      );
      debugger
      let resData = await response.json();
      // console.log(resData);
      if (resData.status === 200) {
        // fetchExam();
        setExamData(resData['data'])
        toast.update(liveTestCreatingToast, { render: "updated successfully", autoClose: 3000,
          type: "success", isLoading: false });
      }
      else if(resData.status === 201){
        toast.update(liveTestCreatingToast, { render: "Nothing update", autoClose: 3000,
          type: "success", isLoading: false });
      }
      clearExamFeild()
    }
    else if(tabs==2){
      if(filteredItems1[id].sorting_params==null){
        filteredItems1[id].sorting_params=0
      }
      if(filteredItems1[id].video_url==null){
        filteredItems1[id].video_url=0
      }

      let parsedData=JSON.parse(groupBySubject)
      let newData;
      if(typeof parsedData === 'object'){
        newData=JSON.stringify(parsedData)
      }else{
        newData=groupBySubject
      }

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token:token,
          id:filteredItems1[id].id,
          exam_id : defExamId,
          set_id :filteredItems1[id].set_id,
          set_name :setName,
          is_live : isLive,
          primary_filter :primaryFilter,
          preference_subject:preferenceSubject,
          secondary_filter :subFilter,
          groupby_subject : newData,
          sorting_params :sortingParams,
          tag :tagSet,
          opening_time :countdown,
          content_source_code :contentSourceCode,
          language :language,
          external_link :pdf,
          show_previous_year_tag :showPreviousYearTag,
          video_url :videoUrl,
          instructions :instruction,
          total_questions :tq,
          time :time,
          positive_mark :pm,
          negative_mark :nm,
          take_payment :takePay,
          date:set_date
        }),
      };
      const response = await fetch(
          `${API_BASE_URL}/update-set`,
          options
      );
      let resData = await response.json();
      // console.log(resData);
      if (resData.status === 200) {
        // fetchSets(clickSetId);
        setSetData(resData['data'])
        toast.update(liveTestCreatingToast, { render: "updated successfully", autoClose: 3000,
          type: "success", isLoading: false });
      }
      else if(resData.status === 201){
        toast.update(liveTestCreatingToast, { render: "Nothing update", autoClose: 3000,
          type: "success", isLoading: false });
      }
      clearSetFields()
    }
    else if(tabs==5){

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token:token,
          id:notesId,
          exam_id:note_examId,
          notes_title: notesTitle,
          notes_subtitle: notesSubtitle,
          download_url: downloadUrl,
          visibility: visibility,
          date:notesDate
        }),
      };
      const response = await fetch(
          `${API_BASE_URL}/update-notes`,
          options
      );
      let resData = await response.json();
      // console.log(resData);
      if (resData.status === 200) {
        // fetchSets(clickSetId);
        setNotesData(resData['data'])
        toast.update(liveTestCreatingToast, { render: "updated successfully", autoClose: 3000,
          type: "success", isLoading: false });
      }
      else if(resData.status === 201){
        toast.update(liveTestCreatingToast, { render: "Nothing update", autoClose: 3000,
          type: "success", isLoading: false });
      }
      clearNotesData()
    }
    else if(tabs==6){
      try {
        let options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
            id: VideoId,
            exam_id: VideoExamId,
            video_title: videoTitle,
            video_description:videoDesc,
            sorting_params: videoSortingParam,
            video_subtitle: videoSubtitle,
            video_url: videoListUrl,
            thumbnail: thumbnail,
            server: server,
            take_payment: takePayment,
            is_live: videoisLive,
            schedule_status: scheduleStatus,
            visibility: videoVisibility,
            date:videoDate
          }),
        };

        const response = await fetch(`${API_BASE_URL}/update-video`, options);
        const resData = await response.json();

        if (resData.status === 200) {
          setVideoData(resData['data'])
          toast.update(liveTestCreatingToast, {
            render: "updated successfully",
            autoClose: 3000,
            type: "success",
            isLoading: false,
          });
        }
        else if(resData.status === 201){
          toast.update(liveTestCreatingToast, { render: "Nothing update", autoClose: 3000,
            type: "success", isLoading: false });
        }
      } catch (error) {
        console.error("Error:", error);
      }
      clearVideoFields();
    }
  }


  const updateNew1=async (e) => {
    e.preventDefault();
    editRef1.current.click()
    const userDetails = getUserData();
    const token = userDetails.token;
    const liveTestCreatingToast = toast.warning('updating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token:token,
        id:idCate,
        title:title,
        background_image:backgroundImage,
        sorting_params:sortingParams,
        status:statusCate,

      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/update-category-details`,
        options
    );
    let resData = await response.json();
    // console.log(resData);
    if (resData["status"] === 200) {
      fetchExam();
      toast.update(liveTestCreatingToast, { render: "updated successfully", autoClose: 3000,
        type: "success", isLoading: false });
    }

  }
  const handleUpdateNew = (id) => {
    setEditExamId(id)
    // console.log(id)
    if(tabs==1){
      setLogoEdit(filteredItems[id].logo);
      setExamNameEdit(filteredItems[id].exam_name);
      setExamCategory(filteredItems[id].exam_category);
      setNewCategoryName(filteredItems[id].category_id);
      setExamType(filteredItems[id].exam_type);
      setShowRatings(filteredItems[id].show_ratings_participants);
      setRating(filteredItems[id].rating);
      setRatingGiven(filteredItems[id].rating_given);
      setTag(filteredItems[id].tag);
      setEntryTime(filteredItems[id].entry_time);
      setShowNotice(filteredItems[id].show_notice);
      setNotice(filteredItems[id].notice);
      setPurchaseText(filteredItems[id].purchase_text);
      setPriceEdit(filteredItems[id].price);
      setValidityEdit(filteredItems[id].validity);
      setUseWalletPercent(filteredItems[id].use_wallet_percent);
      setStatus(filteredItems[id].status);
      setShowOnAppWeb(filteredItems[id].show_on_app_web);
      setDate(filteredItems[id].date);
      setCategoryImage(filteredItems[id].category_image)
      setSortingParams(filteredItems[id].sorting_params)
    }
    else if(tabs==2){
      setSetNameEdit(filteredItems1[id].set_name);
      setSet_date(filteredItems1[id].date)
      setPdf(filteredItems1[id].external_link);
      setTq(filteredItems1[id].total_questions);
      setPm(filteredItems1[id].positive_mark);
      setNm(filteredItems1[id].negative_mark);
      setIsLive(filteredItems1[id].is_live);
      setContentSourceCode(filteredItems1[id].content_source_code);
      setPrimaryFilter(filteredItems1[id].primary_filter);
      setPreferenceSubject(filteredItems1[id].preference_subject?filteredItems1[id].preference_subject:"");
      setSubFilter(filteredItems1[id].secondary_filter);
      setTime(filteredItems1[id].time);
      setTagSet(filteredItems1[id].tag);
      setCountdown(filteredItems1[id].opening_time);
      setLanguage(filteredItems1[id].language)
      setVideoUrl(filteredItems1[id].video_url)
      // setOpeningTime(filteredItems1[id].opening_time)
      setGroupBySubject(filteredItems1[id].groupby_subject)
      setShowPreviousYearTag(filteredItems1[id].show_previous_year_tag)
      setTakePay(filteredItems1[id].take_payment)
      setInstruction(filteredItems1[id].instructions)
      setSortingParams(filteredItems1[id].sorting_params)
    }
    else if(tabs==5){
      setNotesId(filteredItems3[id].id)
      note_setExamId(filteredItems3[id].exam_id)
      setNotesTitle(filteredItems3[id].notes_title)
      setNotesSubtitle(filteredItems3[id].notes_subtitle)
      setDownloadUrl(filteredItems3[id].download_url)
      setVisibility(filteredItems3[id].visibility)
      setNotesDate(filteredItems3[id].date)
    }
    else if(tabs==6){
      setVideoId(filteredItems4[id].id);
      setVideoExamId(filteredItems4[id].exam_id);
      setVideoTitle(filteredItems4[id].video_title);
      setVideoDesc(filteredItems4[id].video_description);
      setVideoSortingParam(filteredItems4[id].sorting_params);
      setVideoSubtitle(filteredItems4[id].video_subtitle);
      setVideoListUrl(filteredItems4[id].video_url);
      setThumbnail(filteredItems4[id].thumbnail);
      setServer(filteredItems4[id].server);
      setTakePayment(filteredItems4[id].take_payment);
      setVideoIsLive(filteredItems4[id].is_live);
      setScheduleStatus(filteredItems4[id].schedule_status);
      setVideoVisibility(filteredItems4[id].visibility);
      setVideoDate(filteredItems4[id].date);
    }

    editRef.current.click()
  }

  const handleUpdateNew1 = (id) => {
    setIdCate(filteredItems2[id].id)
    setSortingParams(filteredItems2[id].sorting_params)
    setStatusCate(filteredItems2[id].status)
    setTitle(filteredItems2[id].title)
    setBackgroundImage(filteredItems2[id].background_image)

    editRef1.current.click()
  }

  async function handleCopy(id) {
    const userDetails = getUserData();
    const token = userDetails.token;
    const liveTestCreatingToast = toast.warning('Copying...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        // id: filteredItems1[id].id,
        exam_id: defExamId,
        set_id: filteredItems1[id].set_id,
        set_name: filteredItems1[id].set_name,
        primary_filter: filteredItems1[id].primary_filter,
        preference_subject: filteredItems1[id].preference_subject,
        language: filteredItems1[id].language,
        secondary_filter: filteredItems1[id].secondary_filter,
        groupby_subject: filteredItems1[id].groupby_subject,
        sorting_params: filteredItems1[id].sorting_params==null?"1":filteredItems1[id].sorting_params,
        tag: filteredItems1[id].tag,
        opening_time: filteredItems1[id].opening_time,
        time: filteredItems1[id].time,
        positive_mark: filteredItems1[id].positive_mark,
        negative_mark: filteredItems1[id].negative_mark,
        total_questions:filteredItems1[id].total_questions,
        instructions:filteredItems1[id].instructions,
        take_payment:filteredItems1[id].take_payment
      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/copy-set`,
        options
    );
    let resData = await response.json();

    if (resData["status"] === 200) {
      // fetchSets(clickSetId);
      console.log(resData['data'])
      setSetData(resData['data'])
      toast.update(liveTestCreatingToast, { render: "Copied successfully", autoClose: 3000,
        type: "success", isLoading: false });
    }
    else {
      toast.update(liveTestCreatingToast, { render: "Copy Failed", autoClose: 3000,
        type: "error", isLoading: false });
    }
  }


  // activate user
  const [selectedExam, setSelectedExam] = useState("");

  // Function to handle dropdown change
  const [searchActive,setSearchActive]=useState(false)
  const handleSelectChange = (event) => {
    setSelectedExam(event.target.value);
  };

  // Get unique exam names
  // const examNames = Array.from(
  //     new Set(examData.map((exam) => exam.exam_name))
  // );
  const examNames = Array.from(new Set(examData.map((exam) => exam.exam_name)));
  console.log(examNames)
  examNames.sort();

  // Get exam ID based on selected exam name
  const selectedExamId = examData.find((exam) => exam.exam_name === selectedExam)?.exam_id;
  const [activate_phone,setActivate_Phone]=useState()
  const [activate_validity,setActivate_Validity]=useState()
  const [activate_paying_amount,setActivate_paying_amount]=useState()

  const handleActivateExam=async (event) => {
    event.preventDefault()
    const userDetails = getUserData();
    const token = userDetails.token;
    const liveTestCreatingToast = toast.warning('Activating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        exam_id:selectedExamId,
        phone_no:activate_phone,
        validity:activate_validity,
        paid_amount:activate_paying_amount,
      }),
    };

    const response = await fetch(
        `${API_BASE_URL}/activate-exam-by-phone`,
        options
    );
    let resData = await response.json();
    if (resData["status"] === 200) {
      toast.update(liveTestCreatingToast, {
        render: "Exam Activated successfully", autoClose: 3000,
        type: "success", isLoading: false
      });
    }
    else if( resData["status"] === 400){
      toast.update(liveTestCreatingToast, {
        render: resData["message"], autoClose: 3000,
        type: "warning", isLoading: false
      });
    }else if( resData["status"] === 404){
      toast.update(liveTestCreatingToast, {
        render: resData["message"], autoClose: 3000,
        type: "error", isLoading: false
      });
    }
    else {
      toast.update(liveTestCreatingToast, {
        render: "Failed to Activate", autoClose: 3000,
        type: "error", isLoading: false
      });
    }
    setActivate_Phone("")
  }

  // select bulk details
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = () => {
    if (selectedRows.length === filteredItems1.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredItems1.map(item => item.set_id));
    }
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
    // console.log(`Selected row ${id} with defExamId ${defExamId}`);
  };

  // console.log("selectedRows",selectedRows)
  const handleDeleteSelected = () => {
    setSetData(filteredItems1.filter(item => !selectedRows.includes(item.id)));
    setSelectedRows([]);
  };

  const bulkOptions=
      [
        { name: 'Select an option', value: 'def',type:"text" },
        { name: 'Main Folder', value: 'primary_filter',type:"text" },
        { name: 'Sub Folder', value: 'secondary_filter',type:"text" },
        { name: 'Group By Subject', value: 'groupby_subject',type:"boolean" },
        // { name: 'SortingParams', value: null },
        { name: 'Tag', value: 'tag', type:"text" },
        { name: 'Preference Subject', value: 'preference_subject', type:"text" },
        { name: 'Opening Time', value: 'opening_time',type:"date" },
        // { name: 'TableName', value: 'table_name' },
        // { name: 'ContentSourceCode', value: "content_source_code" },
        { name: 'Language', value: "language" ,type:"lang"},
        { name: 'Total Questions', value: "total_questions" ,type:"number"},
        { name: 'External Link', value: 'external_link',type:"text" },
        { name: 'ShowPreviousYearTag', value: "show_previous_year_tag",type:"boolean" },
        { name: 'Video Url', value: 'video_url' ,type:"text"},
        { name: 'Instructions', value: 'instructions' ,type:"text"},
        { name: 'Time', value: "time",type:"number" },
        { name: 'Positive Mark', value: "positive_mark",type:"text" },
        { name: 'Negative Mark', value: "negative_mark" ,type:"text"},
        // { name: 'Normalization', value: "normalization" },
        // { name: 'TakePayment', value: 1 },
        { name: 'Live', value: "is_live" ,type:"boolean"},
        { name: 'Date', value: 'date',type:"date" }
      ]

  const [bulkValue,setBulkValue]=useState()
  const [bulkColValue,setBulkColValue]=useState()
  const [bulkType,setBulkType]=useState('text')
  const [resetSelect,setResetSelect]=useState(null)
  const optionRef = useRef(null);

  console.log("bulkValue",bulkValue)

  function handleChangeKey(value) {
    setBulkColValue(value);
    const obj = bulkOptions.find((item) => item.value === value);
    return obj ? setBulkType(obj.type) : null;
  }

  async function handleBulk(e) {
    e.preventDefault()


    bulkEditRef.current.click()

    const liveTestCreatingToast = toast.warning('Updating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    const userDetails = getUserData();
    const token = userDetails.token;
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        exam_id: defExamId,
        set_id:selectedRows,
        column_name:bulkColValue,
        column_value:bulkType=="date"?formatDate(bulkValue):bulkValue
      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/set-bulk-update`,
        options
    );
    // debugger
    let resData = await response.json();
    // console.log(resData.data);
    if (resData["status"] === 200) {
      setSetData(resData['data'])
      toast.update(liveTestCreatingToast, {
        render: "Updated successfully", autoClose: 3000,
        type: "success", isLoading: false
      });

    }else{
      toast.update(liveTestCreatingToast, {
        render: "Nothing updated", autoClose: 3000,
        type: "success", isLoading: false
      });
    }
    setSelectedRows([])
    setBulkType("text")
    setBulkValue("")
    setBulkColValue("")
    setResetSelect("")
  }


  // notes
  const [clickedNotesId,setClickedNotesId]=useState()
  const [clickedVdoId,setClickedVdoId]=useState()
  const [vdoExamName,setVdoExamName]=useState()
  function viewNotes(id,name) {
    settabs(5)
    setDefExamName(name)
    setClickedNotesId(id)
    // console.table(id)
    fetchNotes(id)
  }

  function viewVideos(id,name) {
    settabs(6)
    setVdoExamName(name)
    setClickedVdoId(id)
    // console.table(id)
    fetchVideos(id)
  }

  const [settingData,setSettingData]=useState([])
  const [settingId,setSettingId]=useState()
  function handleSetting(e,id) {
    setSettingData(e)
    setSettingId(id)
    setting.current.click()
  }

  const [notificationTitle,setNotificationTitle]=useState()
  const [notificationData,setNotificationData]=useState([])
  const [notification,setNotification]=useState()
  const [notificationImg,setNotificationImg]=useState()
  const notiRef=useRef();
  function handleNotification(e, id) {

    setNotificationData(e)
    notiRef.current.click()
  }

  const sendNotification=async (e) => {
    e.preventDefault();
    const userDetails = getUserData();
    const token = userDetails.token;
    notiRef.current.click();
    toast.success( {
      render: "Notification sending...", autoClose: 3000,
      type: "success", isLoading: false
    });
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        exam_id:notificationData.exam_id,
        title: notificationTitle,
        image: notificationImg,
        body: notification
      }),
    };
    const response = await fetch(
        `${API_BASE_URL}/send-notification`,
        options
    );

    let resData = await response.json();
    // console.log(resData);
    if (resData["status"] === 404) {
      toast.success( {
        render: "Notification failed to send", autoClose: 3000,
        type: "success", isLoading: false
      });

    }

    setNotification("")
    setNotificationTitle("")
    setNotificationImg("")
  }

  return (
      <>

        <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
          {/* Header */}
          <header>
            <div className=" mx-auto">
              <div className="grid  grid-cols-3 mb-2">
                <div className="col-span-2">
                  <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                  <button
                      className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Exam
                    Management
                  </button>

                </div>
                <div className="justify-self-end">
                  <div className="form-control ">
                    <div className="rounded overflow-hidden flex">
                      {

                        tabs==2 || tabs==4 ?null:<label htmlFor="userModal" className="btn btn-outline max-sm:hidden capitalize !rounded-[2px] mr-1" >Activate</label>
                      }
                      <input
                          className="input input-bordered rounded-[2px]   mr-1 max-sm:input-sm max-sm:w-28"
                          type="text"
                          placeholder={tabs === 1?"Search Exam Name":tabs == 2?"Search Set Name":tabs == 3?"Search Adm.":tabs==5?"Search Notes":tabs==6?"Search Video":"Search Category Name"}
                          value={searchQuery}
                          onChange={(event) => setSearchQuery(event.target.value)}
                      />
                      {
                        tabs == 1 ? <label htmlFor="createExamModal"
                                           className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>
                            :tabs==4?  <label htmlFor="createCat"  className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>
                                :
                                tabs==5?
                                    <label htmlFor="createNotesModal"
                                           className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>:
                                    tabs==6?
                                        <label htmlFor="createVideoModal"
                                               className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>:
                                        <label htmlFor="createSetModal"
                                               className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </header>

          {/* Middle container */}
          <main className="flex-1  overflow-auto">
            <div className="grid  grid-cols-3 mb-2">
              <div className="col-span-2">
                <div className="tabs ">
                  <a className={`tab tab-lifted ${tabs === 1 ? 'tab-active' : ''}`}
                     onClick={() => settabs(1)}>Exams</a>
                  {tabs==4?<a className={`tab tab-lifted ${tabs === 4 ? 'tab-active' : ''}`}
                              onClick={() => settabs(4)}>Category </a>:<a className={`tab tab-lifted ${tabs === 4 ? 'tab-active' : ''}`}
                                                                          onClick={() => settabs(4)}>Category</a>}
                  {tabs==2?<a className={`tab tab-lifted ${tabs === 2 ? 'tab-active  font-bold' : ''}`} style={{pointerEvents: 'none'}}
                              onClick={() => settabs(2)}>  {setData.length+ " • Sets • "+defExamName} </a>:<a className={`tab tab-lifted ${tabs === 2 ? 'tab-active' : ''}`} style={{pointerEvents: 'none'}}
                                                                                                              onClick={() => settabs(2)}>Sets</a>}
                  {tabs==3?<a className={`tab tab-lifted ${tabs === 3 ? 'tab-active' : ''}`} style={{pointerEvents: 'none'}}
                              onClick={() => settabs(3)}>Adm {" • "+defExamName} </a>:<a className={`tab tab-lifted ${tabs === 3 ? 'tab-active' : ''}`} style={{pointerEvents: 'none'}}
                                                                                         onClick={() => settabs(3)}>Adm.</a>}
                  {tabs==5?<a className={`tab tab-lifted ${tabs === 5 ? 'tab-active' : ''}`} style={{pointerEvents: 'none'}}
                              onClick={() => settabs(5)}>Notes {" • "+defExamName} </a>:<a className={`tab tab-lifted ${tabs === 5 ? 'tab-active' : ''}`} style={{pointerEvents: 'none'}}
                                                                                           onClick={() => settabs(5)}>Notes</a>}
                  {tabs==6?<a className={`tab tab-lifted ${tabs === 6 ? 'tab-active' : ''}`} style={{pointerEvents: 'none'}}
                              onClick={() => settabs(6)}>Videos {" • "+ vdoExamName} </a>:<a className={`tab tab-lifted ${tabs === 6 ? 'tab-active' : ''}`} style={{pointerEvents: 'none'}}
                                                                                             onClick={() => settabs(6)}>Videos</a>}
                </div>
              </div>
              <div className="justify-self-end">
                {tabs==1?<label htmlFor="userModal" className="btn btn-outline block sm:hidden btn-sm  !rounded-[2px] pt-1 w-38 capitalize" >Activate </label>:null}
                {tabs==2?<label htmlFor="bulkEdit" className="btn btn-outline btn-sm !rounded-[2px] capitalize" disabled={selectedRows.length===0}>Bulk Edit</label>:null}
              </div>
            </div>



            <div className="h-[90%] overflow-x-scroll overflow-y-scroll">

              {/*set and main table exam one*/}
              {
                tabs == 1 ? <div>
                  {
                    !isLoading ?
                        <table className="table   border-separate w-full table-zebra  table-auto table-compact ">
                          <thead className="border-b sticky top-0">
                          <tr>
                            <th className="bg-[#50DBB4] !relative	 pl-5 pr-5 ">Logo</th>
                            <th className="bg-[#50DBB4] w-full  text-left">Exam Name</th>
                            {/*<th className="bg-[#50DBB4] pl-10 pr-10">Category logo</th>*/}
                            {/*<th className="bg-[#50DBB4] w-full text-center">Price</th>*/}
                            {/*<th className="bg-[#50DBB4] w-full pl-10 pr-10">T ADM.</th>*/}
                            <th className="bg-[#50DBB4] w-full text-center">Live</th>
                            <th className="bg-[#50DBB4] w-full !z-20 pl-30 pr-30 text-center">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {filteredItems.map((e, id) => (
                              <tr key={id} >
                                <td className="z-0">
                                  {
                                    <img className="h-[28px] w-[50px] !z-0 mx-auto rounded-[2px]" src={e.logo}/>
                                  }
                                </td>
                                <td className="!text-left">
                                  <b>{e.exam_id}</b>  •
                                  {
                                    e.exam_name
                                  }
                                </td>

                                {/*<td>*/}
                                {/*  <img className="h-[28px] w-[50px] mx-auto rounded-[2px]" src={e.category_image}/>*/}
                                {/*</td>*/}
                                {/*<td >*/}
                                {/*  {*/}
                                {/*      e.price*/}
                                {/*  }*/}
                                {/*</td>*/}
                                {/*<td >*/}
                                {/*  {e.total_admission}*/}
                                {/*</td>*/}
                                <td >
                                  {
                                    <input type="checkbox" className="toggle toggle-accent" checked={e.status == "1"? true : false} />
                                  }
                                </td>
                                <td >
                                  {/*{*/}
                                  {/*    <div className="gap-2 ">*/}
                                  {/*    */}

                                  {/*      /!*<button*!/*/}
                                  {/*      /!*    className="btn btn-sm ml-1  btn-ghost !rounded-[2px]"*!/*/}
                                  {/*      /!*    onClick={() => handleNotification(e,id)}*!/*/}
                                  {/*      /!*>*!/*/}
                                  {/*      /!*  <img src="https://image.bigbooster.in/f359298f-3077-46dd-9c3b-7d123f0e87f6.png" className="w-7 h-7"/>*!/*/}

                                  {/*      /!*</button>*!/*/}


                                  {/*    </div>*/}
                                  {/*}*/}

                                  {/*setting function*/}

                                  {/*<button*/}
                                  {/*    className="btn btn-sm ml-1  btn-ghost !rounded-[2px]"*/}
                                  {/*    onClick={() => handleSetting(e,id)}*/}
                                  {/*>*/}
                                  {/*  <img src="https://image.bigbooster.in/22b024d1-d5f6-49b4-a502-ca4f29b0a387.png" className="w-7 h-7"/>*/}

                                  {/*</button>*/}


                                  <div className="btn-group gap-2">
                                    <button
                                        className="btn btn-sm w-14	 btn-ghost  !rounded-[2px]"
                                        onClick={() => handleUpdateNew(id)}
                                    >
                                      <img src="https://image.bigbooster.in/0db6c07d-b74f-4b9c-ba78-fa85c8843a65.png" className="w-7 h-7"/>
                                    </button>
                                    <button
                                        className="btn btn-sm  w-14	 btn-ghost !rounded-[2px]"
                                        onClick={() => handleNotification(e,id)}
                                    >
                                      <img src="https://image.bigbooster.in/f359298f-3077-46dd-9c3b-7d123f0e87f6.png" className="w-7 h-7"/>

                                    </button>
                                    <button onClick={() => {
                                      viewSets(id,e.exam_name); setSearchQuery('')
                                    }} className="btn   btn-sm w-14	 btn-ghost !rounded-[5px]">
                                      <img src="https://image.bigbooster.in/f9f2c766-c780-4260-bbdd-95521a905d40.png" className="w-7 h-7"/>
                                    </button>

                                    {
                                      role===1?null:role===2?
                                          <button onClick={()=>{
                                            navigate("/viewAdmissions", {
                                              state: {
                                                examName:e.exam_name,
                                                examId:e.exam_id,
                                                id:id,
                                                examData:examData,
                                              },
                                            });
                                          }} className="btn w-14	  btn-sm btn-ghost !rounded-[5px]">
                                            <img src="https://image.bigbooster.in/e9c23ea4-aa1c-4619-93b2-94b5fb2da494.png" className="w-7 h-7"/>
                                          </button>:role===3?
                                              <button onClick={()=>{
                                                navigate("/viewAdmissions", {
                                                  state: {
                                                    examName:e.exam_name,
                                                    examId:e.exam_id,
                                                    id:id,
                                                    examData:examData,
                                                  },
                                                });
                                              }} className="btn w-14	  btn-sm btn-ghost !rounded-[5px]">
                                                <img src="https://image.bigbooster.in/e9c23ea4-aa1c-4619-93b2-94b5fb2da494.png" className="w-7 h-7"/>
                                              </button>
                                              :null
                                    }

                                    <button onClick={() => {
                                      viewNotes(id,e.exam_name); setSearchQuery('');
                                    }} className="btn w-14  btn-sm btn-ghost !rounded-[5px]">
                                      <img src="https://image.bigbooster.in/6d0a8245-6dd9-41a3-9348-57b46f9d0cc6.png" className="w-7 h-7"/>
                                    </button>

                                    <button onClick={() => {
                                      viewVideos(id,e.exam_name); setSearchQuery('');
                                    }} className="btn w-14	 btn-sm btn-ghost !rounded-[5px]">
                                      <img src=" https://image.bigbooster.in/f5eca225-ef5e-4e2b-af2b-ad196d046cd5.png" className="w-7 h-7"/>
                                    </button>

                                    {
                                      role === 1
                                          ? null
                                          : role === 2
                                              ? (
                                                  <button onClick={() => handleRemove(id)} className="btn  btn-sm btn-error rounded-[5px]">
                                                    Delete
                                                  </button>
                                              )
                                              : role === 3
                                                  ? (
                                                      <button onClick={() => handleRemove(id)} className="btn  btn-sm btn-error rounded-[5px]">
                                                        Delete
                                                      </button>
                                                  )
                                                  : null
                                    }

                                  </div>

                                </td>

                              </tr>
                          ))}
                          </tbody>
                        </table> : <LoadingTableComponent/>
                  }

                </div> :tabs==2? <div>
                  {
                    !isLoading ?
                        <table className="table border-separate w-full table-zebra table-auto  table-compact ">
                          <thead className="border-b sticky  top-0">
                          <tr className="text-center">
                            <th className="bg-[#50DBB4] pl-8 pr-8 mt-auto mb-auto">
                              <input
                                  type="checkbox"
                                  className="checkbox checkbox-sm mt-auto mb-auto"
                                  checked={selectedRows.length === filteredItems1.length} onChange={handleSelectAll}
                              />
                            </th>
                            <th className="bg-[#50DBB4] ">Set Name</th>
                            <th className="bg-[#50DBB4] ">Teach Set ID</th>
                            <th className="bg-[#50DBB4] ">lang</th>
                            <th className="bg-[#50DBB4] text-left">Main Folder</th>
                            <th className="bg-[#50DBB4] ">Sub Folder</th>
                            <th className="bg-[#50DBB4] ">Time</th>
                            <th className="bg-[#50DBB4] ">TQ</th>
                            <th className="bg-[#50DBB4] pl-4 pr-4">Creation Time</th>
                            <th className="bg-[#50DBB4] pl-4 pr-4">Live</th>
                            <th className="bg-[#50DBB4] ">PDF</th>
                            <th className="bg-[#50DBB4] pl-10 pr-10">Action</th>

                          </tr>
                          </thead>
                          <tbody>
                          {filteredItems1.map((e, id) => (
                              <tr key={id} className="hover">
                                <th className="text-center">
                                  <input type="checkbox" checked="checked" className="checkbox checkbox-accent checkbox-sm ml-auto mr-auto"  checked={selectedRows.includes(e.set_id)}
                                         checked={selectedRows.includes(e.set_id)}
                                         onChange={() => handleSelectRow(e.set_id)} />
                                </th>
                                <td >
                                  <b>{
                                    e.set_id
                                  }</b>•
                                  {
                                    e.set_name
                                  }
                                </td>
                                <td >
                                  {
                                    e.content_source_code
                                  }
                                </td>
                                <td >
                                  {
                                    e.language
                                  }
                                </td>
                                <td className="!text-left">
                                  {
                                    e.primary_filter
                                  }
                                </td><td >
                                {
                                  e.secondary_filter
                                }
                              </td>
                                <td >
                                  {
                                    e.time
                                  }
                                </td>


                                <td >
                                  {
                                    e.total_questions
                                  }
                                </td>
                                <td >
                                  {
                                    e.date
                                  }
                                </td>

                                <td >
                                  {
                                    <label className="cursor-pointer label">
                                      <input type="checkbox" className="toggle toggle-accent mx-auto" checked={e.is_live  == "1"?true:false}
                                      />
                                    </label>
                                  }
                                </td>
                                <td className="justify-self-center ">
                                  {
                                    e.external_link==="#" || e.external_link===" "?null:<a href={e.external_link} className="text-2xl "><AiFillFilePdf className="text-orange-300 ml-auto mr-auto"/></a>
                                  }
                                </td>
                                <td >
                                  <div className="btn-group">
                                    {
                                      <div className="btn-group gap-2">
                                        <button
                                            className="btn btn-sm  btn-outline !rounded-[2px]"
                                            onClick={() => handleUpdateNew(id)} disabled={selectedRows.includes(e.set_id)}
                                        >
                                          <AiFillEdit/>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-info ml-1  !rounded-[2px]"
                                            onClick={() => handleCopy(id)}
                                        >
                                          <AiFillCopy/>
                                        </button>
                                        {
                                          role===1?null:role===2?<button onClick={() => handleRemove(id)}
                                                                         className="btn btn-error ml-1 !rounded-[2px] btn-sm "><AiFillDelete/></button>:role===3? <button onClick={() => handleRemove(id)}
                                                                                                                                                                          className="btn btn-error ml-1 !rounded-[2px] btn-sm "><AiFillDelete/></button>:null
                                        }
                                      </div>
                                    }

                                  </div>
                                </td>

                              </tr>
                          ))}
                          </tbody>
                        </table> : <LoadingTableComponent/>
                  }
                </div>: tabs==4?<div>
                      { !isLoading? <table className="border-separate w-full table-zebra  table table-auto table-compact ">
                        <thead className="border-b sticky top-0">
                        <tr className="text-center">
                          <th className="bg-[#50DBB4]">BG Image</th>
                          <th className="bg-[#50DBB4] ">Category ID</th>
                          <th className="bg-[#50DBB4] ">Category Name</th>
                          <th className="bg-[#50DBB4] ">Sorting Parameter</th>
                          <th className="bg-[#50DBB4] ">Visibility</th>
                          <th className="bg-[#50DBB4] ">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        { filteredItems2.map((e, id) => (
                            <tr key={id} >
                              <th> {
                                <img className="h-[28px] w-[50px] ml-auto mr-auto rounded-[2px]" src={e.background_image}/>

                              }</th>
                              <td >
                                {
                                  e.id
                                }
                              </td>
                              <td >
                                {
                                  e.title
                                }
                              </td>

                              <td >
                                {e.sorting_params}
                              </td>
                              <td className="text-center">
                                {
                                  <label className="cursor-pointer label">
                                    <input type="checkbox" className="toggle toggle-accent mx-auto" checked={e.status == "1"?true:false}
                                    />
                                  </label>
                                }
                              </td>
                              <td >
                                <div className="btn-group">
                                  <button
                                      className="btn btn-sm btn-outline  !rounded-[2px]"
                                      onClick={() => handleUpdateNew1(id)}
                                  >
                                    {/*<AiFillEdit/>*/} Edit
                                  </button>
                                </div>
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>: <LoadingTableComponent/>}
                    </div>:
                    tabs == 5 ? <div>
                          {
                            !isLoading ?
                                <table className="table   border-separate w-full table-zebra  table-auto table-compact ">
                                  <thead className="border-b sticky top-0">
                                  <tr>
                                    <th className="bg-[#50DBB4] w-full pl-10 pr-10 text-center">Exam Id</th>
                                    <th className="bg-[#50DBB4] w-full pl-10 pr-10 text-center">Notes Title</th>
                                    <th className="bg-[#50DBB4] w-full pl-10 pr-10">Subtitle</th>
                                    <th className="bg-[#50DBB4] w-full pl-12 pr-12">Visibility</th>
                                    <th className="bg-[#50DBB4] w-full pl-20 pr-20 text-center">Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {filteredItems3.map((e, id) => (
                                      <tr key={id} >
                                        <th className="text-center">
                                          {e.exam_id}
                                        </th>

                                        <td >
                                          {
                                            e.notes_title
                                          }
                                        </td>
                                        <td >
                                          {
                                            e.notes_subtitle
                                          }
                                        </td>
                                        <td >
                                          {
                                            <input type="checkbox" className="toggle toggle-accent" checked={e.status == "1"? true : false} />
                                          }
                                        </td>
                                        <td >
                                          <div className="btn-group gap-2">

                                            <a href={e.download_url} className="btn btn-sm  btn-outline !rounded-[2px]"
                                                // onClick={() => handleUpdateNew(id)}
                                            >
                                              {/*<AiFillEdit/>*/} Download
                                            </a>
                                            <button
                                                className="btn btn-sm  btn-outline !rounded-[2px]"
                                                onClick={() => handleUpdateNew(id)}
                                            >
                                              {/*<AiFillEdit/>*/} Edit
                                            </button>
                                            <button
                                                onClick={() => handleRemove(id)}
                                                className="btn btn-sm btn-error btn-outline !rounded-[2px]"

                                            >Delete
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                  ))}
                                  </tbody>
                                </table> : <LoadingTableComponent/>
                          }

                        </div> :
                        tabs == 6 ? <div>
                          {
                            !isLoading ?
                                <table className="table   border-separate w-full table-zebra  table-auto table-compact ">
                                  <thead className="border-b sticky top-0">
                                  <tr>
                                    <th className="bg-[#50DBB4] w-full  text-center">Thumbnail</th>
                                    <th className="bg-[#50DBB4] w-full  text-center">Exam Id</th>
                                    <th className="bg-[#50DBB4] text-center">Video Title</th>
                                    <th className="bg-[#50DBB4] w-full text-center">Subtitle</th>
                                    <th className="bg-[#50DBB4] w-full ">Visibility</th>
                                    <th className="bg-[#50DBB4] w-full  ">Is Live</th>
                                    <th className="bg-[#50DBB4] w-full ">Payment</th>
                                    <th className="bg-[#50DBB4] w-full text-center">Schedule Status</th>
                                    <th className="bg-[#50DBB4] w-full ">Server</th>
                                    <th className="bg-[#50DBB4] w-full ">Sorting Params</th>
                                    <th className="bg-[#50DBB4] w-full pl-20 pr-20 text-center">Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {filteredItems4.map((e, id) => (
                                      <tr key={id} >
                                        <th>
                                          {
                                            <img className="h-[28px] w-[45px] !z-0 mx-auto rounded-[2px]" src={e.thumbnail}/>
                                          }
                                        </th>
                                        <td >
                                          <b>{e.exam_id}</b>
                                        </td>
                                        <td >
                                          {
                                            e.video_title
                                          }
                                        </td>
                                        <td >
                                          {
                                            e.video_subtitle
                                          }
                                        </td>
                                        <td >
                                          {
                                            <input type="checkbox" className="toggle toggle-accent" checked={e.visibility == "1"? true : false} />
                                          }
                                        </td>
                                        <td >
                                          {
                                            <input type="checkbox" className="toggle toggle-accent" checked={e.is_live == "1"? true : false} />
                                          }
                                        </td>
                                        <td >
                                          {
                                            e.take_payment==0?<p>Not paid</p>:<p>Paid</p>
                                          }
                                        </td>
                                        <td >
                                          {
                                            e.schedule_status
                                          }
                                        </td>
                                        <td >
                                          {
                                            e.server
                                          }
                                        </td>
                                        <td >
                                          {
                                            e.sorting_params
                                          }
                                        </td>
                                        <td >
                                          <div className="btn-group gap-2">
                                            <button
                                                className="btn btn-sm  btn-outline !rounded-[2px]"
                                                onClick={() => handleUpdateNew(id)}
                                            >Edit
                                            </button>
                                            <button onClick={()=>(
                                                navigate(`/live-chat/${e.id}`, { state: e })
                                            )}
                                                    className="btn btn-sm  btn-outline !rounded-[2px]"

                                            >Live Chat
                                            </button>
                                            <button
                                                onClick={() => handleRemove(id)}
                                                className="btn btn-sm btn-error btn-outline !rounded-[2px]"

                                            >Delete
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                  ))}
                                  </tbody>
                                </table> : <LoadingTableComponent/>
                          }

                        </div>:null
              }
            </div>
          </main>

        </div>


        {/*  modal for create */}

        <input type="checkbox" id="createExamModal" className="modal-toggle"/>
        <label htmlFor="createExamModal" className="modal cursor-pointer">
          <label className="modal-box pt-0 pb-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">Create Exam</p>
              <div className="card-actions  justify-end">
                <label ref={closeBtnRef} htmlFor="createExamModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>

            <form className="text-center" onSubmit={handleAdd}>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Exam Name</span>
                </label>
                <input type="text" placeholder="Enter exam name"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={exam_name} onChange={(e) => {
                  setExamName(e.target.value);
                }}/>
              </div>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Validity</span>
                </label>
                <input type="number" placeholder="Enter validity"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={validity} onChange={(e) => {
                  setValidity(e.target.value);
                }}/>
              </div>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Price</span>
                </label>
                <input type="number" placeholder="Enter exam name"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={price} onChange={(e) => {
                  setPrice(e.target.value);
                }}/>
              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Create</button>
              </div>
            </form>
          </label>
        </label>


        <input type="checkbox" id="createSetModal" className="modal-toggle"/>
        <label htmlFor="createSetModal" className="modal cursor-pointer">
          <label className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">Create Set</p>
              <div className="card-actions  justify-end">
                <label ref={closeBtnRef1} htmlFor="createSetModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>

            <form className="text-center" onSubmit={handleAdd}>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Set Name</span>
                </label>
                <input type="text" placeholder="Set Name"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={set_name} onChange={(e) => {
                  setSetName(e.target.value);
                }}/>
              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">create</button>
              </div>
            </form>
          </label>
        </label>

        <input type="checkbox" id="createNotesModal" className="modal-toggle"/>
        <label htmlFor="createNotesModal" className="modal cursor-pointer">
          <label className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">Create Notes</p>
              <div className="card-actions  justify-end">
                <label ref={closeNotes} htmlFor="createNotesModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>

            <form className="text-center" onSubmit={createNotes}>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Notes Title</span>
                </label>
                <input type="text" placeholder="Notes Title"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={def_notesTitle} onChange={(e) => {
                  setDef_NotesTitle(e.target.value);
                }}/>
              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">create</button>
              </div>
            </form>
          </label>
        </label>

        <input type="checkbox" id="createVideoModal" className="modal-toggle"/>
        <label htmlFor="createVideoModal" className="modal cursor-pointer">
          <label className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">Create Notes</p>
              <div className="card-actions  justify-end">
                <label ref={closeVideo} htmlFor="createVideoModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>

            <form className="text-center" onSubmit={createViedo}>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Video Title</span>
                </label>
                <input type="text" placeholder="Video Title"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={videoListTitle} onChange={(e) => {
                  setVideoTitleList(e.target.value);
                }}/>
              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">create</button>
              </div>
            </form>
          </label>
        </label>



        <input type="checkbox" id="createCat" className="modal-toggle"/>
        <label htmlFor="createCat" className="modal cursor-pointer">
          <label className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">Create Category</p>
              <div className="card-actions  justify-end">
                <label ref={closeBtnRef2} htmlFor="createCat" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>

            <form className="text-center" onSubmit={handleAdd}>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Category Name</span>
                </label>
                <input type="text" placeholder="Category Name"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={catName} onChange={(e) => {
                  setCatName(e.target.value);
                }} required/>
              </div>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Background Image</span>
                </label>
                <input type="text" placeholder="Category Name"
                       className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={catImage} onChange={(e) => {
                  setCatImage(e.target.value);
                }} required/>
              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">create</button>
              </div>
            </form>
          </label>
        </label>

        {/* Put this part before </body> tag */}
        <input type="checkbox" id="editExamModal" className="modal-toggle"/>
        <label htmlFor="editExamModal" className="modal cursor-pointer">
          <label className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header z-20  sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">{tabs==1?examName:tabs==2?setName:tabs==5?notesTitle:tabs==6?videoTitle:null}</p>
              <div className="card-actions  justify-end">
                <label ref={editRef} htmlFor="editExamModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>
            <form onSubmit={updateNew}>

              {
                tabs==1? <div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Background Logo</span>
                        </label>
                        <input
                            type="text"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={logoEdit}
                            onChange={(e) => {
                              setLogoEdit(e.target.value);
                            }}
                        />
                      </div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Exam Name</span>
                        </label>
                        <input
                            type="text"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={examName}
                            onChange={(e) => {
                              setExamNameEdit(e.target.value);
                            }}
                        />
                      </div>

                      {/*<div className="form-control">*/}
                      {/*  <label className="label">*/}
                      {/*    <span className="label-text">Exam Category</span>*/}
                      {/*  </label>*/}
                      {/*  <input*/}
                      {/*      type="text"*/}
                      {/*      className="input rounded-[5px] input-bordered  !rounded-sm c-font1"*/}
                      {/*      value={examCategory}*/}
                      {/*      onChange={(e) => {*/}
                      {/*        setExamCategory(e.target.value);*/}
                      {/*      }}*/}
                      {/*  />*/}
                      {/*</div>*/}
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Exam Category</span>
                        </label>
                        <select
                            className="select w-full select-bordered c-font1 !rounded-[2px]"
                            value={newCategoryName}
                            onChange={(event) => {
                              setNewCategoryName(event.target.value);
                            }}
                        >
                          <option value="">Select Category</option>

                          {categoryData.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.title}
                              </option>
                          ))}
                        </select>
                      </div>

                      {/*<div className="form-control">*/}
                      {/*  <label className="label">*/}
                      {/*    <span className="label-text">Category Logo</span>*/}
                      {/*  </label>*/}
                      {/*  <input*/}
                      {/*      type="text"*/}
                      {/*      className="input rounded-[5px] input-bordered  !rounded-sm c-font1"*/}
                      {/*      value={categoryImage}*/}
                      {/*      onChange={(e) => {*/}
                      {/*        setCategoryImage(e.target.value);*/}
                      {/*      }}*/}
                      {/*  />*/}
                      {/*</div>*/}
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Exam Type</span>
                        </label>
                        <input
                            type="text"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={examType}
                            onChange={(e) => {
                              setExamType(e.target.value);
                            }}
                        />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Sorting Params</span>
                        </label>
                        <input
                            type="number"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={sortingParams}
                            onChange={(e) => {
                              setSortingParams(e.target.value);
                            }}
                        />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Show Ratings</span>
                        </label>
                        <select className="select w-full select-bordered c-font1 !rounded-[2px] " value={showRatings}
                                onChange={(event) => {
                                  setShowRatings(event.target.value)

                                }}>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Rating</span>
                          {/*<span className="text-sm">Total Rating: {rating}</span>*/}
                        </label>
                        <input
                            type="text"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={rating}
                            onChange={(e) => {
                              setRating(e.target.value);
                            }}
                        />
                      </div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Exam Tag</span>
                        </label>
                        <input
                            type="text"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={tag}
                            onChange={(e) => {
                              setTag(e.target.value);
                            }}
                        />
                      </div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Entry Time</span>
                        </label>
                        <DatePicker
                            showTimeSelect
                            setDefaultSelected={true}
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            timeFormat="HH:mm:ss"
                            placeholderText={entryTime}
                            selected={entryTime.length>0?new Date(entryTime.toString()):new Date()}
                            closeOnSelect={false}
                            shouldCloseOnSelect={false}
                            onChange={(date) => {
                              setEntryTime(formatDate(date))
                            }}
                            className="input input-bordered !z-0 c-font1 w-full"
                        />

                      </div>

                      {/*<div className="form-control">*/}
                      {/*  <label className="label">*/}
                      {/*    <span className="label-text">Show Notice</span>*/}
                      {/*  </label>*/}
                      {/*  <select className="select w-full select-bordered c-font1 !rounded-[2px] " value={showNotice}*/}
                      {/*          onChange={(event) => {*/}
                      {/*            setShowNotice(event.target.value)*/}

                      {/*          }}>*/}
                      {/*    <option value="0">No</option>*/}
                      {/*    <option value="1">Yes</option>*/}
                      {/*  </select>*/}
                      {/*</div>*/}

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Exam Notice</span>
                        </label>
                        <textarea
                            className="textarea h-[100px] rounded-[5px] input-bordered !rounded-sm c-font1"
                            value={notice}
                            onChange={(e) => {
                              setNotice(e.target.value);
                            }}
                        />
                      </div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Exam Price</span>
                        </label>
                        <input
                            type="number"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={priceEdit}
                            onChange={(e) => {
                              setPriceEdit(e.target.value);
                            }}
                        />
                      </div>
                      {/*<div className="form-control">*/}
                      {/*  <label className="label">*/}
                      {/*    <span className="label-text">Purchase Text</span>*/}
                      {/*  </label>*/}
                      {/*  <input*/}
                      {/*      type="text"*/}
                      {/*      className="input rounded-[5px] input-bordered  !rounded-sm c-font1"*/}
                      {/*      value={purchaseText}*/}
                      {/*      onChange={(e) => {*/}
                      {/*        setPurchaseText(e.target.value);*/}
                      {/*      }}*/}
                      {/*  />*/}
                      {/*</div>*/}

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Exam Validity</span>
                        </label>
                        <input
                            type="text"
                            className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                            value={validityEdit}
                            onChange={(e) => {
                              setValidityEdit(e.target.value);
                            }}
                        />
                      </div>

                      {/*<div className="form-control">*/}
                      {/*  <label className="label">*/}
                      {/*    <span className="label-text">Use Wallet Percent</span>*/}
                      {/*  </label>*/}
                      {/*  <input*/}
                      {/*      type="number"*/}
                      {/*      className="input rounded-[5px] input-bordered  !rounded-sm c-font1"*/}
                      {/*      value={useWalletPercent}*/}
                      {/*      onChange={(e) => {*/}
                      {/*        setUseWalletPercent(e.target.value);*/}
                      {/*      }}*/}
                      {/*  />*/}
                      {/*</div>*/}

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Live</span>
                        </label>
                        <select className="select select-bordered w-full " value={status}  onChange={(e) => {
                          setStatus(e.target.value);
                        }}>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Platform</span>
                        </label>
                        <select className="select select-bordered w-full " value={showOnAppWeb}  onChange={(e) => {
                          setShowOnAppWeb(e.target.value);
                        }}>
                          <option value="1">App</option>
                          <option value="2">Web</option>
                          <option value="3">Both</option>
                        </select>
                      </div>

                      {/*<div className="form-control">*/}
                      {/*  <label className="label">*/}
                      {/*    <span className="label-text">Exam Date</span>*/}
                      {/*  </label>*/}
                      {/*  <input*/}
                      {/*      type="text"*/}
                      {/*      className="input rounded-[5px] input-bordered  !rounded-sm c-font1"*/}
                      {/*      value={date}*/}
                      {/*      onChange={(e) => {*/}
                      {/*        setDate(e.target.value);*/}
                      {/*      }}*/}
                      {/*  />*/}
                      {/*</div>*/}

                    </div>:
                    tabs==2?<div>
                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Set Name</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={setName}
                                onChange={(e) => {
                                  setSetNameEdit(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Content Source Code</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={contentSourceCode}
                                onChange={(e) => {
                                  setContentSourceCode(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Primary Filter</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={primaryFilter}
                                onChange={(e) => {
                                  setPrimaryFilter(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Preference Subject</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={preferenceSubject}
                                onChange={(e) => {
                                  setPreferenceSubject(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Sub Filter</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={subFilter}
                                onChange={(e) => {
                                  setSubFilter(e.target.value);
                                }}
                            />
                          </div>


                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Time</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={time}
                                onChange={(e) => {
                                  setTime(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control ">
                            <label className="label">
                              <span className="label-text">Date</span>
                            </label>
                            <input type="text" placeholder="Set Name"
                                   className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                                   value={set_date} onChange={(e) => {
                              setSet_date(e.target.value);
                            }}/>
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Tag</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={tagSet}
                                onChange={(e) => {
                                  setTagSet(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Countdown</span>
                            </label>
                            <DatePicker
                                showTimeSelect
                                setDefaultSelected={true}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                timeFormat="HH:mm:ss"
                                placeholderText={countdown}
                                selected={countdown.length>0?new Date(countdown.toString()):new Date()}
                                closeOnSelect={false}
                                shouldCloseOnSelect={false}
                                onChange={(date) => {
                                  setCountdown(formatDate(date))
                                }}
                                className="input input-bordered z-20 c-font1 w-full"
                            />

                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">PDF</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={pdf}
                                onChange={(e) => {
                                  setPdf(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Video URL</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                value={videoUrl}
                                onChange={(e) => setVideoUrl(e.target.value)}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">TQ</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={tq}
                                onChange={(e) => {
                                  setTq(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">PM</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={pm}
                                onChange={(e) => {
                                  setPm(e.target.value);
                                }}
                            />
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">NM</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={nm}
                                onChange={(e) => {
                                  setNm(e.target.value);
                                }}
                            />
                          </div>
                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Instructions</span>
                            </label>
                            <textarea
                                type="text"
                                className="input rounded-[5px] input-bordered h-20 !rounded-sm c-font1"
                                value={instruction}
                                onChange={(e) => {
                                  setInstruction(e.target.value);
                                }}   rows={4}

                            />
                          </div>
                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Sorting Parameter</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={sortingParams}
                                onChange={(e) => {
                                  setSortingParams(e.target.value);
                                }}
                            />
                          </div>
                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Group by Subject</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered   c-font1"
                                value={groupBySubject}
                                onChange={(e) => {
                                  setGroupBySubject(e.target.value);
                                }}
                            />
                            {/*<select className="select select-bordered w-full " value={groupBySubject}  onChange={(e) => {*/}
                            {/*  setGroupBySubject(e.target.value);*/}
                            {/*}}>*/}
                            {/*  <option value="true">Yes</option>*/}
                            {/*  <option value="false">No</option>*/}
                            {/*</select>*/}
                          </div>
                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Take Payment</span>
                            </label>
                            <select className="select select-bordered w-full " value={takePay}  onChange={(e) => {
                              setTakePay(e.target.value);
                            }}>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Show Previous Year Tag</span>
                            </label>
                            <select className="select select-bordered w-full " value={showPreviousYearTag}  onChange={(e) => {
                              setShowPreviousYearTag(e.target.value);
                            }}>
                              <option value="1">Show</option>
                              <option value="0">Hide</option>
                            </select>
                          </div>

                          <div className="form-control">
                            <label className="label">
                              <span className="label-text">Is Live</span>
                            </label>
                            <select className="select select-bordered w-full " value={isLive}  onChange={(e) => {
                              setIsLive(e.target.value);
                            }}>
                              <option value="1">Show</option>
                              <option value="0">Hide</option>
                            </select>
                          </div>

                        </div>
                        :tabs==5?
                            <div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Notes Title</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={notesTitle}
                                    onChange={(e) => {
                                      setNotesTitle(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Notes Subtitle</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={notesSubtitle}
                                    onChange={(e) => {
                                      setNotesSubtitle(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Download URL</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={downloadUrl}
                                    onChange={(e) => {
                                      setDownloadUrl(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Visibility</span>
                                </label>
                                <select className="select select-bordered w-full " value={visibility}  onChange={(e) => {
                                  setVisibility(e.target.value);
                                }}>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>


                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Date</span>
                                </label>
                                <input
                                    type="date"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={notesDate}
                                    onChange={(e) => {
                                      setNotesDate(e.target.value);
                                    }}
                                />
                              </div>
                            </div>
                            :tabs==6?<div>
                              {/* Render your input fields with the state variables */}
                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Video Title</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={videoTitle}
                                    onChange={(e) => {
                                      setVideoTitle(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Video Subtitle</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={videoSubtitle}
                                    onChange={(e) => {
                                      setVideoSubtitle(e.target.value);
                                    }}
                                />
                              </div>
                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Video Description</span>
                                </label>
                                <textarea
                                    type="text"
                                    className="textarea rounded-[5px] textarea-bordered c-font1"
                                    value={videoDesc}
                                    onChange={(e) => {
                                      setVideoDesc(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Video URL</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={videoListUrl}
                                    onChange={(e) => {
                                      setVideoListUrl(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Thumbnail</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={thumbnail}
                                    onChange={(e) => {
                                      setThumbnail(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Server</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={server}
                                    onChange={(e) => {
                                      setServer(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Take Payment</span>
                                </label>
                                <select className="select select-bordered w-full " value={takePayment}  onChange={(e) => {
                                  setTakePayment(e.target.value);
                                }}>
                                  <option value="1">Paid</option>
                                  <option value="0">Not Paid</option>
                                </select>
                              </div>
                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Sorting Param</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={videoSortingParam}
                                    onChange={(e) => {
                                      setVideoSortingParam(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Is Live</span>
                                </label>
                                <select className="select select-bordered w-full " value={videoisLive}  onChange={(e) => {
                                  setVideoIsLive(e.target.value);
                                }}>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Schedule Status</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={scheduleStatus}
                                    onChange={(e) => {
                                      setScheduleStatus(e.target.value);
                                    }}
                                />
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Video Visibility</span>
                                </label>
                                <select className="select select-bordered w-full " value={videoVisibility}  onChange={(e) => {
                                  setVideoVisibility(e.target.value);
                                }}>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>

                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Date</span>
                                </label>
                                <input
                                    type="date"
                                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                                    value={videoDate}
                                    onChange={(e) => {
                                      setVideoDate(e.target.value);
                                    }}
                                />
                              </div>

                            </div>:null
              }

              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Update</button>
              </div>

            </form>
          </label>
        </label>


        <input type="checkbox" id="userModal" className="modal-toggle"/>
        <label htmlFor="userModal" className="modal cursor-pointer">
          <label className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">Activate Exam</p>
              <div className="card-actions  justify-end">
                <label ref={userRef} htmlFor="userModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>
            <form onSubmit={handleActivateExam}>
              <div className="form-control  ">
                <label className="label">
                  <span className="label-text">Phone Number</span>
                </label>
                <input type="number" placeholder="Phone Number" className="input input-bordered w-full "
                       value={activate_phone} onChange={(e)=>(setActivate_Phone(e.target.value))} required/>
              </div>
              <div className="form-control w-full ">
                <label className="label">
                  <span className="label-text">Select Exam</span>
                </label>

                <select className="select select-bordered" id="examDropdown" value={selectedExam}  onChange={handleSelectChange}  required>
                  <option value="">Select an exam</option>
                  {examNames.map((examName) => (
                      <option value={examName} key={examName}>
                        {examName}
                      </option>
                  ))}
                </select>
                <p className="text-sm text-gray-700 mt-1">
                  {selectedExam} ・ <b>{selectedExamId}</b>
                </p>
              </div>
              <div className="form-control  ">
                <label className="label">
                  <span className="label-text">Validity</span>
                </label>
                <input type="number" placeholder="Validity" className="input input-bordered w-full "
                       value={activate_validity} required onChange={(e)=>(setActivate_Validity(e.target.value))}/>
              </div>
              <div className="form-control  ">
                <label className="label">
                  <span className="label-text">Paid Amount</span>
                </label>
                <input type="number" placeholder="Paid Amount" className="input input-bordered w-full "
                       value={activate_paying_amount} required onChange={(e)=>(setActivate_paying_amount(e.target.value))}/>
              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Activate</button>
              </div>
            </form>
          </label>
        </label>



        {/*bulk edit*/}
        <input type="checkbox" id="bulkEdit" className="modal-toggle" />
        <div htmlFor="bulkEdit" className="modal cursor-pointer">
          <div className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">Bulk Edit</p>
              <div className="card-actions  justify-end">
                <label ref={bulkEditRef} htmlFor="bulkEdit" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>
            <form className="text-center" onSubmit={
              handleBulk
            } >
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Select Column</span>
                </label>
                <select className="select select-bordered w-full  !rounded-sm c-font1" value={resetSelect} onChange={(e)=>{handleChangeKey(e.target.value);  setResetSelect(e.target.value)}}>
                  {/*<option value="def">Select an option</option>*/}
                  {
                    bulkOptions.map((item,key)=>(
                        <>
                          <option value={item.value} key={key}>{item.name}</option>

                        </>
                    ))
                  }
                </select>
              </div>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Value</span>
                </label>
                {
                  bulkType=="text"?<input type="text" placeholder="Value"
                                          className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                                          value={bulkValue} onChange={(e)=>setBulkValue(e.target.value)}
                  />:bulkType=="number"?<input type="number" placeholder="Value"
                                               className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                                               value={bulkValue} onChange={(e)=>setBulkValue(e.target.value)}
                  />:bulkType=="boolean"? <div className="form-control">
                    <select className="select select-bordered w-full " value={bulkValue}  onChange={(e) => {
                      setBulkValue(e.target.value);
                    }}>
                      <option >Select</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>:bulkType=="lang"? <div className="form-control">
                        <select className="select select-bordered w-full " value={bulkValue}  onChange={(e) => {
                          setBulkValue(e.target.value);
                        }}>
                          <option >Select</option>
                          <option value="1">Single Language</option>
                          <option value="2">Bilingual</option>
                        </select>
                      </div>
                      :bulkType=="date"? <DatePicker
                          showTimeSelect
                          setDefaultSelected={true}
                          dateFormat="yyyy-MM-dd HH:mm:ss"
                          timeFormat="HH:mm:ss"
                          selected={bulkValue}
                          closeOnSelect={false}
                          shouldCloseOnSelect={false}
                          onChange={(date) => {
                            setBulkValue(date)
                          }}
                          popperPlacement="center"
                          className="input input-bordered z-20  w-full"
                      />:null
                }

              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Update</button>
              </div>
            </form>
          </div>
        </div>



        <input type="checkbox" id="editExamModal1" className="modal-toggle"/>
        <label htmlFor="editExamModal1" className="modal cursor-pointer">
          <label className="modal-box pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
              <p className="text-2xl font-bold c-font1 text-center">{title}</p>
              <div className="card-actions  justify-end">
                <label ref={editRef1} htmlFor="editExamModal1" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>
            <form onSubmit={updateNew1}>
              <div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Title</span>
                  </label>
                  <input
                      type="text"
                      className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Background Image</span>
                  </label>
                  <input
                      type="text"
                      className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                      value={backgroundImage}
                      onChange={(e) => {
                        setBackgroundImage(e.target.value);
                      }}
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Sorting Params</span>
                  </label>
                  <input
                      type="text"
                      className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                      value={sortingParams}
                      onChange={(e) => {
                        setSortingParams(e.target.value);
                      }}
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Visibility Status</span>
                  </label>
                  <select
                      className="select select-bordered !rounded-sm c-font1"
                      value={statusCate}
                      onChange={(e) => {
                        setStatusCate(parseInt(e.target.value));
                      }}
                  >
                    <option value={0}>Hide</option>
                    <option value={1}>Show</option>
                  </select>
                </div>

              </div>
              <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Update</button>
              </div>

            </form>
          </label>
        </label>



        {/*  notification modal */}
        <input type="checkbox" id="notiModal" className="modal-toggle"/>
        <label htmlFor="notiModal" className="modal 	 cursor-pointer">
          <label className="modal-box modal-top	 pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 ">
              <div className=" ml-4 text-center ">
                {/*Notification •*/}
                <p className="text-lg font-bold mt-auto mb-auto c-font1 ">{notificationData.exam_name}</p>
              </div>
              <div className="card-actions  justify-end">
                <label ref={notiRef} htmlFor="notiModal" className="btn btn-ghost btn-sm mt-[-30px]">
                  <RxCross2/>
                </label>
              </div>
            </div>
            <hr className="mb-2"/>

            <form onSubmit={sendNotification}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Notification Title</span>
                </label>
                <input
                    type="text"
                    placeholder={"Title"}
                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                    value={notificationTitle}
                    onChange={(e) => {
                      setNotificationTitle(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Notification Image</span>
                </label>
                <input
                    type="text"
                    placeholder={"Image"}
                    className="input rounded-[5px] input-bordered !rounded-sm c-font1"
                    value={notificationImg}
                    onChange={(e) => {
                      setNotificationImg(e.target.value);
                    }}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Notification Body</span>
                </label>
                <textarea
                    type="text"
                    placeholder={"type"}
                    className="textarea  rounded-[5px] textarea-bordered  c-font1"
                    value={notification}
                    onChange={(e) => {
                      setNotification(e.target.value);
                    }}
                />
              </div>
              <button className={"btn mt-2 mb-2 rounded-[2px] btn-natural w-full"}>Submit</button>
            </form>

          </label>
        </label>

        {/*  setting modal*/}


        <input type="checkbox" id="settingModal" className="modal-toggle"/>
        <label htmlFor="settingModal" className="modal 	 cursor-pointer">
          <label className="modal-box modal-top	 pb-0 pt-0 relative" htmlFor="">
            <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 ">
              {/*<div className="flex ml-4 !text-center pl-auto pr-auto">*/}
              {/*  <AiFillSetting className="mt-auto  mb-auto !text-xl"/>*/}
              {/*</div>*/}
              <p className="!text-md font-bold mt-auto mb-auto c-font1 text-center">Setting • {settingData.exam_name}</p>

              <div className="card-actions  justify-end">
                <label ref={setting} htmlFor="settingModal" className="btn btn-ghost bg-transparent btn-sm mt-[-25px]">
                  <RxCross2/>
                </label>
              </div>
            </div>
            <hr className="mb-2"/>
            <div className="grid grid-cols-2 grid-rows-2 gap-2 mb-4">
              <div className="mt-auto mb-auto">
                <button onClick={() => {
                  viewSets(settingId,settingData.exam_name); setSearchQuery(''); setting.current.click();
                }} className="btn  w-full btn-sm btn-outline !rounded-[5px]">View Sets</button>

              </div>

              <div className="mt-auto mb-auto">
                {
                  role===1?null:role===2?<button onClick={()=>{
                    navigate("/viewAdmissions", {
                      state: {
                        examName:settingData.exam_name,
                        examId:settingData.exam_id,
                        id:settingId,
                        examData:examData,
                      },
                    });
                    setting.current.click();
                  }} className="btn  w-full btn-sm btn-outline !rounded-[5px]">View Admissions</button>:role===3?<button onClick={()=>{
                    navigate("/viewAdmissions", {
                      state: {
                        examName:settingData.exam_name,
                        examId:settingData.exam_id,
                        id:settingId,
                        examData:examData,
                      },
                    });
                    setting.current.click();
                  }} className="btn  w-full btn-sm btn-outline !rounded-[5px]">View Admissions</button>:null
                }
              </div>

              <div className="mt-auto mb-auto">
                <button onClick={() => {
                  viewNotes(settingId,settingData.exam_name); setSearchQuery(''); setting.current.click();
                }} className="btn w-full  btn-sm btn-outline !rounded-[5px]">View Notes</button>

              </div>

              <div className="mt-auto mb-auto">
                <button onClick={() => {
                  viewVideos(settingId,settingData.exam_name); setSearchQuery(''); setting.current.click();
                }} className="btn w-full btn-sm btn-outline !rounded-[5px]">View Videos</button>

              </div>

            </div>

          </label>
        </label>

      </>
  )
}
export default ExamManagement;
