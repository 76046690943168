import toast, { Toaster } from 'react-hot-toast';
import sync from "../assets/menu/connection-sync.png";
import React from "react";
import axios from "axios";
import {API_BASE_URL} from "../utils/config";
const ResetCache = () => {


    const handleResetAll = async () => {
        const response = await axios({
            url: `https://api1.dishaonlinetest.in/reset-cache`,
            method: "get",
        });
        let data= response.data.status;

        const response1 = await axios({
            url: `https://api2.dishaonlinetest.in/reset-cache`,
            method: "get",
        });
        let data1= response1.data.status;

        const response2 = await axios({
            url: `https://api3.dishaonlinetest.in/reset-cache`,
            method: "get",
        });
        let data2= response2.data.status;


        if(data==200 && data1==200 && data2==200){
            toast.success('Cache Reset Successfully');
        }
        else{
            toast.error('Cache Reset Failed!');
        }

    };
    return(
        <>

            <div>
                <button className="btn  w-full !rounded-[2px] gap-2" onClick={handleResetAll}>
                    <img src={sync} width="20" height="20" />
                    <label>Reset Cache</label>
                </button>
                <Toaster />
            </div>

        </>
    )

}
export default ResetCache;
