import React, {useRef, useState} from "react";

import {AiOutlineSearch} from "react-icons/ai";
import {getUserData} from "../../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../../utils/config";
import {RxCross2} from "react-icons/rx";
import LoadingTableComponent from "../../loadingTable.component";
import Users from "./Users";
import {App_Name} from "../../../utils/name";
import {Link} from "react-router-dom";

const IndexUser = () => {
	const [tab,setTab]=useState(1)
	const searchRef=useRef()
	const [isLoading1, setIsLoading1] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchData, setSearchData] = useState([]);

	const searchByPhone = async (page) => {
		try {
			const userDetails = getUserData();
			const token = userDetails.token;
			const configData = { token, name:searchQuery };
			const response = await axios.post(`${API_BASE_URL}/name`, configData);
			const { data } = response.data;
			if(data.length>0){
				setSearchData(data)
				searchRef.current.click()
			}
			setIsLoading1(false)
		} catch (error) {
			setIsLoading1(false);
			if (error.response && (error.response.status === 500 || error.response.status === 401)) {
				// navigate("/login");
			}
		}
	};


	return(
		<>

			<header>
				<div className="mx-auto p-2 pb-1">
					<div className="grid grid-cols-3 ">
						<div className="col-span-2 flex items-center">
							<Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn !rounded-[2px]  max-sm:text-sm max-sm:btn-sm"> {App_Name}</Link>
						</div>
						<div className="justify-self-end">
							<div className="form-control">
								<div className="border rounded overflow-hidden flex items-center mt-2">
									<input
										className="input input-sm input-bordered rounded-[2px] max-sm:input-sm w-full"
										type="search"
										placeholder="Search User"
										value={searchQuery}
										onChange={(event) => {
											setSearchQuery(event.target.value);
										}}
									/>
									<button className="btn btn-sm btn-outline rounded-[0px]" onClick={searchByPhone}><AiOutlineSearch/></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>

			<Users/>


			<input type="checkbox" id="searchModal" className="modal-toggle"/>
			<label htmlFor="searchModal" className="modal 	 cursor-pointer">
				<label className="modal-box modal-top pb-0 pt-0 relative !w-11/12 max-w-5xl" htmlFor="">
					<div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 ">
						<p className="!text-md font-bold mt-auto mb-auto c-font1 text-center">Search by user name</p>
						<div className="card-actions  justify-end">
							<label ref={searchRef} htmlFor="searchModal" className="btn btn-ghost bg-transparent btn-sm mt-[-25px]">
								<RxCross2/>
							</label>
						</div>
					</div>
					<main className="flex-1 h-[90%] overflow-y-auto">
						<div className=" overflow-x-scroll overflow-y-scroll">
							{!isLoading1? (
								<table className="border-separate w-full table-zebra table table-auto table-compact">
									<thead className="border-b sticky top-0">
									<tr className="text-center">
										<th className="bg-[#50DBB4] sticky-none !w-fit">Username</th>
										<th className="bg-[#50DBB4]  !w-fit">Phone</th>
										<th className="bg-[#50DBB4]  !w-fit">Email</th>
										<th className="bg-[#50DBB4]  !w-fit">Last Active</th>
									</tr>
									</thead>
									<tbody>
									{
										searchData.map((e, id) => (
											<tr key={id} className="hover">
												<td className="!h-14 !w-fit">{e.user_name}</td>
												<td className="!h-14 !w-10">{e.phone}</td>
												<td className="!h-14 !w-20">{e.email}</td>
												<td className="!h-14 !w-20">{e.last_active}</td>
											</tr>
										))}
									</tbody>
								</table>
							) :null}
						</div>
					</main>
				</label>
			</label>
		</>
	)
}
export default IndexUser;
