import React from "react";

const LoadingTableComponent=()=>{

    return(
        <>
            {/*<div className="hero min-h-screen bg-base-200">*/}
            {/*    <div className="hero-content text-center">*/}
            {/*        <div className="max-w-md">*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="flex items-center justify-center h-96 ">
                <div className="m-auto">
                    <button className="btn btn-ghost loading">loading...</button>
                </div>
            </div>

        </>
    )
}
export default LoadingTableComponent;
