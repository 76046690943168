import {Link, useNavigate} from "react-router-dom";
import {App_Name} from "../../../utils/name";
import React, {useEffect, useState} from "react";
import {AiFillEye} from "react-icons/ai";
import {getUserData} from "../../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../../utils/config";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import def from "../../../assets/def-img.jpeg"
const ViewPage=()=>{

    const [tab,setTab]=useState(1)

    const [blogData, setBlogData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    async function fetchBlogs() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
        };
        const response = await axios({
            url:`${API_BASE_URL}/get-blog`,
            // url:`https://5fa2-106-213-39-251.ngrok-free.app/get-blog`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];
        setBlogData(data1.data);
        if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }

    const formatDateForDisplay = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [byDate,setByDate]=useState()

    const filteredSearch = blogData.filter((qu) =>
        tab === 1
            ? qu.title.toLowerCase().includes(searchQuery.toLowerCase())
            : tab === 2
                ? qu.title.toLowerCase().includes(searchQuery.toLowerCase()) && qu.visibility === 1
                : tab === 3
                    ? qu.title.toLowerCase().includes(searchQuery.toLowerCase()) && qu.visibility === 0
                    : tab === 4 && qu.date > byDate
                        ? qu.title.toLowerCase().includes(searchQuery.toLowerCase())
                        : null
    );

    const navigate=useNavigate();
    const handleEdit=(blogId,index)=>{
        const foundBlog = blogData.find(blogPost => blogPost.id === blogId);
        navigate(`/edit-blog/${blogId}`, { state: foundBlog });
    }

    useEffect(() => {
        fetchBlogs()
        // setByDate(new Date())
    }, []);

    return(
        <>
            <div className="bg-base-100  m-0 flex flex-col h-screen ">
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2">
                                <div className="tabs bg-white mt-2 p-2 gap-2 tabs-boxed">
                                    <a className={`tab bg-base-200 tab-sm tab-bordered ${tab==1?"tab-active !bg-neutral":null}`}  onClick={()=>(
                                        setTab(1))}>All {blogData.length}</a>
                                    <a className={`tab bg-base-200 tab-sm tab-bordered ${tab==2?"tab-active !bg-neutral":null}`}  onClick={()=>(
                                        setTab(2))}>Public</a>
                                    <a className={`tab bg-base-200 tab-sm tab-bordered ${tab==3?"tab-active !bg-neutral":null}`}  onClick={()=>(
                                        setTab(3))}>Draft</a>
                                    <a className={`tab bg-base-200 tab-sm tab-bordered ${tab==4?"tab-active !bg-neutral":null}`}  onClick={()=>(
                                        setTab(4))}>By Date</a>
                                </div>
                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className="rounded  flex">
                                        {/*<label htmlFor="CarouselModal" className="btn btn-ghost m-1 text-3xl"><AiOutlinePlusCircle/></label>*/}
                                        <input
                                            className="input input-sm input-bordered rounded-[2px] mt-4 mr-1 max-sm:input-xs max-sm:w-28"
                                            type="text"
                                            placeholder="Search Blog"
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {
                    tab==4?
                        <div className="ml-1">
                            <DatePicker
                                className="input input-sm input-bordered rounded-[2px] z-10 "
                                // selected={byDate}
                                onChange={date => setByDate(formatDateForDisplay(date))}
                                dateFormat="yyyy-MM-dd"
                                placeholderText={byDate}
                                // parseDate={formatDateForDisplay}
                            />
                        </div>
                      :null
                }

                <div className="grid mt-2 gap-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    {
                        filteredSearch.length==0?
                            <div className="h-screen w-screen">
                                <p className="text-center mt-4">No Post Found</p>
                            </div>:
                            filteredSearch.map((blog,key)=>(
                                // eslint-disable-next-line react/jsx-key
                                <div>
                                    <div className="card border text-left rounded-[10px] bg-base-100 shadow-xl">
                                        <figure className="relative px-10 pt-10">
                                            <img src={blog.thumbnail?blog.thumbnail:def} alt={blog.title} className="rounded-xl !w-[600px] !h-[150px]"  />
                                            <h2 className="absolute bottom-0 mr-12 bg-opacity-50 border-none right-0 m-2 badge"><AiFillEye className="text-xl mr-1"/>{blog.total_view}</h2>
                                            <h2 className="absolute bottom-0 ml-12 bg-opacity-50 border-none left-0 m-2 badge">{blog.visibility?"Public":"Draft"}</h2>
                                        </figure>
                                        <div className="card-body">
                                            <p className="opacity-50 text-xs mt-[-10px]">{blog.date.split(" ")[0]}</p>
                                            <h2 className="card-title text-ellipsis line-clamp-1">{blog.title}</h2>
                                            <div className="leading-relaxed mb-3 line-clamp-2">
                                                <div dangerouslySetInnerHTML={{ __html:  blog.content }} />
                                            </div>
                                            <div className="card-actions">
                                                <button onClick={()=>(handleEdit(blog.id,key))} className="btn btn-neutral btn-outline w-full rounded-[5px]">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </div>
        </>
    )
}
export default ViewPage
